import Applet from './applet.js';
import SettingsManager from './settingsManager.js';
import Widget from './widget.js';

import { Chart, registerables } from 'chart.js';
import Foroyar from './foroyar.js';
Chart.register(...registerables);

export default class AppletReporting extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "data";
    static appname="reporting";

    static content = `
    <div id="WindowReporting">
		<div class="w-100 h-100 d-flex flex-column" style="">
            <div class="flex row mx-2" id="titleRow">
                <div class="title flex-grow-1 overflow:hidden;" style="width:100px;">
                    <h2 class="handle date-title" style="color:#ccc; cursor:grab;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">Statistiques globales</h2>
                </div>
                <button class="btn-prev inline"><i class="icofont-caret-left"></i></button>
                <button class="btn-next inline"><i class="icofont-caret-right"></i></button>
                <button class="btn-custom inline"><i class="icofont-pencil-alt-2"></i></button>
                <i class="pl-3 py-2 my-1 icofont-close grey btn-close" style="font-size:x-large;"></i>
            </div>
            <div class="flex d-none row mx-2 handle reporting" id="titleRowCustom">
                <div class="daterangepicker" title="daterangepicker"></div>
                <button class="btn-search inline"><i class="icofont-search"></i></button>
                <div class="flex-grow-1"></div>
                <i class="pl-3 py-2 my-1 icofont-close grey btn-close" style="font-size:x-large;"></i>
            </div>
            <div class="container-fluid d-flex flex-row flex-grow-1" style="overflow:hidden;color:#aaa;">
                <div class="panel-left">
                    <ul style="list-style: none;padding-left: 0px;padding-right: 12px;">
                        <li><button class="panel-show inline" panel="general"><i class="icofont-chart-pie-alt"></i></button></li>
                        <li><button class="panel-show inline" panel="by-op"><i class="icofont-user"></i></button></li>
                    </ul>
                </div>
                <div class="flex-grow-1 panel-right">
                    <div class="row panel panel-by-op d-none reporting" style="height:100%;overflow:scroll;">
                        
                    </div>
                    <div class="row panel panel-general">
                        <div class="col">
                            <div style="text-align:center;">
                                <div class="ticket-amount display-4" style="font-size:6rem; color:#ff6e54;">32</div>
                                <span>nouveaux tickets</span>
                            </div>
                            <div class="reporting-canvas pt-4 m-auto" style="width:220px; height:220px;">
                                <canvas id="reportingPie" width="220" height="220"></canvas>
                            </div>
                        </div>
                        <div class="col" style="border-left: 1px solid #505050;">
                            <p  class="reporting-ticket-details"><span style="color:#003f5c;" class="closed reporting-ticket-number">10</span><i class="d-none icofont-lock"></i> tickets fermés</p>
                            <p  class="reporting-ticket-details"><span style="color:#444e86;" class="open reporting-ticket-number">4 </span><i class="d-none icofont-unlock"></i> tickets ouvert</p>
                            <p  class="reporting-ticket-details"><span style="color:#955196;" class="pending reporting-ticket-number">11</span><i class="d-none icofont-gear"></i> tickets en cours</p>
                            <p  class="reporting-ticket-details"><span style="color:#dd5182;" class="waiting reporting-ticket-number">2 </span><i class="d-none icofont-question-circle"></i> en attente client</p>
                            <p  class="reporting-ticket-details"><span style="color:#ff6e54;" class="postponed reporting-ticket-number">4 </span><i class="d-none icofont-ui-clock"></i> tickets ajournés</p>
                            <p  class="reporting-ticket-details"><span style="color:#ffa600;" class="abandoned reporting-ticket-number">1 </span><i class="d-none icofont-ui-block"></i> tickets abandonnés</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-100" style="text-align:right">

            </div>
		</div>
	</div>
    `;

    loadPath(value) {

    }

    static FromPath(value) {
		var applet = super.New("Rapport");
		applet.loadPath(value);
		return applet;
	}

    static New(path=null, new_window=false, application=null) {
        var applet = super.New("Rapport", new_window, application);
        return applet;
    }

    showPanel(panelName) {
        this.window.find(".panel").addClass("d-none");
        this.window.find(".panel-" + panelName).removeClass("d-none");
    }

    constructor() {
        if (!super("Rapport"))
            return;
            
        this.window
            .setSize(750, 500)
            .setMinSize(180, 250)
            .setPosition(135,75)
            .setClosable(true)
            .setExpandable()
            .setBorderless()
            .center()
            .setResizable();

        this.window.window.addClass('darkglass');
        this.window.find(".btn-close").click(() => {this.window.close()});
        this.window.setHandle(this.window.find(".handle"));

        this.currentWeek = null;
        this.currentYear = null;

        this.update_data();

        this.window.find(".btn-prev").click(() => {
            if(this.currentWeek == null)
                return;

            this.currentWeek -= 1;
            if (this.currentWeek == 0) {
                this.currentWeek=52;
                this.currentYear -=1;
            }
            this.update_data({week:this.currentWeek, year:this.currentYear});
        });

        this.window.find(".btn-next").click(() => {
            if(this.currentWeek == null)
                return;

            this.currentWeek += 1;
            if (this.currentWeek == 53) {
                this.currentWeek=1;
                this.currentYear +=1;
            }
            this.update_data({week:this.currentWeek, year:this.currentYear});
        })

        this.window.find(".panel-show").click((evt) => {
            this.showPanel($(evt.currentTarget).attr("panel"));
        });

        //this.update_reporting_pie(AppletReporting.data);
        this.create_reporting_pie();

        this.window.find(".daterangepicker").kendoDateRangePicker();
        this.window.find(".btn-custom").click(() => {
            this.window.find("#titleRow").addClass("d-none");
            this.window.find("#titleRowCustom").removeClass("d-none");
        })

        kendo.culture("fr-FR")
        var daterangepicker = this.window.find(".daterangepicker").data("kendoDateRangePicker");
        daterangepicker.setOptions({
            fillMode:"none"
        })

        this.window.find(".btn-search").click(() => {
            var dates = this.window.find(".daterangepicker").data("kendoDateRangePicker").range()
            if (dates == null)
                this.update_data({});
            else
                this.update_data({start: dates.start.toISOString(), end: dates.end.toISOString()});
        })


        this.status = {
            open:`<i class="icofont-unlock"></i> Ouvert`,
            pending:`<i class="icofont-gear"></i> En cours`,
            waiting:`<i class="icofont-question-circle"></i> Attente de retour client`,
            closed:`<i class="icofont-lock"></i> Fermé`,
            postponed:`<i class="icofont-ui-clock"></i> Ajourné`,
            abandoned:`<i class="icofont-ui-block"></i> Abandonné`,
        };
    }

    update_data(dates) {
        Foroyar.get("/reporting", dates, (response) => {
            this.currentWeek = response.time.week;
            this.currentYear = response.time.year; 

            var from_date = new Date(response.time.from*1000);
            var to_date = new Date(response.time.to*1000);

            const options = {day: 'numeric', year:'numeric', month:'long'};

            if (response.time.from <= 0) {
                this.window.find(".date-title").html(`Statistiques globales`);
            } else {
                this.window.find(".date-title").html(`Semaine du ${from_date.toLocaleString("fr-FR",options)} au ${to_date.toLocaleString("fr-FR",options)}`);
            }
            

            var global = 0;
            if (response.data.global != undefined)
                global = response.data.global.count;
            this.window.find(".ticket-amount").html(global);
            

            var data = [];
            ["closed","open","pending","waiting","postponed","abandoned"].forEach((status) => {
                var val = 0;
                if (response.data.global!=undefined && response.data.global.statuses[status]!=undefined) {
                    val = response.data.global.statuses[status].count;
                }
                data.push(val);
                this.window.find(`.reporting-ticket-number.${status}`).html(val);
            });

            this.update_pie_data(data);
            this.update_by_op_table(response.operators, response.by_operator);
        },
        (error) => {
            this.window.showAlert(error.message)
        })
    }

    update_pie_data(data) {
        this.chart.data.datasets.forEach((dataset) => {
            dataset.data = data;
        });
        this.chart.update();
    }

    update_by_op_table(operators, by_operator) {
        operators['unassigned'] = {
            nickname:"Non assigné",
            color:"150,150,150"
        }
        this.window.find(".panel-by-op").empty();
        Object.keys(by_operator).forEach((op_uuid) => {
            var op_data = by_operator[op_uuid];
            var op = operators[op_uuid];
            var node = $(`<div class="col"><div style="color:rgb(${op.color})"class="op-label">${op.nickname}</div></div>`);
            Object.keys(op_data).forEach((status) => {
                node.append(`<div class="status-label">${this.status[status]}</div>`);
                op_data[status].forEach((ticket) => {
                    node.append(`<a class="ticket-link ${ticket.priority}" tooltip="${ticket.title}" uuid="${ticket.uuid}" href="/p/ticket/${ticket.uuid}">[${ticket.number}] ${ticket.title}</a>`)
                });
            });
            this.window.find(".panel-by-op").append(node);
        });
        this.window.find(".panel-by-op .ticket-link").click((evt) => {
            evt.preventDefault();
            Applet.LoadFromPath("/ticket/" + $(evt.currentTarget).attr("uuid"));
        })
        Widget.SetupTooltips(this.window);
    }

    create_reporting_pie() {
        const ctx = $('#reportingPie');
        this.chart = new Chart(ctx, {
            type: 'doughnut',
            legend: { display:false},
            data: {
                labels: ["Tickets fermés","Tickets ouvert","Tickets en cours","En attente client","Tickets ajournés","Tickets abandonnés"],
                datasets: [{
                    label: '# of Votes',
                    data: [0,0,0,0,0,0],
                    backgroundColor: ["#003f5c","#444e86","#955196","#dd5182","#ff6e54","#ffa600"],
                    borderWidth: 0
                }]
            },
            options: {
                cutout:'80%',
                plugins: {
                    legend: { display:false}
                }
            }
        });
        

    }
}

AppletReporting.Initialize();