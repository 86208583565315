import Applet from './applet.js';
import Menus from './appletMenus.js';
import AppletVCard from './appletVCard.js';
import AutoForm from './autoForm.js';
import SettingsManager from './settingsManager.js';

export default class AppletAddressBook extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static appname="addressbook";
    static taskbarIcon = "address-book";

    static content = `
    <div id="WindowContact">
        <div class="flex row m-12 h-100">
            <div style="width:48px;min-width:48px;">
                <i style="display:block;font-size:xx-large;" id="btnContact" class="icofont-people icon-button"></i>
                <i style="display:block;font-size:xx-large;" id="btnOrganization" class="icofont-home icon-button"></i>
            </div>
            <div class="flex column" style="width:320px;min-width:320px;">
                <widget class="flex flex-grow" type="list" field="obj_list"></widget>
                <div>
                    <button id="buttonAdd" class="inline"><i class="icofont-plus"></i></button>
                    <button id="buttonDelete" class="inline"><i class="icofont-minus"></i></button>
                </div>
            </div>
            <div class="flex-grow" id="editPanel" style="padding-left:12px;">
                <div id="contactPanel" class="w-100">
                    <profile-img style="float:right;text-align:right;" id="picture" uuid="">
                        <button class="inline small solid"><i id="pictureDelete" class="icofont-close"></i></button>
                    </profile-img>
                    <p class="condensed"><i class="icofont-people grey"> </i><label style="width:110px;">Prénom</label><input id="firstname" disabled></input></p>
                    <p class="condensed"><i class="icofont-tag grey"> </i><label style="width:110px;">Nom</label><input id="lastname" disabled></input></p>
                    <p class="condensed"><i class="icofont-tag grey"> </i><label style="width:110px;">Surnom</label><input id="nickname" disabled></input></p>
                    <p class="condensed">
                        <i class="icofont-home grey"> </i>
                        <label style="width:110px;">Entreprise</label>
                        <input id="company" disabled></input>
                        <i class="icofont-delete grey" id="companyErase"></i>
                    </p>
                    <p class="condensed"><i class="icofont-email grey"> </i><label style="width:110px;">Email</label><input id="email" disabled></input></p>
                    <p class="condensed"><i class="icofont-smart-phone grey"> </i><label style="width:110px;">Téléphone</label><input id="phone" disabled></input></p>

                    <button id="colorSelect" class="inline color-container">
                        <i style="font-size:xx-large; text-shadow: 0px 1px 2px #00000038;" class="icofont-paint"></i>
                        <!--<div id="userColor" style="width:36px; height:24px; background-color: #45ec10; display:inline-block;"></div>-->
                    </button>
                </div>
                <div id="organizationPanel" class="w-100" style="display:none;">
                    <p class="condensed"><i class="icofont-home grey"> </i><label style="width:110px;">Enseigne</label><input id="name" disabled></input></p>
                    <p class="condensed"><i class="icofont-email grey"> </i><label style="width:110px;">Adresse</label><textarea rows=3 id="address" disabled></textarea></p>
                    <p class="condensed"><i class="icofont-tag grey"> </i><label style="width:110px;">Type</label><input id="type" disabled></input></p>
                    <p class="condensed">
                        <i class="icofont-people grey"> </i>
                        <label style="width:110px;">Contacts</label>
                        <div class="flex row">
                            <ul class="item-list flex-grow" id="contacts" value="" disabled></ul>
                            <button class="inline" style="height:43px;" id="contactsAdd" disabled><i class="icofont-plus"></i></button>
                        </div>
                    </p>
                    <p class="condensed">
                        <i class="icofont-web grey"> </i>
                        <label style="width:110px;">Domaines</label>
                        <textarea rows=3 id="domains" disabled></textarea>
                    </p>
                </div>
                <div style="text-align:right;">
                    <button id="buttonApply" style="display:none;" class="inline">Appliquer</button>
                    <button id="buttonModify" class="inline"><i class="icofont-pencil"></i></button>
                    <button id="buttonVCard" class="inline"><i class="icofont-id"></i></button>
                </div>
            </div>
        </div>
		
	</div>
    `;

    enable_modification(value, create=false) {
        if (create) {
            this.window.find("#buttonApply").html('Créer');
        } else {
            this.window.find("#buttonApply").html('Appliquer');
        }
        const fields = ["firstname", "lastname", "email", "nickname", "company", "phone", 'name','address','domains','contacts','type', 'contactsAdd'];

        if(value) {
            fields.forEach((field) => {
                this.window.find("#" + field).removeAttr('disabled');
            });
            this.window.find("#buttonModify").css('display','none');
            this.window.find("#buttonVcard").css('display','none');
            this.window.find("#buttonApply").css('display','inline-block');
        } else {
            fields.forEach((field) => {
                this.window.find("#" + field).attr('disabled','1');
            });
            this.window.find("#buttonModify").css('display','inline-block');
            this.window.find("#buttonVcard").css('display','inline-block');
            this.window.find("#buttonApply").css('display','none');
        }
        this.modification = value;
    }

    select_contact(uuid) {
        $.get(`/${this.mode}/get`, {uuid: uuid}, (res) => {
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else if (this.mode == 'contact') {
                this.window.find("#firstname").val(response.data.firstname);
                this.window.find("#lastname").val(response.data.lastname);
                this.window.find("#email").val(response.data.email);
                this.window.find("#nickname").val(response.data.nickname);
                this.window.find("#company").attr('value', response.data.company).val('');
                this.fetchFieldTitle(this.window.find("#company"), 'organization');
                this.window.find("#phone").val(response.data.phone);

                var col = [255,255,255];
                if (response.data.color != "")
                    var col = response.data.color.split(',');

                this.window.find('#colorSelect')
                    .css('color', `rgb(${col[0]} ${col[1]} ${col[2]})`)
                    .attr('color', `${col[0]},${col[1]},${col[2]}`);
                

                if (response.data.picture != "" && response.data.picture != null) {
                    this.window.find("#picture").attr('uuid', response.data.picture).css('background-image', `url(${SettingsManager.cdn}i/small/${response.data.picture})`);
                } else {
                    this.window.find("#picture").attr('uuid', '').attr('style','float:right;');
                }
            } else {
                this.window.find("#name").val(response.data.name);
                this.window.find("#address").val(response.data.address);
                this.window.find("#domains").val(response.data.domains.join(`\n`));
                this.window.find("#contacts").val(response.data.contacts.join(`\n`));
                this.window.find("#type").val(response.data.type);
            }
        });
    }

    loadPath(value) {
        this.window.find('#search').val(value);
        //this.update_contact_list();
    }

    setupListWidget(form_type) {
        this.autoform = new AutoForm(form_type);
        this.autoform.errorCallback = (err) => {this.window.showAlert(err)};
        this.autoform.loadForm(() => { 
            this.window.widgets['obj_list'].SetOptions(25);
            this.window.widgets['obj_list'].SetAutoform(this.autoform);
            this.window.widgets['obj_list'].SetElementClickedCallback((elt, uuid) => {
                this.enable_modification(false);
                this.current_uuid = uuid;
                this.select_contact(uuid);
            });
            this.window.widgets['obj_list'].updateList();
        });
    }

    static FromPath(value) {
		var applet = super.New("Ticket List");
		applet.loadPath(value);
		return applet;
	}

    static New() {
        const applet = super.New("Ticket List");
        //applet.update_contact_list();
        return applet;
    }

    constructor() {
        if (!super("Carnet d'addresse"))
            return;

            this.mode = "contact";
            
        this.window
            .setSize(950, 580)
            .setMinSize(815, 430)
            .setResizable()
            .setClosable()
            .setPinnable()

        this.modification = false;

        /*this.window.find('#search').keyup(() => {
            this.update_contact_list();
            this.setPath(this.window.find('#search').val());
        });*/

        this.window.find('#buttonModify').click(() => {
            this.enable_modification(true);
        });

        this.window.find('#buttonVCard').click(() => {
            AppletVCard.New(this.current_uuid);
        });

        const fields = ["firstname", "lastname", "email", "nickname", "company", "phone", 'name','address','domains','contacts','type'];

        this.window.find('#buttonAdd').click(() => {
            this.window.find('.item').removeClass('selected');
            this.enable_modification(true, true);
            this.current_uuid = "";
            this.window.find('#picture').attr('style','float:right;').attr('uuid','')
            fields.forEach((field) => {
                this.window.find('#' + field).val('');
                this.window.find('#' + field).removeAttr('value');
            });
            this.window.find('#colorSelect')
                .css('color', `rgb(255 255 255)`)
                .attr('color', '');

            this.window.find('#picture').attr('src','/assets/img/contact.png')
        })

        this.window.find('#company').focus((evt) => {
            Menus.showSearchPopup(this.window, this.window.find('#company'), 'organization');
        });

        this.window.find('#companyErase').click((evt) => {
            if (this.window.find('#company').prop('disabled') == false)
                this.window.find('#company').val('').attr('value','');
        });

        this.window.find('#contactsAdd').click((evt) => {
            Menus.showSearchPopup(this.window, this.window.find('#contacts'), 'contact', 'list');
        });

        this.window.find('#buttonDelete').click(() => { 
            this.window.showModal('Pas encore implémenté !','TODO', 'beach', true, true);
        });

        this.update_request = null;

        this.window.find('#btnContact').click((evt) => { 
            $("#listTitle").html('Liste des contacts');
            $('#organizationPanel').css('display','none');
            $('#contactPanel').css('display','block');
            this.mode = "contact";
            $(this.currentTarget).addClass('selected');
            this.window.find('#btnOrganization').removeClass('selected');
            this.setupListWidget("vcard");
            //this.update_contact_list();
        });

        this.window.find('#pictureDelete').click((evt) => {
            evt.preventDefault();
            if (this.modification) {
                this.window.find('#picture').attr('style','float:right;').attr('uuid','');
            }
        });

        this.window.find('#btnOrganization').click((evt) => { 
            $("#listTitle").html('Liste des sociétés');
            $('#organizationPanel').css('display','block');
            $('#contactPanel').css('display','none');
            this.mode = "organization";
            $(this.currentTarget).addClass('selected');
            this.window.find('#btnContact').removeClass('selected');
            this.setupListWidget("organization");
            //this.update_contact_list();
        });

        this.window.find('.color-container').click((evt) => {
            if (!this.modification)
                return;
            Menus.showColorPickerPopup(this.window, this.window.find($(evt.currentTarget)), (col) => {
                this.window.find('#colorSelect')
                    .css('color', `rgb(${col[0]} ${col[1]} ${col[2]})`)
                    .attr('color', `${col[0]},${col[1]},${col[2]}`);
            });
        });

        this.window.find('#picture').click((evt) => {
            if (!this.modification)
                return;
            this.openMediaLibrary((uuid) => {
                if (uuid != null) {
                    this.window.find('#picture').css('background-image', `url(${SettingsManager.cdn}i/small/${uuid})`).attr('uuid',uuid);
                }
            })
        })

        this.window.find('#buttonApply').click((evt) => {
            this.enable_modification(false);
            var data = {uuid : this.current_uuid, color:'', picture:'', create:'0'};
            if (this.current_uuid == "")
                data.create = '1';

            if (this.mode == 'contact') {
                ['firstname','lastname','email','nickname', 'phone'].forEach((field) => {
                    data[field] = this.window.find('#' + field).val();
                });
                var color = this.window.find('#colorSelect').attr('color');
                if (color != "") {
                    data['color'] = color;
                }
                data['company'] = this.window.find('#company').attr('value');
                data['picture'] = this.window.find('#picture').attr('uuid');

            } else {
                ['name','address','type'].forEach((field) => {
                    data[field] = this.window.find('#' + field).val();
                });
                ['domains','contacts'].forEach((field) => {
                    data[field] = this.window.find('#' + field).val().split(`\n`);
                });
            }
            $.post(`/${this.mode}/update`, {'data': JSON.stringify(data)}, (res) => {
                const response = res;
                if (response.status != 'OK') {
                    this.window.showAlert(response.message);
                } else { 
                    //this.update_contact_list();
                }
            });
        });

        this.setupListWidget("vcard");
    }
}

AppletAddressBook.Initialize();