import Applet from './applet.js';
//import Window from './window.js';
import AutoFormField from './autoFormFields.js';

export default class Widget {
    static initialized = false;


    static Setup(root, window) {

        if (!this.initialized) {
            $('main').append(
                $('<div class="ftooltip"></div>')
            )
            this.initialized = true;
        }

        this.SetupWHandle(root, window);
        this.SetupScrollBar(root, window);
        this.SetupTabs(root, window);
        this.SetupiLinks(root, window);

        this.SetupWidgets(root, window);
        this.SetupTooltips(root);
    }


    static UpdateTooltip(content=null, mouse_x=-1, mouse_y=-1) {
        if (content == null) {
            $('.ftooltip').css('display','none');
        } else {
            $('.ftooltip').html(content);
            var x = Math.min(window.innerWidth - $(".ftooltip").width() - 20, mouse_x - $(".ftooltip").width() / 2);
            x = Math.max(0,x);
            $('.ftooltip')
                .css('display','block')
                .css('left',`${x}px`)
                .css('top',`${mouse_y-50}px`)
                .html(content);
        }
    }

    static SetupWidgets(root, window) {
        if (window == undefined)
            return;

        //import('./autoFormFields.js').then((Module) => {
            if(window.widgets == undefined)
                window.widgets = {};

            root.find('widget').each((idx, elt) => {
                //if(!$(elt).is(':empty'))
                //    return;

                const type = $(elt).attr('type');
                const field = $(elt).attr('field');

                var label = $(elt).attr('label');

                var options = $(elt).attr('options');
                if (options != undefined)
                    options = options.split(' ');
                else
                    options = [];

                const formClass = AutoFormField.FindFormClass(type, options);
                if (formClass == null)
                    return;
            
                const widg = new formClass(field, label, type, options);
                widg.generate();
                $(elt).append(widg.element);
                window.widgets[field] = widg;
            });
        //});
    }

    static HideInfoPopUp(root) {
        root.find('.info-popup').remove();
    }

    static ShowInfoPopUp(content,root, alt_button_label=null, alt_button_callback=null) {
        root.find('.info-popup').remove();
        var html = $(`
        <div class="flex row info-popup" style="position:sticky;top:0px;left:0px;width:100%;height:0px;justify-content:center;">
            <div class="info-popup-container" style="width:100%; height:0px; transition:all .25s ease-out;overflow:hidden;">
                <div class="flex row" style="width: 80%; margin:auto; height: 64px; background-color: rgba(220,220,220,.8); border-bottom-left-radius: 12px; border-bottom-right-radius: 12px; border-bottom: 1px solid #ccc; border-left: 1px solid #f5f5f5; backdrop-filter: blur(5px); padding: 8px;">
                    <div class="flex-grow" style="margin: auto; max-width: 75%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                        ${content}
                    </div>
                    <span style="cursor:pointer;margin:auto;padding:12px;" class="inline small button-dismiss">Fermer</span>
                </div>
            </div>
        </div>
        `);
        if(alt_button_callback != null) {
            html.find('.button-dismiss').before($(`<span style="cursor:pointer;margin:auto;padding:12px;font-weight:300;" class="inline small button-dismiss button-alt">${alt_button_label}</span>`));
            html.find('.button-alt').click((evt) => { alt_button_callback(evt)});
        }
        html.find('.button-dismiss').click(() => {
            html.find('.info-popup-container').css('height','0px');
            setTimeout(() => {html.remove()}, 300);
        });
        root.css('position','relative').prepend(html);
        setTimeout(() => {html.find('.info-popup-container').css('height','64px')},10);
    }

    static SetupiLinks(root) {
        root.find('.ilink').click((evt) => {
            evt.preventDefault();
            Applet.LoadFromPath($(evt.currentTarget).attr('href'));
        });
    }

    static SetupTabs(root) {
        root.find('.tab').each((idx, element) => {
            $(element).find('.tab-button').click((evt) => {
                $(element).find('.tab-button').removeClass('selected');
                $(evt.currentTarget).addClass('selected');
                const tab_id = $(evt.currentTarget).attr('tab');
                $(element).find(`.tab-panel`).removeClass('selected');
                $(element).find(`.tab-panel[tab="${tab_id}"]`).addClass('selected');
            })
        });
    }


    static SetupTooltips(root) {
        root.find('[tooltip]').mouseenter((evt) => {
            this.UpdateTooltip($(evt.currentTarget).attr("tooltip"), evt.pageX, evt.pageY);
        }).mouseleave((evt) => {
            this.UpdateTooltip();
        });
    }

    static SetupScrollBar(root) {
        root.find('.scrollbar-v').each((index,scrollbar) => {
            $(scrollbar).append($(
                `<div class="scrollbar-v-bar" style="height:0%;"></div>
                 <div class="scrollbar-v-handle" style="height:0%;"></div>`
            ));

            const container = $($(scrollbar).attr('target'));
            container.scroll((evt) => {
                const handle_percent = container.height() / container[0].scrollHeight * 100;
                const bar_percent = container.scrollTop() / container[0].scrollHeight * 100;
                $(scrollbar).find('.scrollbar-v-bar').css('height', `${bar_percent}%`);
                $(scrollbar).find('.scrollbar-v-handle').css('height', `${handle_percent}%`);
            });
            $(scrollbar).mousedown((evt) => {
                const handle_percent = container.height() / container[0].scrollHeight * 100;

                const offset_y = evt.offsetY;
                const height = $(scrollbar).height();

                var height_percent = offset_y / height * 100 - handle_percent / 2;
                height_percent = Math.min(Math.max(0,height_percent), 100.0 - handle_percent);
                container.scrollTop( container[0].scrollHeight * height_percent / 100 );
            });
        });
    }

    static SetupWHandle(root, window) {
        root.find('.hr-container').each((ind,root_container) => {
            $(root_container).find('.whandle').remove();

            $(root_container).css('display','grid').css('grid-gap','0px');
            const n = $(root_container).children().length;
            $(root_container).children().each((i,cell) => { 
                $(cell).css('grid-row','1').attr('style', `grid-row:1;grid-column:${i+1};`); 
            });
            for (let i=1; i < n; i++) {
                $(root_container).append(`<div class="whandle" style="grid-row:1;grid-column:${i+1};"></div>`)
            }

            $(root_container).find('.whandle').each((index, element) => {
                $(element).mousedown((evt) => {
                    window.window.css('user-select','none');
                    document.onmousemove = (evt) => {

                        const containers = $(element).siblings('div:not(.whandle)');
                        
                        const cont_left = $(containers[index]);
                        const cont_right = $(containers[index+1]);
                        
                        const x = evt.pageX - cont_left.offset().left + parseFloat(cont_left.css('margin-left').replace('px',''));
                        const w = cont_left.outerWidth(true) + cont_right.outerWidth(true)
                        var ratio = x / w;

                        ratio = Math.max(0,Math.min(1,ratio));

                        var ratios = [];
                        $(element).parent().css('grid-template-columns').split(' ').forEach((r) => {
                            ratios.push(parseFloat(r.replace('px','')));
                        });

                        const total = ratios[index] + ratios[index+1];
                        ratios[index] = total * ratio;
                        ratios[index+1] = (1-ratio)*total;

                        var cols = "";
                        ratios.forEach((r) => { cols += `${r}fr `});

                        $(element).parent().css('grid-template-columns',cols);

                    };
                    document.onmouseup = (evt) => {
                        window.window.css('user-select','unset');
                        document.onmousemove = null;
                        document.onmouseup = null;
                    };
                })
            });
        });
    }
}