import Applet from './applet.js';
import marked from 'marked';

export default class AppletWelcome extends Applet {
    static unique = true;
    static instance = [];
    static version = "0.0.16.1";
    static shownInTaskBar = true;
    static taskbarIcon = "sail-boat-alt-2";
    static appname="welcome";

    static content = `
    <div id="WindowWelcome">
        <div class="flex flex-column h-100">
            <div class="welcome-icon" style="display:flex; justify-content:center;">
                <i class="icofont-sail-boat-alt-2"></i>
                <!--<h2 style="text-align:center;">Bienvenue</h2>-->
            </div>
            <p>Bienvenue sur Føroyar !</p>
            <div class="flex-grow" style="overflow-y:scroll;">
                <div class="changelog flex-grow" style="padding:12px;">Chargement...</div>
            </div>
        </div>
	</div>
    `;

    loadPath(value) {

    }

    static FromPath(value) {
		var applet = super.New("Message d'accueil");
		applet.loadPath(value);
		return applet;
	}

    setForboyard() {
        $('#glCanvas').css("background-image","url(/assets/img/forboyard.jpg)");

    }

    constructor() {
        if (!super('Message d\'accueil'))
            return;
        this.window.setSize(650,660)
            .setClosable(true)
            .setMinSize(450,460)
			.addCloseButtonToFooter("Fermer")
            .center()
            .setOnTop()
            .pin()

        const d = new Date();
        if(d.getMonth() == 3 && d.getDate() < 8)
            this.setForboyard();

        $.get('/changelog', (res) => {
            var html = marked.parse( res, {
                gfm: true,
                breaks: true,
                tables: true,
                xhtml: true,
                headerIds: false
            } );
            this.window.find('.changelog').html(html);
        })

        $.get('/version', (res) => {
            if (res.replace("\n","") != AppletWelcome.version) {
                this.window.showModal(`La version des scripts en cache (${AppletWelcome.version}) est dépassée. Merci de rafraichir votre cache navigateur pour passer à la version <b>${res}</b>`, 'Mise à jour', 'arrow-up', true);
            }
        })
        

    }
}

AppletWelcome.Initialize();