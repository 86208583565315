export default class Foroyar {
    static get(url, payload, success_callback=null, error_callback=null, progress_callback=null, send_file=false) {
        return Foroyar.request("GET", url, payload, success_callback, error_callback, progress_callback, send_file)
    }

    static post(url, payload, success_callback=null, error_callback=null, progress_callback=null, send_file=false) {
        return Foroyar.request("POST", url, payload, success_callback, error_callback, progress_callback, send_file)
    }

    static put(url, payload, success_callback=null, error_callback=null, progress_callback=null, send_file=false) {
        return Foroyar.request("PUT", url, payload, success_callback, error_callback, progress_callback, send_file)
    }

    static delete(url, payload, success_callback=null, error_callback=null, progress_callback=null, send_file=false) {
        return Foroyar.request("DELETE", url, payload, success_callback, error_callback, progress_callback, send_file)
    }

    static patch(url, payload, success_callback=null, error_callback=null, progress_callback=null, send_file=false) {
        return Foroyar.request("PATCH", url, payload, success_callback, error_callback, progress_callback, send_file)
    }

    static request(method, url, payload, success_callback=null, error_callback=null, progress_callback=null, send_file=false) {
        console.log('Preparing request');
        const req_xhr = $.ajax({
            type: method,
            url: url,
            data: payload,
            processData: send_file ? false : true,
            contentType: send_file ? false : 'application/x-www-form-urlencoded; charset=UTF-8',
            success: (data, textStatus, jqXHR) => {
                var res;
                try {
                    res = JSON.parse(data);
                } catch (error) {
                    /*if(error_callback != null) {
                        error_callback('invalid_json', `Invalid JSON received : ${data}`)
                    }
                    return;*/
                    res = data;
                }
                if (res.status == 'OK') {
                    if (success_callback != null) {
                        success_callback(res.data);
                    }
                } else {
                    error_callback(res.error_code, res.message);
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                try {
                    res = JSON.parse(jqXHR.responseText);
                    if (error_callback != null) {
                        error_callback(res.error, res.message);
                    }
                } catch (error) {
                    if (error_callback != null) {
                        error_callback('network_error', `Network error ${jqXHR.status} : ${jqXHR.responseText}`);
                    }
                }
            },
            //dataType: "text"
        });
        return req_xhr;
    }
}