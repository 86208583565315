import Menus from './appletMenus.js';
import AppletLauncher from './appletLauncher.js';
import Utils from './utils.js';

export default class TaskBar {
    static taskBarRoot = null;
    static initialized = false;
    static winList = {};
    static appList = [];

    static Initialize() {
        this.taskBarRoot = $("#taskBar");
        this.winList = {};

        var apps = AppletLauncher.get_app_list();
        var menu = [];
        apps.forEach((app) => {
            menu.push([app.name, app.className]);
        });
        $("#brand").click((evt) => {
            evt.preventDefault();
            Menus.showMenu($('nav'), $(evt.currentTarget), menu, (app) => {AppletLauncher.LaunchApp(app);});
        });
        TaskBar.initialized = true;
    }

    static SetTitle(win, new_title) {
        var uid = null;
        Object.keys(this.winList).forEach((k) => {
            if (this.winList[k] == win)
                uid = k;
                return;
        });
        if (uid == null) {
            console.log('uid non trouvé');
            return;
        }
        this.taskBarRoot.find('#' + uid).find('.tbwin-title').html(new_title);
    }

    static _SetSelectedWindow(uid) {
        this.taskBarRoot.find('.taskbar-element').removeClass('selected');
        this.taskBarRoot.find('#' + uid).addClass('selected');
    }

    static AddWindow(win, name, icon) {
        Object.keys(this.winList).forEach((k) => {
            if (this.winList[k] == win)
                return k;
        });

        const uid = Utils.generate_uid("tbwin_");
        this.winList[uid] = win;

        this.taskBarRoot.append(`<div class="taskbar-element" id="${uid}"><i class="icofont-${icon}"> </i><span class="tbwin-title">${name}</span></div>`)
        this.taskBarRoot.find('#' + uid).click((evt) => {
            const win = this.winList[$(evt.currentTarget).attr('id')];
            if (this.last_clicked_window == uid && !win.reduced) {
                win.setReduced(true);
                this.taskBarRoot.find('#' + uid).removeClass('selected');
            } else {
                win.setReduced(false);
                win.setOnTop();
            }
            this.last_clicked_window = uid;
            TaskBar._SetSelectedWindow(uid);
        }).on('auxclick',(evt) => {
            if (evt.button == 1) {
                const win = this.winList[$(evt.currentTarget).attr('id')];
                evt.preventDefault();
                win.center().setOnTop();
            }

        }).contextmenu((evt) => {
            evt.preventDefault();
            Menus.showMenu($('nav'), $(evt.currentTarget), [
                ['Renommer','rename'],
                ['Décoration','decorate'],
                ['Réduire','reduce'],
                ['Centrer','center'],
                ['Restaurer','restore'],
                ['Pop', 'popout'],
                ["Fermer", "close"]
            ], (res) => {
                const win = this.winList[$(evt.currentTarget).attr('id')];
                switch(res) {
                    case "rename":
                        win.showInputBox("", "Nom de la fenêtre", (value) => { win.setTitle(value);});
                        break;
                    case "reduce":
                        win.setReduced(!win.reduced);
                        break;
                    case "decorate":
                        win.setBorderless(!win.window.hasClass('borderless'));
                        break;
                    case "center":
                        win.center().setOnTop();
                        break;
                    case "popout":
                        win.browserPopUp();
                        break;
                    case "restore":
                        win.setSize(win.minSize[0],win.minSize[1]).center().setOnTop();
                        break;
                    case "close":
                        win.close();
                        break;
                    default:
                        break;
                }
            });
        });

    }

    static SetLastClickedWindow(win) {
        var uid = null;
        Object.keys(this.winList).forEach((k) => {
            if (this.winList[k] == win)
                uid = k;
                return;
        });
        if (uid != null) {
            this.last_clicked_window = uid;
            TaskBar._SetSelectedWindow(uid);
        }
    }

    static SetReduced(win, value) {
        var uid = null;
        Object.keys(this.winList).forEach((k) => {
            if (this.winList[k] == win)
                uid = k;
                return;
        });
        if (uid == null) {
            console.log('uid non trouvé');
            return;
        }
        if (value)
            this.taskBarRoot.find('#' + uid).addClass('reduced');
        else
            this.taskBarRoot.find('#' + uid).removeClass('reduced');
    }

    static RemoveWindow(win) {
        var uid = null;
        Object.keys(this.winList).forEach((k) => {
            if (this.winList[k] == win)
                uid = k;
                return;
        });
        if (uid == null) {
            console.log('uid non trouvé');
            return;
        }
        this.taskBarRoot.find('#' + uid).remove();
        delete this.winList[uid];

    }
}