import Applet from './applet.js';
import Menus from './appletMenus.js';
import AppletTickets from './appletTickets.js';
import Foroyar from './foroyar.js';
import Utils from './utils.js';

export default class AppletTicketGrid extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "table";
    static appname="ticketgrid";

    static content = `
    <div>
        <div class="d-flex">
            <input id="search" /><i class="icofont-search mr-3 p-3"></i>
            <button class="btn-toggle btn-toggle-tickets selected btn-toggle-maintenance">Assistance</button>
            <button class="btn-toggle btn-toggle-tickets btn-toggle-evolution">Evolution</button>
            <button class="btn-toggle btn-toggle-events">Évènements</button>
            <div class="d-events d-none">
                <label for=evtFrom class="event-input d-block m-3 invisible">Du </label>
                <input class="ml-3 event-input" type=date id=evtFrom>
                <label for=evtTo class="d-block m-3 event-input"> Au </label>
                <input class="ml-3 event-input" type=date id=evtTo>
            </div>
            <div class="d-grid d-flex pl-3">
                <input type=checkbox id=inputMyTickets>
                <label class="pt-3 pl-1" for=inputMyTickets>Mes tickets</label>
            </div>
            <div class="d-grid d-flex pl-3">
                <input type=checkbox id=inputBugTickets>
                <label class="pt-3 pl-1" for=inputBugTickets>Bugs uniquement</label>
            </div>
            <button class="inline ml-auto btn-menu">Menu</button>
            <button class="inline btn-export">Exporter</button>
        </div>
        <div id="gridEvents" class="d-events d-flex flex-column flex-grow-1 d-none" style="background-color:rgba(0,0,0,0); border:0px;"></div>
		<div id="grid" class="d-grid d-flex flex-column flex-grow-1" style="background-color:rgba(0,0,0,0); border:0px;"></div>
        <div class="flex row">
            <button id="btnNew" class="inline">Nouveau</button>
            <div style="display:inline-block;" class="flex-grow"></div>
        </div>
	</div>
    `;

    loadPath() {
        
    }

    static FromPath(value) {
		var applet = super.New("TicketGrid");
		applet.loadPath(value);
		return applet;
	}

    update_projects() {
        if (this.projects === undefined)
            return;

        this.window.find('.project').each((i, element) => {
            $(element).html(this.projects[$(element).attr('uuid')]);
        });
    }

    update_tags() {
        if (this.operators === undefined)
            return;

        this.window.find('.tag').each((index,tag) => {
            const uuid = $(tag).attr('uuid');
            if (uuid == "" || uuid == undefined)
                return

            if (uuid in this.operators) {
                const operator = this.operators[uuid];
                if (operator.nickname == "")
                    $(tag).html(operator.username);
                else
                $(tag).html(operator.nickname);

                var hue = operator.hue;

                $(tag).css('filter',`hue-rotate(${hue}deg)`);
            }
        });
    }

    update_priority(priority) {
		var color = "";
		var icon = "";
		switch(priority) {
			case "low":
				color = "cyan";
				icon = "shrimp";
				break;
			case "medium":
				color = "green";
				icon = "fish-4";
				break;
			case "high":
				color = "orange";
				icon = "squid";
				break;
			case "critical":
				color = "red";
				icon = "octopus";
				break;
		}
		return [color, icon];
	}

    constructor() {
        if (!super("TicketGrid"))
            return;
            
        this.window
            .setSize(1250, 750)
            .setMinSize(180, 250)
            .center()
            .setClosable(true)
            .setPinnable()
            .setExpandable()
            .setResizable();

        Applet.FetchOperators((operators) => { this.operators = operators; this.update_tags()});
        Applet.FetchProjects((operators) => { 
            this.projects = operators; 
            this.update_projects();
            this.createGrid();
            this.createEventsGrid(this.window.find('#gridEvents'));
        });

        this.maintenance = true;

        this.window.find('#search').keyup(() => {
            this.dataSource.read();
        })

        this.window.find('#btnNew').click(() => {
            AppletTickets.New(null, false, this);
        })

        this.window.find('#inputMyTickets').click((evt) => {
            this.setOrderPriority($(evt.currentTarget).prop('checked'));
            this.dataSource.read();
        })

        this.window.find('#inputBugTickets').click((evt) => {
            this.setBugFilter($(evt.currentTarget).prop('checked'));
            this.dataSource.read();
        })

        this.window.find(".btn-toggle").click((evt) => {
            this.window.find('.btn-toggle').removeClass('selected');
            $(evt.currentTarget).addClass('selected');
        });

        this.window.find(".btn-toggle-maintenance").click((evt) => {
            this.maintenance = true;
            this.window.find(".btn-toggle").removeClass("selected");
            $(evt.currentTarget).addClass("selected");
            this.dataSource.read();
        });

        this.window.find(".btn-toggle-evolution").click((evt) => {
            this.maintenance = false;
            this.window.find(".btn-toggle").removeClass("selected");
            $(evt.currentTarget).addClass("selected");
            this.dataSource.read();
        });

        this.window.find(".btn-toggle-tickets").click((evt) => {
            this.window.find(".d-grid").addClass('d-flex').removeClass("d-none");
            this.window.find(".d-events").removeClass('d-flex').addClass("d-none");
            this.window.find(".event-input").addClass('invisible');
        });

        this.window.find(".btn-toggle-events").click((evt) => {
            this.window.find(".d-grid").removeClass('d-flex').addClass("d-none");
            this.window.find(".d-events").addClass('d-flex').removeClass("d-none");
            this.window.find(".event-input").removeClass('invisible');
        });

        setTimeout(() => { $('#gridEvents').removeClass('d-flex')}, 1000);

        this.templates = {};
        this.currentTemplate = "new_template";

        this.contact_cache = {}

        this.generateMenu();

        this.columnTemplatesExcel = {
            "priority" : (element) => {
                return element.priority;
            },
            "instance":(element) => {
                if (element.instance == "")
                    return "Aucun";
                else
                    return `${element.instance}`;
            },
            "client_parent":(element) => {
                if (element.instance == "")
                    return "";
                else
                    return `${element.client_parent}`;
            },
            "software":(element) => {
                if (element.software == "")
                    return "Aucun";
                else
                    return `${element.software}`;
            },
            "contacts":(element) => {
                var content = [];
                if (element.contacts == undefined)
                    return "";
                element.contacts.forEach((contact) => {
                    if (!contact.includes(":")) {
                        content.push(contact);
                    } else {
                        var t = contact.split(":")[0];
                        var v = contact.split(":")[1]
                        if (t == "uuid" || t == "vcard") {
                            if (Object.keys(this.contact_cache).includes(v))
                                content.push(this.contact_cache[v]);
                            else
                                content.push("uuid:" + v);
                        } else {
                            content.push(v);
                        }
                    }
                });
                return content.join(", ");
            },
            "assignments":(element) => {
                var content = [];
                try {
                    element.assignments.forEach((op) => {
                        if (op == "")
                            return;
                        content.push(this.operators[op].nickname);
                    });
                } catch {
                    return "";
                }
                return content.join(", ");
            },
            "created_by":(element) => {
                var content = this.operators[element.created_by].nickname;
                return content;
            },
            "created":(element) => {
                const options = {
                    year:"numeric",
                    month:"2-digit",
                    day:"2-digit",
                    hour:"2-digit",
                    minute:"2-digit"
                }
                const date = new Date(parseFloat(element.created * 1000))
                return date.toLocaleString("fr-FR", options);
            },
            "closed":(element) => {
                if(element.closed == null)
                    return null;
                const options = {
                    year:"numeric",
                    month:"2-digit",
                    day:"2-digit",
                    hour:"2-digit",
                    minute:"2-digit"
                }
                const date = new Date(parseFloat(element.created * 1000))
                return date.toLocaleString("fr-FR", options);
            },
            "modified":(element) => {
                const options = {
                    year:"numeric",
                    month:"2-digit",
                    day:"2-digit",
                    hour:"2-digit",
                    minute:"2-digit"
                }
                const date = new Date(parseFloat(element.modified * 1000))
                return date.toLocaleString("fr-FR", options);
            },
            "type":(element) => {
                const ticket_types = {
                    'maintenance':'Maintenance',
                    'specifics':'Développement spécifique',
                    'evolution':'Demande d\'évolution',
                }
                return ticket_types[element.type];
            },
            "origin":(element) => {
                if (element.origin == undefined)
                    return "Non spécifié";
                const origins = {
                    'mail':'Mail',
                    'phone':'Téléphone',
                    'client':'Suivi client'
                }
                return origins[element.origin];
            },
            "categories":(element) => {
                var content = "";
                if (element.categories == undefined)
                    return "";
                try {
                    element.categories.forEach((cat) => {
                        if (cat == "")
                            return;
                        content += `<span class="category" uuid="${cat}"></span>`;
                    });
                } catch {
                    return "";
                }
                return content;
            },
            "completion":(element) => {
                return `${element.completion}%`;
            },
            "external_links":(element) => {
                if (element.external_links == null)
                    return "";
                var content = "";
                element.external_links.forEach((link) => {
                    content += link[2];
                });
                return content;
            },
            "title":(element)=> {
                return element.title;
                //return `<a href="https://foroyar.nevea.fr/">${element.title}</a>`
            }
        }

        this.columnTemplates = {
            "status" : (element) => { 
                var icon="unlocked";
                switch (element.status) {
                    case "closed":
                        icon = "lock";
                        break;
                    case 'pending':
                        icon = 'gear';
                        break;
                    case 'waiting':
                        icon = 'question-circle';
                        break;
                    case "abandoned":
                        icon = "ui-block";
                        break;
                    case "evolution":
                        icon = "space-shuttle";
                        break;
                    case "postponed":
                        icon = "ui-clock";
                        break;
                    case "evol_open":
                        icon = "question-circle";
                        break;
                    case "evol_pending":
                        icon = "tick-mark";
                        break;
                    case "evol_rejected":
                        icon = "close";
                        break;
                    case "evol_closed":
                        icon = "check-circled";
                        break;
                    case "to_be_closed":
                        icon = "eye";
                        break;
                    case "evol_pending_validation":
                        icon = "ui-clock";
                        break;
                }
                return `<i class='icofont-${icon}'></i>`;
            },
            "priority" : (element) => {
                const info = this.update_priority(element.priority);
                return `<i class="icofont-${info[1]}" style="color:${info[0]};"></i>`;
            },
            "title":(element) => {
                var ticket_number = parseInt(element.number).toString();
                if (isNaN(ticket_number))
                    ticket_number = element.number
                var content = `<a href='/p/ticket/${ticket_number}' target="_blank" class="ticket-link" uuid="${element.uuid}">${element.title}</a>`;
                /*element.assignments.forEach((op) => {
                    if (op == "")
                        return;
                    content += `<span class="tag condensed" style="display:inline;" uuid="${op}"></span>`;
                });*/
                return content;
            },
            "type":(element) => {
                const ticket_types = {
                    'maintenance':'Maintenance',
                    'specifics':'Développement spécifique',
                    'evolution':'Demande d\'évolution'
                }
                return ticket_types[element.type];
            },
            "origin":(element) => {
                if (element.origin == undefined)
                    return "Non spécifié";
                const origins = {
                    'mail':'Mail',
                    'phone':'Téléphone',
                    'client':'Suivi client'
                }
                return origins[element.origin];
            },
            "assignments":(element) => {
                var content = '';
                if (element.assignments == null && element.assignments == undefined) return "";
                element.assignments.forEach((op) => {
                    if (op == "")
                        return;
                    content += `<span class="tag condensed" style="display:inline;" uuid="${op}"></span>`;
                });
                return content;
            },
            "created_by":(element) => {
                var content = `<span class="tag condensed" style="display:inline;" uuid="${element.created_by}"></span>`;
                return content;
            },
            "instance":(element) => {
                if (element.instance == "")
                    return "<span class='grey'>Aucun</span>";
                else
                    return `<span class="project" uuid="${element.instance}"></span>`;
            },
            "client_parent":(element) => {
                if (element.instance == "")
                    return "";
                else
                    return `<span class="project" uuid="${element.client_parent}"></span>`;
            },
            "software":(element) => {
                if (element.instance == "")
                    return "<span class='grey'>Aucun</span>";
                else
                    return `<span class="project" uuid="${element.software}"></span>`;
            },
            "contacts":(element) => {
                var content = "";
                element.contacts.forEach((contact) => {
                    if (contact == "")
                        return;
                    content += Utils.generateMailTag(contact).prop("outerHTML");
                });
                return content;
            },
            "created":(element) => {
                const options = {
                    year:"numeric",
                    month:"2-digit",
                    day:"2-digit",
                    hour:"2-digit",
                    minute:"2-digit"
                }
                const date = new Date(parseFloat(element.created * 1000))
                return date.toLocaleString("fr-FR", options);
            },
            "closed":(element) => {
                if(element.closed == null)
                    return "";
                const options = {
                    year:"numeric",
                    month:"2-digit",
                    day:"2-digit",
                    hour:"2-digit",
                    minute:"2-digit"
                }
                const date = new Date(parseFloat(element.closed * 1000))
                return date.toLocaleString("fr-FR", options);
            },
            "modified":(element) => {
                const options = {
                    year:"numeric",
                    month:"2-digit",
                    day:"2-digit",
                    hour:"2-digit",
                    minute:"2-digit"
                }
                const date = new Date(parseFloat(element.modified * 1000))
                return date.toLocaleString("fr-FR", options);
            },
            "categories":(element) => {
                var content = "";
                element.categories.forEach((cat) => {
                    if (cat == "")
                        return;
                    content += `<span class="category" uuid="${cat}"></span>`;
                });
                return content;
            },
            "external_links":(element) => {
                var content = "";
                if(element.external_links == null)
                    return "";
                element.external_links.forEach((link) => {
                    if(link[0] == "tuleap") {
						var node = `<a href="${link[2]}" target="_blank" class="tuleap-link"><img src="/assets/img/tuleap.png" class="mx-1" height=16>Tuleap #${link[1]}</a>`;
						content += node;
					}
                });
                return content;
            },
            "completion":(element) => {
                return `<span class="progress-bar"><span id="inputCompletion" class="progress-bar-percentage" style="width:${element.completion}%">${element.completion}%</span></span>`;
            }
        }
    }

    createGridEventsDataSource() {
        var dataSource = new kendo.data.DataSource({
            transport : {
                read: {
                    url: '/events',
                    type : "get",
                    dataType : "json",
                    data: (ctx) => { return { evtfrom:this.window.find('#evtFrom').val(), evtto:this.window.find('#evtTo').val(), take : ctx.take, skip : ctx.skip }; }
              },
              parameterMap: function(options, operation) {
                if(options.sort != undefined)
                    options.sort = JSON.stringify(options.sort);
                if(options.filter != undefined)
                    options.filter = JSON.stringify(options.filter);
                return options;
              }
            },
            batch: true,
            pageSize: 20,
            serverPaging : true,
            //serverSorting : true,
            //serverFiltering: true,
            schema : {
                data: "data.events",
                total: "data.count",
                fields : {
                    ticket : { type : "string"},
                    ticket_uuid : { type : "string"},
                    number : { type : "string"},
                    type : { type : "string"},
                    date : { type : "string"},
                    date_fmt : { type : "string"},
                    content : { type : "string"},
                    operator : { type : "string"},
                    created : {type : "string"},
                    status : {type : "string"},
                    priority : {type : "string"},
                    assignments : {type : "string"},
                    software : {type : "string"},
                    origin : {type : "string"}
                },
                model : { uuid: 0, name : 1}
            }
        })
        return dataSource;
    }

    createEventsGrid(node) {
        node.kendoGrid({
            dataSource: this.createGridEventsDataSource(),
            dataBound: (evt) => { 
                this.update_tags();
                Utils.lookUpMailTags(this.window, this.contact_cache);
                this.update_projects();
                this.window.find('#gridEvents').find('.ticket-link').click((evt) => {
                    evt.preventDefault();
                    AppletTickets.New($(evt.currentTarget).attr('uuid'),false,this);
                })
            },
            height: 650,
            groupable: true,
            filterable: true,
            sortable: true,
            resizable:true,
            reorderable: true,
            pageable: {
                refresh: true,
                pageSizes: [20,50,200,'all'],
            },
            
            columns: [
                {
                    field : "operator",
                    title : "Par_",
                    template : '<span class="tag condensed" style="display:inline;" uuid="#= data.operator #"></span>',
                    width : '80px'
                },
                {
                    field : "date_fmt",
                    title : "Date",
                    template : "Le #= data.date_fmt[0] # à #= data.date_fmt[1] #",
                    width : '190px'
                },
                {
                    field : "number",
                    title : "Numéro",
                    width : "120px"
                },
                {
                    field : "ticket",
                    title : "Ticket",
                    template : `<a href='/p/ticket/#= data.number #' target="_blank" class="ticket-link" uuid="#= data.ticket_uuid #">#= data.ticket #</a>`
                },
                {
                    field : "ticket_uuid",
                    title : "Ticket id",
                    hidden : true
                },
                {
                    field : "type",
                    title : "Type",
                    width : "160px",
                    values : [
                        { text : "Changement logiciel", value : "software" },
                        { text : "Changement titre", value : "title" },
                        { text : "Changement pièce-jointe", value : "attachment" },
                        { text : "Origine", value : "origin" },
                        { text : "Créé depuis ticket d'évolution", value : "from_evolution" },
                        { text : "Mail", value : "mail" },
                        { text : "Changement contenu", value : "content" },
                        { text : "Changement type de ticket", value : "ticket_type" },
                        { text : "Création ticket", value : "new_ticket" },
                        { text : "Ajout commentaire", value : "comment" },
                        { text : "Changement de status", value : "status" },
                        { text : "Changement d'assignation", value : "assignments" },
                        { text : "Créé depuis un mail", value : "from_mail" },
                        { text : "Changement d'instance", value : "instance" },
                        { text : "Changement priorité", value : "priority" },
                        { text : "Changement de temps estimé", value : "estimated_time" },
                        { text : "Vers ticket d'évolution", value : "to_evolution" },
                    ]
                },
                {
                    field : "date",
                    title : "Date",
                    hidden : true
                },
                {
                    field : "content",
                    title : "Contenu",
                    /*width : "50%",*/
                    template : (data) => {
                        var node = this.formatEventContent(data.type, data.content);
                        return node;
                    }
                },
                {
                    field : "status",
                    title : "Statut",
                    template : (element) => { 
                        var icon="unlocked";
                        switch (element.status) {
                            case "closed":
                                icon = "lock";
                                break;
                            case 'pending':
                                icon = 'gear';
                                break;
                            case 'waiting':
                                icon = 'question-circle';
                                break;
                            case "abandoned":
                                icon = "ui-block";
                                break;
                            case "evolution":
                                icon = "space-shuttle";
                                break;
                            case "postponed":
                                icon = "ui-clock";
                                break;
                            case "evol_open":
                                icon = "question-circle";
                                break;
                            case "evol_pending":
                                icon = "tick-mark";
                                break;
                            case "evol_rejected":
                                icon = "close";
                                break;
                            case "evol_closed":
                                icon = "check-circled";
                                break;
                            case "to_be_closed":
                                icon = "eye";
                                break;
                            case "evol_pending_validation":
                                icon = "ui-clock";
                                break;
                        }
                        return `<i class='icofont-${icon}'></i>`;
                    }
                },
                {
                    field : "priority",
                    title : "Priorité",
                    template : (element) => {
                        const info = this.update_priority(element.priority);
                        return `<i class="icofont-${info[1]}" style="color:${info[0]};"></i>`;
                    }
                },
                {
                    field : "origin",
                    title : "Origine",
                    template :(element) => {
                        if (element.origin == undefined)
                            return "Non spécifié";
                        const origins = {
                            'mail':'Mail',
                            'phone':'Téléphone',
                            'client':'Suivi client'
                        }
                        return origins[element.origin];
                    }
                },
                {
                    field : "assignments",
                    title : "Assignations",
                    template :(element) => {
                        var content = '';
                        if (element.assignments == null && element.assignments == undefined) return "";
                        element.assignments.forEach((op) => {
                            if (op == "")
                                return;
                            content += `<span class="tag condensed" style="display:inline;" uuid="${op}"></span>`;
                        });
                        return content;
                    }
                },
                {
                    title : "Logiciel",
                    field : "software",
                    template:(element) => {
                        if (element.instance == "")
                            return "<span class='grey'>Aucun</span>";
                        else
                            return `<span class="project" uuid="${element.software}"></span>`;
                    }
                },
                {
                    field : "created",
                    title : "Créé le",
                    template :(element) => {
                        const options = {
                            year:"numeric",
                            month:"2-digit",
                            day:"2-digit",
                            hour:"2-digit",
                            minute:"2-digit"
                        }
                        const date = new Date(parseFloat(element.created * 1000))
                        return date.toLocaleString("fr-FR", options);
                    }
                },

            ],
        });
    }

    formatEventContent(evt_type, content) {
        content = content.split('|')
        var node = `<div class="history ${evt_type}">`;
        switch(evt_type) {
            case "software":
                node += `Logiciel changé de <b>${content[0]}<b/> à <b>${content[1]}</b>`;
                break;
            case "title":
                node += `Titre changé de <b>${content[0]}<b/> à <b>${content[1]}</b>`;
                break;
            case "attachment":
                node += `Modification des pièces-jointes`;
                break;
            case "origin":
                node += `Origine changée de <b>${content[0]}<b/> à <b>${content[1]}</b>`;
                break;
            case "from_evolution":
                node += `Ticket créé depuis un ticket d'évolution`;
                break;
            case "mail":
                node += "Mail associé au ticket";
                break;
            case "content":
                node = `<div class="history">Contenu du ticket modifié`;
                break;
            case "ticket_type":
                node += `Type changé de <b>${content[0]}<b/> à <b>${content[1]}</b>`;
                break;
            case "new_ticket":
                node += `Ticket créé`;
                break;
            case "comment":
                const strippedContent = content[0].replace(/(<([^>]+)>)/gi, "");
                node += strippedContent;
                break;
            case "status":
                node += `Statut passé de <b>${content[0]}<b/> à <b>${content[1]}</b>`;
                break;
            case "assignments":
                if (content[0][0] == "+")
                    node += `Ticket assigné à <span class="tag condensed" style="display:inline;" uuid="${content[0].substring(1)}"></span>`
                else if (content[0][0] == "-")
                    node += `Ticket desassigné de <span class="tag condensed" style="display:inline;" uuid="${content[0].substring(1)}"></span>`
                break;
            case "from_mail":
                node += `Ticket créé depuis un mail`;
                break;
            case "instance":
                node += `Instance changée de <b>${content[0]}<b/> à <b>${content[1]}</b>`;
                break;
            case "priority":
                node += `Priorité changée de <b>${content[0]}<b/> à <b>${content[1]}</b>`;
                break;
            case "estimated_time":
                node += `Temps estimé passé de <b>${content[0]}<b/> à <b>${content[1]}</b>`;
                break;
            case "to_evolution":
                node += `Converti en ticket d'évolution`;
                break;
        }
        return node + "<div>";
    }

    setBugFilter(value) {
        var filter = [
            { field:"status", operator : "neq", value:"closed"},
            { field:"status", operator : "neq", value:"abandoned"}
        ];
        if(value) {
            filter.push({ field : "title", operator : "contains", value : "#b "});
        }

        this.dataSource.filter(filter);
    }

    setOrderPriority(value) {

    }

    createGrid() {
        Date.prototype.getWeekNumber = function(){
            var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
            var dayNum = d.getUTCDay() || 7;
            d.setUTCDate(d.getUTCDate() + 4 - dayNum);
            var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
            return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
          };

        this.project_values = [];
        Object.keys(this.projects).forEach((uuid) => {
            this.project_values.push({text:this.projects[uuid],value:uuid});
        });
        this.project_values.push({text:"Aucun",value:""});
        

        this.dataSource = new kendo.data.DataSource({
            transport: {
              read: {
                url: "/ticket/list",
                type: "get",
                dataType: "json",
                data: () => { return { search:this.window.find('#search').val(), devonly : $('#inputMyTickets').prop('checked') ? "true" : "false",  maintenance:this.maintenance }; }
              },
              parameterMap: function(options, operation) {
                if(options.sort != undefined)
                    options.sort = JSON.stringify(options.sort);
                if(options.filter != undefined)
                    options.filter = JSON.stringify(options.filter);
                return options;
              }
            },
            filter: {
                logic : "and",
                filters: [
                    { field:"status", operator : "neq", value:"closed"},
                    { field:"status", operator : "neq", value:"abandoned"}
                ]
            }, 
            batch: true,
            pageSize: 20,
            serverPaging : true,
            serverSorting : true,
            serverFiltering: true,
            schema: {
                data: "data.data",
                total: "data.count",
                fields: {
                    uuid: { type: "string" },
                    number: { type: "string" },
                    title:{ type: "string" },
                    status:{ type: "string" },
                    assignments:{ type: "string" },
                    created_by:{ type: "string" },
                    instance:{ type: "string" },
                    client_parent:{type:"string"},
                    priority:{ type: "string" },
                    contacts:{ type: "string" },
                    created:{ type: "date" },
                    modified:{ type: "date" },
                    categories:{ type: "string" },
                    estimated_time:{ type: "string" },
                    completion:{ type: "number" },
                    software:{ type: "string" },
                    type:{ type:"string "},
                    origin:{ type:"string" },
                    closed:{ type:"date"},
                    external_links:{type:"string"}
                },
                model: { uuid: 0, name: 1 }
            }
        });

        this.window.find('#grid').kendoGrid({
            dataSource: this.dataSource,
            dataBound: (evt) => { 
                this.update_tags();
                Utils.lookUpMailTags(this.window, this.contact_cache);
                this.update_projects();
                this.window.find('#grid').find('.ticket-link').click((evt) => {
                    evt.preventDefault();
                    AppletTickets.New($(evt.currentTarget).attr('uuid'),false,this);
                })
            },
            height: 650,
            selectable: "multiple",
            groupable: true,
            filterable: true,
            sortable: true,
            resizable:true,
            reorderable: true,
            columnMenu: {
                componentType: "modern",
                columns: {
                    sort: "asc",
                    groups: [
                        { 
                            title: "Colonnes", 
                            columns: [
                                "status","priority","created","assignments",
                                "created_by","modified","instance", "client_parent", 
                                "contacts","estimated_time","completion",'closed',
                                'origin','type','software','external_links','week','link'
                            ] 
                        }
                    ]
                }
            },
            pageable: {
                refresh: true,
                //pageSizes: true,
                //buttonCount: 5,
                pageSizes: [20,50,200,'all'],
            },
            
            columns: [
                {
                    values: [
                        {text:"Fermé",value:"closed"},
                        {text:"En cours",value:"pending"},
                        {text:"Attente de retour client",value:"waiting"},
                        {text:"Ouvert",value:"open"},
                        {text:"Abandonné",value:"abandoned"},
                        {text:"Évolution",value:"evolution"},
                        {text:"Reporté",value:"postponed"},
                        {text:"A statuer",value:"evol_open"},
                        {text:"Accepté",value:"evol_pending"},
                        {text:"Rejeté",value:"evol_rejected"},
                        {text:"Terminé",value:"evol_closed"},
                        {text:"À clôturer",value:"to_be_closed"},
                        {text:"À valider par Xavier",value:"evol_pending_validation"},
                    ],
                    template: this.columnTemplates["status"],
                    field: "status",
                    title: "Statut",
                    width:60,
                    
                },
                {
                    field: "priority",
                    values: [
                        {text:"Basse",value:"low"},
                        {text:"Moyenne",value:"medium"},
                        {text:"Haute",value:"high"},
                        {text:"Critique",value:"critical"},
                    ],
                    title: "Priorité",
                    width:60,
                    template: this.columnTemplates["priority"]
                },
                {
                    field: "number",
                    title: "Numéro",
                    width:120
                },
                {
                    field: "title",
                    title: "Sujet",
                    width:550,
                    template: this.columnTemplates["title"]
                },
                {
                    field: "assignments",
                    title: "Assigné à",
                    template: this.columnTemplates["assignments"]
                },
                {
                    field: "created_by",
                    title: "Créé par",
                    template: this.columnTemplates["created_by"]
                },
                {
                    field:"instance",
                    title:"Client",
                    values: this.project_values,
                    template: this.columnTemplates["instance"]
                },
                {
                    field:"client_parent",
                    title:"Réseau",
                    values: this.project_values,
                    template: this.columnTemplates["client_parent"]
                },
                {
                    field:"software",
                    title:"Logiciel",
                    values: this.project_values,
                    template: this.columnTemplates["software"]
                },
                {
                    field:"type",
                    title:"Type",
                    //values: ["maintenance","evolution","specifics"],
                    template: this.columnTemplates["type"]
                },
                {
                    field:"origin",
                    title:"Origine",
                    //values: ["mail","client","phone"],
                    template: this.columnTemplates["origin"]
                },
                {
                    field: "contacts",
                    title: "Demande de",
                    template:this.columnTemplates["contacts"]
                },
                {
                    field: "created",
                    hidden:true,
                    title: "Créé le",
                    template: this.columnTemplates["created"]
                },
                {
                    field: "closed",
                    hidden:true,
                    title: "Fermé le",
                    template: this.columnTemplates["closed"]
                },
                {
                    field: "modified",
                    title: "Modifié",
                    template: this.columnTemplates["modified"]
                },
                {
                    field: "categories",
                    title: "Catégories",
                    hidden: true,
                    template: this.columnTemplates["categories"]
                },
                {
                    field: "estimated_time",
                    hidden:true,
                    title: "Temps estimé",
                },
                {
                    field: "external_links",
                    title: "Liens externes",
                    template: this.columnTemplates["external_links"]
                },
                {
                    field: "completion",
                    title: "Complétion",
                    template: this.columnTemplates["completion"]
                },
                {
                    field: "week",
                    title: "Semaine",
                    hidden: true,
                    template: (data) => { return (new Date(data.created*1000)).getWeekNumber(); }
                },
                {
                    field: "link",
                    title: "Lien",
                    hidden: true,
                    template: (data) => { return "https://foroyar.nevea.fr/p/ticket/" + data.number}
                }

                
            ],
            excelExport: (e) => {
                var grid = this.window.find('#grid').data("kendoGrid");
                var sheet = e.workbook.sheets[0];

                var columns = [];
                grid.columns.forEach((col) => {
                    if (col.hidden != true)
                        columns.push(col)
                })

                for (var i = 1; i < sheet.rows.length; i++) {
                    
                    var row = sheet.rows[i];
                    console.log(row);

                    var dataItem = {};

                    for (var j=0; j<columns.length; j++) {
                        dataItem[columns[j].field] = row.cells[j];
                        if (row.cells[j] != undefined)
                            dataItem[columns[j].field] = row.cells[j].value;
                    }

                    for (var j=0; j<columns.length; j++) {
                        var field = columns[j].field;
                        var template = this.columnTemplatesExcel[field];
                        if (template != undefined && row.cells[j] != undefined) {
                            row.cells[j].value = template(dataItem)
                        }
                    }
                    
                }
            },
        });

        this.grid = this.window.find('#grid').data("kendoGrid");

        this.window.find(".btn-export").click(() => {
            var grid = this.window.find('#grid').data("kendoGrid");
            grid.saveAsExcel();
        });

        this.window.find(".btn-menu").click((evt) => {
            Menus.showMenu(this.window, $(evt.currentTarget), this.menu, (value) => {this.menuCallback(value)})
        })

    }

    generateMenu() {
        this.templates = {};

        this.menu = [
            ["Enregistrer", "save"]
        ];

        Foroyar.get("/userSettings/grid_template", {}, (res) => {
            this.menu.push("separator")
            var data = JSON.parse(res[0])
            this.templates = data;
            Object.keys(data).forEach((template) => {
                this.menu.push([template, "template:" + template]);
            });
        },
        (error, msg) => {

        })
        
    }

    saveTemplate(title) {
        if (title == "")
            return;

        this.currentTemplate = title;
        this.templates[title] = {
            "groups":this.dataSource.group(),
            "options":kendo.stringify(this.grid.getOptions()),
            "filter":this.dataSource.filter(),
            "search":this.window.find('#search').val()
        };

        Foroyar.post(
            "/userSettings/grid_template", 
            {
                data:JSON.stringify(this.templates)
            }, 
            (res) => {console.log(res); this.generateMenu()}, 
            (error,msg) => { console.log(error,msg);}
        );  
    }

    loadTemplate(title) {
        var template = this.templates[title];
        this.currentTemplate = title;



        var options = JSON.parse(template["options"]);
        var groups = template["groups"];
        var search = template["search"];
        var filters = template["filters"];

        options.columns.forEach((col) => {
            if (Object.keys(this.columnTemplates).includes(col.field)) {
                col.template = this.columnTemplates[col.field];
            }
        })
        this.dataSource.group(groups);
        this.dataSource.filter(filters);
        options.dataSource = this.dataSource;
        this.window.find('#search').val(search);
        options.dataBound = (evt) => { 
            this.update_tags();
            Utils.lookUpMailTags(this.window);
            this.update_projects();
            this.window.find('#grid').find('.ticket-link').click((evt) => {
                evt.preventDefault();
                AppletTickets.New($(evt.currentTarget).attr('uuid'),false,this);
            })
        };

        this.grid.setOptions(options);
        this.dataSource.read();

        this.window.setTitle("Tickets - " + title);
    }

    menuCallback(value) { 
        if (value == "save") {
            this.window.showInputBox("Choisir un nom pour le template", "Template", (content) => {this.saveTemplate(content);})
            
        } else if (value.split(":")[0] == "template") {
            this.loadTemplate(value.split(":")[1]);
        }
    }
}

AppletTicketGrid.Initialize();