import Applet from './applet.js';
import Foroyar from './foroyar.js';
import Window from './window.js';
import Widget from './widget.js';

export default class AppletMailAccounts extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "gear";
    static appname="mailaccounts";

    static content = `
    <div id="MailAccounts">
		<div class="flex row nowrap m-12 h-100">
            <div style="width:48px;min-width:48px;">
                <i style="display:block;font-size:xx-large;" id="btnUserSettings" class="icofont-gear icon-button"></i>
                <i style="display:block;font-size:xx-large;" id="btnGeneral" class="icofont-globe-alt selected icon-button"></i>
                <i style="display:block;font-size:xx-large;" id="btnPersonal" class="icofont-user icon-button"></i>
            </div>

            <div class="panel-settings flex-grow-1">
                <h3>Paramètres généraux</h3>
                <div class="general-settings">

                </div>
            </div>

            <div class="panel-search d-none flex column h-100" style="width:280px;min-width:280px;">
                <input class="w-90 m-12" id="search" placeholder="Recherche...">
                <h4 id="listTitle">Compte généraux</h4>
                <ul id="accountList" class="item-list flex-grow" style="overflow-y:auto;">

                </ul>
                <div>
                    <button class="inline" id="btn-add-account">Ajouter</button>
                    <button class="inline" id="btn-remove-account"><i class="icofont-minus"></i></button>
                </div>
            </div>

			<div class="panel-form d-none form" style="flex-grow:2; margin-left:15px;" id="formPanel">
                <div class="flex row" style="margin-left:0px;">
                    <div class="switch" style="margin-right:10px;"></div>
				    <h3 class="flex-grow">Paramètre du compte</h3>
                </div>
				<div class="inline-input"><label style="width:125px;">Nom du compte</label><input id="field_name" placeholder="" /></div>
                <div class="inline-input"><label style="width:125px;">Adresse</label><input id="field_address" placeholder="" /></div>
				<div class="form-container" id="ImapContainer">
					<h4>IMAP</h4>
					<div class="m-3 grid-form-2">
                        <label class="rcol-1">Serveur</label>
                        <input class="rcol-2" id="imap_server" placeholder="imap.server.com" />
						<label class="rcol-3">Port</label>
                        <input class="rcol-4" id="imap_port" placeholder="993" />
					</div>
                    <div class="m-3 grid-form-2">
                        <label  class="rcol-1">SSL</label>
                        <select id="imap_ssl" class="rcol-2">
                            <option value="none" selected>Aucun</option>
                            <option value="ssl">SSL/TLS</option>
                            <option value="starttls">Starttls</option>
                        </select>
                        <label class="rcol-3">Authentification</label>
                        <select class="rcol-4" id="imap_auth_method">
                            <option value="none" selected>Aucun</option>
                            <option value="plain">Text clair</option>
                            <option value="md5">MD5</option>
                            <option value="oauth">OAuth2</option>
                        </select>
                    </div>
					<div class="m-3 grid-form-2">
                        <label class="rcol-1 my-auto">Login</label>
                        <input class="rcol-2 my-auto" id="imap_login" placeholder="" />
                        <label class="rcol-3 imap_password_field">Mot de passe</label>
                        <input class="rcol-4 imap_password_field" id="imap_password" placeholder="" type="password" />
                        <button class="rcol-4 inline" id="imap_button_field" class="inline oauth-btn">Connexion OAuth2</button>
                    </div>
				</div>
				<div class="form-container" id="SmtpContainer">
					<h4>SMTP</h4>
					<div>
						<div class="inline-input" style="display:inline; width:64%;"><label style="width:110px;">Serveur</label><input id="smtp_server" placeholder="imap.server.com" /></div>
						<div class="inline-input" style="display:inline; width:34%;"><label style="width:110px;">Port</label><input id="smtp_port" placeholder="993" /></div>
					</div>
                    <div>
                        <div class="inline-input" style="display:inline-block; width:49%;">
                            <label style="width:110px;">SSL</label>
                            <select id="smtp_ssl">
                                <option value="none" selected>Aucun</option>
                                <option value="ssl">SSL/TLS</option>
                                <option value="starttls">Starttls</option>
                            </select>
                        </div>
                        <div class="inline-input" style="display:inline-block; width:50%;">
                            <label style="width:110px;">Authentification</label>
                            <select id="smtp_auth_method">
                                <option value="none" selected>Aucun</option>
                                <option value="plain">Text clair</option>
                                <option value="md5">MD5</option>
                                <option value="oauth">OAuth2</option>
                            </select>
                        </div>
                    </div>
					<div class="inline-input" style="display:inline;"><label style="width:110px;">Login</label><input id="smtp_login" placeholder="" /></div>
					<div class="inline-input" id="smtp_password_field" style="display:inline;"><label style="width:110px;">Mot de passe</label><input id="smtp_password" placeholder="" type="password" /></div>
                    <div class="inline-input" id="smtp_button_field" style="display:none;"><button class="inline oauth-btn">Connexion OAuth2</button></div>

				</div>
                <button id="buttonApply" class="inline">Appliquer</button>
			</div>
		</div>
	</div>`;

    createCodeWindow(user_code) {
        var content = "<div><div style='text-align:center'><p>Veuillez authentifier l'appareil à l'aide du code ci-dessous.</p><h1>" + user_code+ "</h1></div></div>"
        var codeWin = new Window('TicketWindowHistory', 'Historique', 0, 0, 390, 270, $(content)).setMinSize(390, 270)
        .setClosable(false)
        .setLite()
        .center().pin();
        return codeWin;
    }

    updateSettingSchema() {
        Foroyar.get("/userSettings/schema", null, (res) => {
            var setting_names = [];
            res.general.forEach((setting) => {
                setting_names.push(setting.name)
                var node = $(`<div class="setting mb-2"><h5>${setting.label}${setting.scope == "global" ? " <b>(global)</b>" : ""}</h5></div>`)
                if(setting.type == "string") {
                    node.append($(`<widget type="string" options="readonly" field="setting_${setting.name}"></widget>`));
                } else if (setting.type.split(":")[0] == "search") {
                    node.append($(`<widget type="${setting.type}" options="readonly" field="setting_${setting.name}"></widget>`));
                }
                node.append($(`<button style="float:right;" class="inline btn-modify-setting" widget="${setting.name}">Modifier</button>`));
                node.append(`<small>${setting.comment}</small>`);
                this.window.find(".general-settings").append(node);
                
            });
            this.window.find(".btn-modify-setting").click((evt) => {
                var setting = $(evt.currentTarget).attr("widget");
                var widget = this.window.w("setting_" + setting);
                if(widget.disabled) {
                    widget.disable(false);
                    $(evt.currentTarget).html("Enregistrer");
                } else {
                    widget.disable(true);
                    $(evt.currentTarget).html("Modifier");
                    Foroyar.post(`/userSettings/${setting}`, {data:widget.val()}, (res) => {
                        console.log(res);
                    })
                }
            })
            Widget.SetupWidgets(this.window.find(".general-settings"), this.window);
            console.log(setting_names);
            Foroyar.get(`/userSettings/${setting_names.join(",")}`, null, (res) => {
                for (var i=0; i<res.length; i++) {
                    console.log("setting_" + setting_names[i]);
                    this.window.w("setting_" + setting_names[i]).val(res[i]);
                }
            })
        },
        (error,msg) => {
            this.window.showAlert(msg)
        })
    }

    addEmptyAccount() {
        var account = {
            'name':"Nouveau compte",
            'uuid':'',
            'address':"",
            'IMAP': {
                'server':'',
                'port':'',
                'ssl':'none',
                'auth_method':'none',
                'login':'',
                'password':''
            },
            'SMTP': {
                'server':'',
                'port':'',
                'ssl':'none',
                'auth_method':'none',
                'login':'',
                'password':''
            },
        }
        if (!('' in this.accounts)) {
            this.accounts[''] = account;
        }
        this.updateAccountList();
        this.window.find('#account_').addClass('modified');
    }

    updateAccount() {
        var account = {
            'private': this.mode == "personal",
            'name': this.window.find('#field_name').val(),
            'uuid': this.currentAccount,
            'inbox': '/INBOX',
            'archives': '/ARCHIVES_FOROYAR',
            'address':this.window.find('#field_address').val(),
            'IMAP': {
                'server': this.window.find('#imap_server').val(),
                'port':this.window.find('#imap_port').val(),
                'ssl':this.window.find('#imap_ssl').val(),
                'auth_method':this.window.find('#imap_auth_method').val(),
                'login':this.window.find('#imap_login').val(),
                'password':this.window.find('#imap_password').val(),
                'protocol':'IMAP',
            },
            'SMTP': {
                'server': this.window.find('#smtp_server').val(),
                'port':this.window.find('#smtp_port').val(),
                'ssl':this.window.find('#smtp_ssl').val(),
                'auth_method':this.window.find('#smtp_auth_method').val(),
                'login':this.window.find('#smtp_login').val(),
                'password':this.window.find('#smtp_password').val(),
                'protocol':'SMTP',
            }
        }

        $.post('/settings/mailAccounts', {data:JSON.stringify(account)}, (res) => {
            if (res.status != 'OK') {
                alert(res.message);
            } else {
                this.currentAccount = res.data;
                this.refreshAccountList();
            }
        });
    }

    selectAccount(accountUuid) {
        const oldAccount = this.currentAccount;
        $('#account_' + oldAccount).removeClass('selected');

        if (!(accountUuid in this.accounts)) {
            if (this.accounts.length > 0) {
                this.currentAccount = this.accounts[0].uuid;
            } else {
                this.currentAccount = null;
            }
        } else {
            this.currentAccount = accountUuid;
        }

        if (this.currentAccount == null) {
            this.window.find('#formPanel').css('visibility','hidden');
        } else {
            this.window.find('#formPanel').css('visibility','visible');
            var account = this.accounts[this.currentAccount];
            if (this.currentAccount == "") {
                ["field_name", "field_address", "smtp_server", "smtp_port", "smtp_login", "smtp_password",
                "imap_server", "imap_port", "imap_login", "imap_password"].forEach((element) => {
                    this.window.find('#' + element).val('');
                });
                ['smtp_ssl', 'smtp_auth_method', 'imap_ssl', 'imap_auth_method'].forEach((element) => {
                    this.window.find('#' + element).val('none');
                });
                this.window.find('#smtp_auth_method').change();
                this.window.find('#imap_auth_method').change();
                $('#account_').addClass('selected');
            } else {
                $.get('/settings/mailAccounts', {uuid:this.currentAccount}, (res) => {
                    var data = res;
                    if (data.status != 'OK') {
                        alert(data.message);
                    } else {
                        var account = data.data;
                        $('#account_' + this.currentAccount).addClass('selected');

                        this.window.find('#field_name').val(account.name);
                        this.window.find('#field_address').val(account.address);

                        this.window.find('#smtp_server').val( account.SMTP.server);
                        this.window.find('#smtp_port').val( account.SMTP.port);
                        this.window.find('#smtp_ssl').val(account.SMTP.ssl);
                        this.window.find('#smtp_auth_method').val(account.SMTP.auth_method);
                        this.window.find('#smtp_auth_method').change();
                        this.window.find('#smtp_login').val( account.SMTP.login);
                        this.window.find('#smtp_password').val( (account.SMTP.password ? "12345678" : ""));

                        this.window.find('#imap_server').val( account.IMAP.server);
                        this.window.find('#imap_port').val( account.IMAP.port);
                        this.window.find('#imap_ssl').val(account.IMAP.ssl);
                        this.window.find('#imap_auth_method').val(account.IMAP.auth_method);
                        this.window.find('#imap_auth_method').change();
                        this.window.find('#imap_login').val( account.IMAP.login);
                        this.window.find('#imap_password').val( (account.IMAP.password ? "12345678" : ""));
                    }
                });
            }
        }
    }

    updateAccountList() {
        this.window.find('#accountList').empty();

        Object.keys(this.accounts).forEach((key) => {
            const account = this.accounts[key];
            this.window.find('#accountList').append(`<li class="item" id="account_${account.uuid}">
                    <img src='/assets/img/mail.png' width=32>
                    <span>${account.name} (${account.address})</span>
                </li>`);
        });
        this.window.find('#accountList').find('li').click((evt) => {
            var uuid = $(evt.currentTarget).attr('id').replace('account_','');
            this.selectAccount(uuid);
        })
        this.selectAccount(this.currentAccount);
    }

    refreshAccountList() {
        $.get('/settings/mailAccounts', {'private':this.mode == "personal"}, (res) => {
            var data = res;

            if (data.status == 'error') {
                alert(data.message);
                return;
            } else {
                this.accounts = {}
                data.data.forEach((account) => {
                    this.accounts[account.uuid] = account;
                });
                this.updateAccountList();

            }
        })
    }

    loadPath() {
        
    }

    static FromPath(value) {
		var applet = super.New("Comptes de messagerie");
		applet.loadPath(value);
		return applet;
	}

    constructor() {
        super("Paramètres utilisateur")

        this.window.setMinSize(600, 420).setSize(1072,750)
            .setClosable()
            .setPinnable()
            .setResizable()
            .setExpandable()
            .center()
            .setOverflow('auto');

        this.mode = "general";

        this.updateSettingSchema();

        this.window.find('.switch').click((evt) => {
            if ($(evt.currentTarget).hasClass('disabled'))
                return;
            if ($(evt.currentTarget).hasClass('off'))
                $(evt.currentTarget).removeClass('off');
            else
                $(evt.currentTarget).addClass('off');
        })

        this.window.find('#btnGeneral').click((evt) => {
            this.mode = "general";
            this.window.find(".panel-search").removeClass("d-none");
            this.window.find(".panel-form").removeClass("d-none");
            this.window.find(".panel-settings").addClass("d-none");

            this.window.find('#btnPersonal').removeClass('selected');
            this.window.find('#btnGeneral').addClass('selected');
            this.window.find('#listTitle').html('Comptes généraux')
            
            this.refreshAccountList();
        })
        this.window.find('#btnPersonal').click((evt) => {
            this.mode = "personal";
            this.window.find(".panel-search").removeClass("d-none");
            this.window.find(".panel-form").removeClass("d-none");
            this.window.find(".panel-settings").addClass("d-none");

            this.window.find('#btnPersonal').addClass('selected');
            this.window.find('#btnGeneral').removeClass('selected');
            this.window.find('#listTitle').html('Comptes personnels')
            this.refreshAccountList();
        })
        this.window.find('#btnUserSettings').click((evt) => {
            this.mode = "settings";
            this.window.find(".panel-search").addClass("d-none");
            this.window.find(".panel-form").addClass("d-none");
            this.window.find(".panel-settings").removeClass("d-none");
            //this.window.find('#listTitle').html('Paramètres')
            //this.refreshAccountList();
        })

        $('#smtp_auth_method').on('change', () => {
            if ($('#smtp_auth_method').val() == 'oauth') {
                $('#smtp_password_field').css('display','none');
                $('#smtp_button_field').css('display','unset');
            } else {
                $('#smtp_password_field').css('display','unset');
                $('#smtp_button_field').css('display','none');
            }
            
        });

        $('#imap_auth_method').on('change', () => {
            if ($('#imap_auth_method').val() == 'oauth') {
                $('.imap_password_field').css('display','none');
                $('#imap_button_field').css('display','unset');
            } else {
                $('.imap_password_field').css('display','unset');
                $('#imap_button_field').css('display','none');
            }
            
        });

        $('#buttonApply').click((evt) => {
            this.updateAccount();
        });

        $('#btn-add-account').click((evt) => {
            this.addEmptyAccount();
        });

        $('.oauth-btn').click((evt) => {
            $('.oauth-btn').attr('disabled','true');

            $.get('/oauth_device', {"uuid":this.currentAccount}, (res) => {
                const data = res;
                if (res.status == 'error') {    
                    alert('error occured', res.message);
                } else {
                    const url = data.data.verification_uri + "?user_code=" + data.data.user_code;
                    console.log(data.data);
                    var codeWin = this.createCodeWindow(data.data.user_code)
                    this.extWindow = window.open(url, "Authentication", "menubar=yes,location=yes,resizable=no,scrollbars=yes,status=yes");

                    $.post('/oauth_device', {'device_code':data.data.device_code, "uuid":this.currentAccount }, (res) => {
                        codeWin.close();
                        //alert(res);
                    });
                }
            }).fail(() => { 
                alert('failure')
            });


        });

        this.refreshAccountList()
        //this.window.find('#formPanel').css('visibility','hidden');
        
    }
}

AppletMailAccounts.Initialize();