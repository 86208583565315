import Applet from './applet.js';
import AutoForm from './autoForm.js';

export default class AppletAutoForm extends Applet {
    static unique = false;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "people";
    static appname="autoform";

    //static titlebar_content = `<i id="iconPopOut" class="icofont-external window-icon icon-expand"></i>`;

    static content = `
    <div id="WindowAutoForm">
        <div class="flex row nowrap m-12 h-100">
            <div id="iconBox" style="width:48px;min-width:48px;">
                <i style="display:block;font-size:xx-large;" form="user" class="icofont-people form-button icon-button"></i>
                <i style="display:block;font-size:xx-large;" form="vcard" class="icofont-ui-v-card form-button icon-button"></i>
                <i style="display:block;font-size:xx-large;" form="organization" class="icofont-home form-button icon-button"></i>
                <i style="display:block;font-size:xx-large;" form="oauth_session" class="icofont-web form-button icon-button"></i>
                <i style="display:block;font-size:xx-large;" form="mail_archive" class="icofont-email form-button icon-button"></i>
                <i style="display:block;font-size:xx-large;" form="ticket" class="icofont-ticket form-button icon-button"></i>
                <i style="display:block;font-size:xx-large;" form="signature" class="icofont-quill-pen form-button icon-button"></i>
                <i style="display:block;font-size:xx-large;" form="project" class="icofont-cube form-button icon-button"></i>
                <i style="display:block;font-size:xx-large;" form="kbpage" class="icofont-library form-button icon-button"></i>
                <i style="display:block;font-size:xx-large;" form="postit" class="icofont-tack-pin form-button icon-button"></i>
                <i style="display:block;font-size:xx-large;" form="setting" class="icofont-gear form-button icon-button"></i>
            </div>
            <div class="hr-container w-100">
                <div class="flex column h-100 flex-grow overflow-x-hidden overflow-y-auto" id ="listPanel" style="overflow-y:auto;">
                    <!--<input class="w-90 m-12" id="search" placeholder="Recherche...">
                    <h4>Résultats</h4>
                    <ul id="itemList" class="item-list flex-grow" style="overflow-y:auto; overflow-x:hidden;">

                    </ul>-->
                    <widget class="flex flex-grow" type="list" field="obj_list"></widget>
                    <div>
                        <button id="buttonAdd" class="inline"><i class="icofont-plus"></i></button>
                        <button id="buttonDelete" class="inline"><i class="icofont-minus"></i></button>
                    </div>
                    <button id='btnCancel' style="display:none;margin-right:58px; margin-bottom:24px;" class="">Annuler</button>
                </div>

                <div id="formPanel" class="flex-grow overflow-y-auto invisible" style="padding-left:12px; overflow-y:auto; overflow-x:hidden;">
                    <h3 id="formTitle">AutoForm</h3>
                    <div class="hide-scrollbar" id="autoTarget" style="padding-left:12px; max-height:100%;"></div>
                    <div style="text-align:right;">
                        <button id="buttonApply" style="display:none;" class="inline">Appliquer</button>
                        <button id="buttonModify" class="inline"><i class="icofont-pencil"></i></button>
                        <!--<button id="buttonPopOut" class="inline"><i class="icofont-id"></i></button>-->
                    </div>
                </div>
            </div>
        </div>
		
	</div>
    `;

    loadPath() {
        
    }

    static FromPath(value) {
		var applet = super.New("AutoForm");
		applet.loadPath(value);
		return applet;
	}

    popOutList() {

        this.list_window  = new Window('AutoFormList', 'Liste', 180, 120, 450, 420, $(`<div>
            <div class="flex row nowrap m-12 h-100">
                <div id="iconBox" style="width:48px;min-width:48px;"></div>
                <div id="listPanel" class="flex-grow"></div>
            </div></div>`))
            .setSize(400, 850)
            .setMinSize(400, 420)
            .setClosable()
            .setPinnable()
            .setResizable()
            .setExpandable()
            .center();

            this.list = this.list_window.find('.content')
            this.list_window.find('#listPanel').append(this.window.find('#listPanel'));
            this.list_window.find('#iconBox').append(this.window.find('#iconBox'));
    }

    loadForm(name) {
        this.autoform = new AutoForm(name);
        this.autoform.errorCallback = (err) => {this.window.showAlert(err)};
        this.autoform.loadForm(() => { 
            this.setPanelVisible(false);
            this.window.setTitle(this.autoform.label);
            this.window.find('#formTitle').html('');
            this.window.find('#autoTarget').empty();
            this.autoform.generateForm(this.window.find('#autoTarget'), this); 

            this.window.find('#buttonAdd').css('display','unset');
            if (!(this.autoform.rights.includes('creation'))) {
                this.window.find('#buttonAdd').css('display','none');
            }

            this.window.find('#buttonDelete').css('display','unset');
            if (!(this.autoform.rights.includes('deletion'))) {
                this.window.find('#buttonDelete').css('display','none');
            }

            this.window.find('#buttonModify').removeClass('invisible');
            this.window.find('#buttonApply').removeClass('invisible');
            if (!(this.autoform.rights.includes('modification'))) {
                this.window.find('#buttonModify').addClass('invisible');
                this.window.find('#buttonApply').addClass('invisible');
            }

        });
        //this.updateList('');
        this.setupListWidget(this.autoform);
    }

    setupListWidget(autoform) {
        this.autoform = autoform;
        this.autoform.errorCallback = (err) => {this.window.showAlert(err)};
        this.autoform.loadForm(() => { 
            this.window.widgets['obj_list'].SetOptions(25);
            this.window.widgets['obj_list'].SetAutoform(this.autoform);
            this.window.widgets['obj_list'].SetElementClickedCallback((elt, uuid) => {
                this.loadUuid(uuid);
            });
            this.window.widgets['obj_list'].updateList();
        });
    }

    static New(name) {
        var applet = super.New("AutoForm");
        if (name !== undefined)
            applet.loadForm(name);
        applet.list_window = null;
        return applet;
    }

    constructor() {
        super("AutoForm");
            
        this.window
            .setSize(900, 650)
            .setMinSize(800, 250)
            .setPosition(135,75)
            .setClosable(true)
            .setPinnable()
            .setExpandable()
            .setResizable();

        this.list = this.window.find('#listPanel');

        /*this.list.find('#search').keyup(() => {
            this.updateList(this.list.find('#search').val());
            this.setPath(this.list.find('#search').val());
        });*/

        this.current_uuid = null;
        this.current_item_title = "";

        this.window.find('#buttonModify').click(() => { this.enableModifications() });
        this.window.find('#buttonApply').click(() => { this.applyModifications() });
        this.list.find('#buttonAdd').click(() => { this.autoform.create(); this.setPanelVisible(true); this.window.find('#formTitle').html('Création ' + this.autoform.name); this.enableModifications();});
        this.list.find('#buttonDelete').click(() => { this.deleteItem(); });

        this.window.find('#iconPopOut').click((evt) => { this.popOutList(); $(evt.currentTarget).remove(); });

        this.window.find('.form-button').click((evt) => {
            const form_name = $(evt.currentTarget).attr('form');
            this.loadForm(form_name);
        });
    }

    deleteItem() {
        this.window.showYesNoModal(`Voulez-vous vraiment supprimer <strong>${this.current_item_title}</strong> ? <p style="color:red;">Cette opération est irréversible.</p>`, 
            'Suppresion', 
            'warning',
            () => {
                this.autoform.deleteItemByUuid(this.current_uuid, (uuid) => {
                    this.setPanelVisible(false);
                    this.current_uuid = null;
                    this.current_item_title = "";
                    this.updateList(this.list.find('#search').val());
                })
            });

        
    }

    applyModifications() {
        this.window.find('#buttonModify').prop('disabled',true);
        this.window.find('#buttonModify').prop('disabled',true);

        this.autoform.postForm((uuid) => {
            this.loadUuid(uuid);
            this.updateList(this.list.find('#search').val());
        })
    }

    enableModifications() {
        this.window.find('#buttonModify').prop('disabled',false).css('display','none');
        this.window.find('#buttonApply').prop('disabled',false).css('display','unset');
        this.autoform.enableModifications(true);
        this.modification_mode = true;
    }

    loadUuid(uuid) {
        this.window.find('#buttonModify').prop('disabled',true);
        this.window.find('#buttonModify').prop('disabled',true);

        this.autoform.loadItemByIndexValue(uuid, (title, uuid, data) => {
            this.setPanelVisible(true);
            this.autoform.enableModifications(false);
            this.window.find('#formTitle').html(title);

            this.current_uuid = uuid;
            this.current_item_title = title;
            
            this.window.find('#buttonModify').prop('disabled',false).css('display','unset');
            this.window.find('#buttonApply').prop('disabled',false).css('display','none');
            this.modification_mode = false;
        });
        
    }

    setPanelVisible(value) {
        if (value) {
            this.window.find('#formPanel').removeClass('invisible');
        } else {
            this.window.find('#formPanel').addClass('invisible');
        }
    }

    updateList(search) {
        this.window.widgets['obj_list'].updateList(search);
    }
}

AppletAutoForm.Initialize();