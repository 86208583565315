import Applet from './applet.js';

export default class AppletSysMon extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "server";
    static appname="sysmon";

    static content = `


    <div id="WindowSysMon">
        <!--<div >
            <h1 class="h2">Informations système</h1>
        </div>-->
        <div style="overflow:auto;">
            <h3>Processeur</h3>
            <div class="cpu-container" style="width:90%;">
                <div id="overview" class="cpu-placeholder" style="height:100px"></div>
                <div id="cpu-legend"></div>
            </div>
            <h3>Mémoire</h3>
            <div class="mem-container" style="width:90%;">
                <div id="memory_overview" class="mem-placeholder" style="height:100px"></div>
                <div id="mem-legend"></div>
            </div>
            <h3>Disque</h3>
            <div class="disk-container" style="width:90%;">
                <div id="disk_overview" class="disk-placeholder" style="height:100px"></div>
                <div id="disk-legend"></div>
            </div>
        </div>
	</div>
    `;

    loadPath() {
        
    }

    static FromPath(value) {
		var applet = super.New("Moniteur système");
		applet.loadPath(value);
		return applet;
	}

    constructor() {
        if (!super("Moniteur système"))
            return;
            
        this.window
            .setSize(450, 550)
            .setMinSize(450, 500)
            .setPosition(135,75)
            .setClosable(true)
            .setPinnable()
            .setResizable();

        setTimeout(() => {this.setupPlot();},500);

        this.data = {
            'cpu_percent': newPlotData('CPU'),
            'swap_memory': newPlotData('Swap'),
            "virtual_memory": newPlotData('VMem'),
            "disk_io_avg_write": newPlotData('Disk read'),
            'disk_io_avg_read': newPlotData('Disk write')
        };

            // Set up the control widget

        this.updateInterval = 500;

        this.params_cpu = $.extend(true, {}, plotParameters);
        this.params_cpu.grid.markings=[];
        this.params_cpu.colors = ["#d18b2c", "#dba255"]
        this.params_mem = $.extend(true, {}, plotParameters);
        this.params_mem.colors = ["#4fa7c9", "#9a65c9"];
        this.params_mem.grid.markings=[];
        this.params_disk = $.extend(true, {}, plotParameters);
        this.params_disk.colors = ["#c9c465", "#65c966"];
        this.params_disk.grid.markings=[];

        this.params_mem.yaxis.ticks = [[0, ""], [50, "50%"], [100, "100%"]];

        this.params_disk.yaxis.tickFormatter = function (val, axis) {
            if (val > 1000000)
                return (val / 1000000).toFixed(axis.tickDecimals) + " Mo/s";
            else if (val > 1000)
                return (val / 1000).toFixed(axis.tickDecimals) + " ko/s";
            else
                return val.toFixed(axis.tickDecimals) + " o/s";
        }
        this.params_disk.yaxis.autoScale = "loose";
        this.params_disk.yaxis.ticks = null;
    }

    setupPlot() {
        this.getData();
        this.cpu_plot = $.plot("#overview", [this.retrieveData('cpu_percent')], this.params_cpu);
        this.mem_plot = $.plot("#memory_overview", [this.retrieveData('virtual_memory'), this.retrieveData('swap_memory')], this.params_mem);
        this.disk_plot = $.plot("#disk_overview", [this.retrieveData('disk_io_avg_read'), this.retrieveData('disk_io_avg_write')], this.params_disk);

        this.updatePlot()

    }

    getData() {
        var sm = this;
        // Do a random walk
        $.getJSON('/admin/sysinfo', function (cpu_percent) {

        var pulled_data = {
          'cpu_percent': cpu_percent["cpu_percent"], 
          'swap_memory': cpu_percent["swap_memory"][3],
          'virtual_memory': cpu_percent["virtual_memory"][2],
          'disk_io_avg_write': cpu_percent["disk_io_avg"][0],
          'disk_io_avg_read':cpu_percent["disk_io_avg"][1]
        }

        for (const [key,value] of Object.entries(sm.data)) {
          if (value.data.length >= 100) {
            value.data = value.data.slice(1);
          }
          value.data.push(pulled_data[key]);
        }
      });
    }

    retrieveData(plot_name) {
        var res = [];
        for (var i = 0; i < this.data[plot_name].data.length; ++i) {
            res.push([i, this.data[plot_name].data[i]])
        }
        return res;
    }


    updatePlot() {
        this.getData();
        this.cpu_plot = $.plot("#overview", [this.retrieveData('cpu_percent')], this.params_cpu);
        this.mem_plot = $.plot("#memory_overview", [this.retrieveData('virtual_memory'), this.retrieveData('swap_memory')], this.params_mem);
        this.disk_plot = $.plot("#disk_overview", [this.retrieveData('disk_io_avg_read'), this.retrieveData('disk_io_avg_write')], this.params_disk);

        // Since the axes don't change, we don't need to call plot.setupGrid()
  
        this.cpu_plot.draw();
        this.mem_plot.draw();
        this.disk_plot.draw();
        this.disk_plot.setupGrid(true);
        setTimeout(() => {this.updatePlot();}, this.updateInterval);
    }


}

AppletSysMon.Initialize();