import Applet from './applet.js';
import Menus from './appletMenus.js';
import WSConnection from './websocket.js';
import SettingsManager from './settingsManager.js';

export default class AppletTicketMini extends Applet {
    static unique = false;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "ticket";
    static appname="ticketMini";

    static content = `
    <div>
		
        <div class="flex row" style="margin-left:5px; margin-right:5px">
            <h3 class="flex-grow title-handle" style="user-select: none;">Nouvel évènement</h3>
            <i class="icofont-close grey lock-hide" style="cursor:pointer; font-size:x-large;border-bottom:1px solid #aaa; margin-bottom:.5rem;" id="closeButton"></i>
        </div>
        <div class="flex column">
            <select id="inputReason" class="reason">
                <option>Compte bloqué</option>
                <option>Problème sur action de formation</option>
                <option>Problème lié à la facturation</option>
                <option>Autre problème mineur</option>
                <option value="custom">Personnalisé...</option>
            </select>
            <input id="inputReasonCustom" placeholder="Titre du ticket..." style="display:none;">
            <textarea id="inputCommentary" class="commentary" rows="3" placeholder="Commentaire..."></textarea>
            <div id="tagBox" class="flex-grow">
            </div>
        </div>
        <div class="flex row" style="padding: 15px; padding-bottom: 0px;">
            <div class="d-flex flex-wrap">
                <div style="width:300px;white-space:nowrap;overflow:hidden;" class=""><label style="width:110px;"><label style="width:110px;">Demande de : </label><widget class="flex-grow" style="margin-right:24px;" type="mixed:autoform/vcard" options="nolabel" field="contact"></widget></div>
                <div style="width:300px;white-space:nowrap;overflow:hidden;" class=""><label style="width:110px;"><label style="width:110px;">Temps estimé : </label><select id="inputEstimatedTime" class="seamless">
                    <option>5 minutes</option>
                    <option>10 minutes</option>
                    <option>15 minutes</option>
                    <option>30 minutes</option>
                    <option>1 heure</option>
                    <option>non défini...</option>
                </select></div>
                <div style="width:300px;white-space:nowrap;overflow:hidden;" class=""><label style="width:110px;"><label style="width:110px;">Origine : </label><select id="inputOrigin" class="seamless">
                    <option value="mail">Mail</option>
                    <option value="phone">Téléphone</option>
                    <option value="client">Suivi client</option>
                </select></div>
                <div style="width:300px;white-space:nowrap;overflow:hidden;" class=""><label style="width:110px;"><label  style="width:110px;">Client:</label> <input id="inputInstance" class="seamless"></div>
                <div style="width:300px;white-space:nowrap;overflow:hidden;" class=""><label style="width:110px;"><label  style="width:110px;">Logiciel:</label> <input id="inputSoftware" class="seamless"></div>
                <div style="width:300px;white-space:nowrap;overflow:hidden;" class=""><label style="width:110px;"><label style="width:110px;">Fermer le ticket:</label> <input type="checkbox" id="inputClosed" class="seamless" checked></div>
                <div style="width:300px;white-space:nowrap;overflow:hidden;" class=""><label style="width:110px;"><label style="width:110px;">Ouvert le:</label> <input id="inputCreated" class="seamless inline"></input></div>
            </div>
            <button id="btnCreate">Créer</button>
        </div>
        <div class="new-mail-panel flex column" style="display:none;">
                <widget type="search:mail_account" options="" label="De" field="w_from"></widget>
                <widget type="string" options="" label="A" field="w_to"></widget>
                <widget type="string" options="" label="Cc" field="w_cc"></widget>
                <widget type="string" options="" label="Sujet" field="w_subject"></widget>
                <widget type="text" options="html h:250" field="w_content"></widget>
                <button class="btn inline" id="btnSignature">Signature</button>
        </div>
		
	</div>
    `;

    loadPath() {
        
    }

    static FromPath(value) {
		var applet = super.New("Evènement");
		applet.loadPath(value);
        console.log(mono_args);
		return applet;
	}

    fetch_operators() {
        
		const req = $.get('/operator/list', (res) => {
            var operators = {}
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				operators = response.data;
                Object.keys(operators).forEach((op) => {
                    var hue = 0;
                    if (operators[op].color != "") {
                        var rgb = operators[op].color.split(',');
                        operators[op].rgb = rgb;
                        for (var i=0; i<3;i++)
                            rgb[i] = parseInt(rgb[i]);
                        const min = Math.min(rgb[0], rgb[1], rgb[2]);
                        const max = Math.max(rgb[0], rgb[1], rgb[2]);
        
                        if (min == max) {
                            hue = 0;
                        } else {
                            if (max == parseInt(rgb[0])) {
                                hue = (rgb[1] - rgb[2]) / (max - min);
                            } else if (max == rgb[1]) {
                                hue = 2 + (rgb[2] - rgb[0]) / (max - min);
                            } else {
                                hue = 4 + (rgb[0] - rgb[1]) / (max - min);
                            }
        
                            hue = Math.round(hue*60);
                        }
                    }
                    operators[op].hue = hue;
                });
                this.operators = operators;
				this.update_tags(this.assignments);
			}
		});
        return req;
    }

    update_tags() {
        Object.keys(this.operators).forEach((op) => {
            var disabled="disabled";
            if (op == Applet.user_uuid)
                disabled="";
            this.window.find('#tagBox').append(`<div class="tag ${disabled}" uuid="${op}"></div>`);
        });

        this.window.find('.tag').click((evt) => {
            $(evt.currentTarget).toggleClass("disabled");
        }).each((index,tag) => {
			const uuid = $(tag).attr('uuid');
			if (uuid == "" || uuid == undefined)
				return

			const operator = this.operators[uuid];
			if (operator  == undefined) {
				$(tag).html(`undefined:${uuid}`);
				return;
			}
			if (operator.nickname == "")
				$(tag).html(operator.username);
			else
			$(tag).html(operator.nickname);

			var hue = operator.hue;

			$(tag).css('filter',`hue-rotate(${hue}deg)`);
		});
    }

    send_data() {
        var data = {
            'title': this.window.find('#inputReason').val(),
            'content': this.window.find('#inputCommentary').val(),
            'assignments' : [],
            'contact' : this.window.w('contact').val(),
            'instance' : this.window.find('#inputInstance').attr('value'),
            'software' : this.window.find('#inputSoftware').attr('value'),
            'estimatedTime' : this.window.find('#inputEstimatedTime').val(),
            'origin' : this.window.find('#inputOrigin').val(),
            'created' : this.window.find('#inputCreated').getKendoDatePicker().value().getTime() / 1000,
            'closed' : this.window.find('#inputClosed').prop('checked'),
        }

        if (data.title == "custom")
            data.title = this.window.find('#inputReasonCustom').val()

        if(this.from_mail != null) {
            data['from_mail'] = this.from_mail;
            data['mail_subject'] = this.window.w("w_subject").val();
            data['mail_from'] = this.window.w("w_from").val();
            data['mail_to'] = this.window.w("w_to").val();
            data['mail_cc'] = this.window.w("w_cc").val();
            data['mail_content'] = this.window.w("w_content").val();
        }

        this.window.find('.tag').each((index, dom) => {
            if (!$(dom).hasClass('disabled'))
                data.assignments.push($(dom).attr('uuid'));
        });

        this.window.find('#btnCreate').prop('disabled',true);
        $.post('/ticket/create_mini', {data: JSON.stringify(data)}, (res) => {
            const response = res;
            if (response.status != "OK") {
                this.window.showAlert(response.message);
            } else {
                this.window.close();
            }
        });

        console.log(data);
    }

    static New(from_mail=null) {
        var applet = super.New('Ticket rapide');
        applet.from_mail = from_mail;

        if (from_mail != null) {
            applet.window.setSize(700,970)
            applet.window.find(".new-mail-panel").css("display","flex");

            $.get('/mail', {action:"default_reply"}, (res) => {
                const response = res;

                if (response.status != "OK") {
                    return;
                } else {
                    applet.window.w('w_from').val(response.data);
                    //applet.fetchFieldTitle(applet.window.find("#from"), 'mail_account');
                }
            });

            $.get(`/autoform/mail_archive/load`, {uuid:from_mail}, (response) => {
                if (response.status != "OK") {
                    applet.window.showAlert(`Error while retrieving form data for ${mail_uuid} : ${response.message}.`);
                    return;
                } else {
                    const mail = response.data.data;
                    applet.orig_mail_uuid = from_mail;

                    applet.window.w('w_to').val(mail.hdr_from.join(','));
                    applet.window.w('w_cc').val(mail.hdr_cc.join(','));
                    applet.window.w('w_subject').val("Re: " + mail.subject);

                    const comment_regex = /<!--.*-->/gs;
                    const date_mail = new Date(mail.reception_date*1000);
                    var mail_content = mail.content.replaceAll('vid:', `${SettingsManager.cdn}i/orig/`);
                    mail_content = mail_content.replace(comment_regex, "");
                    applet.window.w('w_content').val(`<p>&nbsp;</p><hr>Mail original reçu le ${date_mail.toLocaleDateString()} : <blockquote>${mail_content}</bloquote>`);

                    applet.window.find("#inputReason").val("custom").change();
                    applet.window.find("#inputReasonCustom").val("[TR] " + mail.subject);
                }
    
            });
        }

        return applet;
    }

    constructor() {
        if (!super("Evènement"))
            return;
            
        this.window
            .setSize(700, 520)
            .setMinSize(180, 250)
            .setPosition(135,75)
            .setClosable(true)
            .setBorderless(true)
            .setPinnable()
            .setHandle(".title-handle")
            .setResizable();

        this.operators = {};
        this.fetch_operators();

        this.window.find('#closeButton').click(() => {this.window.close();})

        this.window.find('#inputReason').change((evt) => {
            if ($(evt.currentTarget).val() == "custom") {
                this.window.find('#inputReason').css("display","none");
                this.window.find('#inputReasonCustom').css("display","unset");
            }
        })

        this.window.find("#inputCreated").kendoDatePicker({culture:"fr-FR"});
        this.window.find("#inputCreated").getKendoDatePicker().value(new Date());
        this.window.find('#inputInstance').focus((evt) => {
            Menus.showSearchPopup(this.window, this.window.find('#inputInstance'), 'project');
        });

        this.window.find('#inputSoftware').focus((evt) => {
            Menus.showSearchPopup(this.window, this.window.find('#inputSoftware'), 'software');
        });

        this.window.find('#btnCreate').click(() => { this.send_data(); });

        this.window.find('#btnSignature').click((evt) => {
            Menus.showSearchPopup(this.window, $(evt.currentTarget), "signature", "callback", (value,label) => {
                $.get('/autoform/signature/load', {uuid:value}, (response) => {
                    if (response.status != "OK") {
                        this.window.showAlert(`Error while retrieving form data ${this.name} : ${response.message}.`);
                        return;
                    } else {
                        const viewFragment = this.window.w("w_content").contenteditor.data.processor.toView( response.data.data.content );
                        const modelFragment = this.window.w("w_content").contenteditor.data.toModel( viewFragment );

                        this.window.w("w_content").contenteditor.model.insertContent( modelFragment );
                    }
                });
            });
        })
    }
}

AppletTicketMini.Initialize();