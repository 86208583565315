import Applet from './applet.js';
import AppletViewer from './appletViewer.js';
import Foroyar from './foroyar.js';
import SettingsManager from './settingsManager.js';

export default class AppletMediaLibrary extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "library";
    static appname="medialibrary";

    static content = `
    <div id="WindowMediaLibrary">
        <div class="flex row nowrap m-12 h-100">
            <div style="width:48px;min-width:48px;">
                <i style="display:block;font-size:xx-large;" id="btnContact" class="icofont-people icon-button"></i>
                <i style="display:block;font-size:xx-large;" id="btnCategory" class="icofont-tags icon-button"></i>
                <i style="display:block;font-size:xx-large;" id="btnUpload" class="icofont-upload icon-button"></i>
            </div>
            <div class="flex column h-100" style="width:280px;min-width:280px;">
                <input class="w-90 m-12" id="search" placeholder="Recherche...">
                <h4 id="listTitle">Par opérateur</h4>
                <ul id="itemList" class="item-list flex-grow" style="overflow-y:auto;">

                </ul>
                <button id='btnCancel' style="display:none;margin-right:58px; margin-bottom:24px;" class="">Annuler</button>
            </div>

            <div class="flex-grow" style="padding-left:12px; overflow-y:clip; overflow-x:hidden;">
                <div id="uploadPanel" class="flex-column flex-grow h-100" style="display:none;">
                    <div id="fileDeposit" class="file-deposit flex-grow">
                        <h1 style="position:relative; top:40%;">Déplacez votre document</h1>
                    </div>
                    <input id="inputFileName" placeholder="Nom du document..."></input>
                    <select id="selectCategory">
                        <option value=""></option>
                    </select>
                    <div class="flex-row" style="margin:12px;text-align:center;">
                        <button id="btnSendFile">Envoyer</button>
                    </div>
                </div>

                <div class="icon-box" style="justify-content: flex-start;">
                    <i id="btnLayout" class="icofont-listing-box icon-button"></i>
                </div>
                <div class="flex row hide-scrollbar small" id="thumbnailPanel" style="overflow-y:auto; padding-left:12px; max-height:100%;"></div>
            </div>
        </div>
		
	</div>
    `;

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
      
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
      
        const i = Math.floor(Math.log(bytes) / Math.log(k));
      
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }

    update_thumbnail_list() {
        if (this.update_request != null && this.update_request.state == "pending") {
            this.update_request.abort();
            console.log("aborted");
        }
        

        this.update_request = $.get(`/media/list`, {owner:this.selected_operator, amount:150}, (res) => {
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else {
                this.window.find('#thumbnailPanel').empty()
                response.data.forEach((c) => {
                    var css_class="";
                    if (c.uuid == this.current_uuid) {
                        css_class = "selected";
                    }

                    const size = this.formatBytes(c.total_size);

                    var img = `${SettingsManager.cdn}i/small/${c.uuid}`;

                    this.window.find('#thumbnailPanel').append(
                        `<div class="thumbnail-box ${css_class}" uuid="${c.uuid}">
                            <div class="img-box" loading="lazy" style="background-image: url(${img});">
                            </div>
                            <p>${c.title}</p>
                            <p class="grey">${size}</p>
                        </div>`)

                });
                this.window.find('#thumbnailPanel').find('.thumbnail-box').click((evt) => {
                    if (this.img_select_callback != null) {
                        this.img_select_callback(
                            $(evt.currentTarget).attr('uuid'),
                            $(evt.currentTarget).find("p").html()
                        );
                    } else {
                        AppletViewer.New($(evt.currentTarget).attr('uuid'));
                    }
                });

                this.window.find('.item').click((evt) => {
                    //this.enable_modification(false);
                    this.window.find('.item').removeClass('selected');
                    const uuid = $(evt.currentTarget).attr('uuid');
                    $(evt.currentTarget).addClass('selected')
                    this.current_uuid = uuid;
                    //this.select_contact(uuid);
                });
            }
        });
    }

    update_category_list() {
        if (this.mode == "operator") {
            this.window.find('#itemList').empty();
            $.get('/search?type=operators', (res) => {
                const response = res;
                if (response.status != 'OK') {
                    this.window.showAlert(response.message);
                } else {
                    response.data.forEach((element) => {
                        this.window.find('#itemList').append(
                            `<li class="item" uuid="${element[1]}">${element[0]}</li>`
                        );
                    });
                    this.window.find('#itemList').find('li').click((evt) => {
                        this.selected_operator = $(evt.currentTarget).attr('uuid');
                        this.update_thumbnail_list();
                    });
                }
            });
        }
    }

    select_media(uuid) {
        $.get(`/media/get`, {uuid: uuid}, (res) => {
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else if (this.mode == 'contact') {
                
            } else {
                
            }
        });
    }

    loadPath() {
        
    }

    static FromPath(value) {
		var applet = super.New("Bibliothèque de Medias");
		applet.loadPath(value);
		return applet;
	}

    showPanel(panel='thumbnails') {
        switch(panel) {
            case 'thumbnails':
                this.window.find('#uploadPanel').css('display', 'none');
                this.window.find('.icon-box').css('display','flex');
                this.window.find('#thumbnailPanel').css('display','flex');
                break;

            case 'upload':
                this.window.find('#uploadPanel').css('display', 'flex');
                this.window.find('.icon-box').css('display','none');
                this.window.find('#thumbnailPanel').css('display','none');
                break;
            default:
                break;
        }
    }

    emptyUploadPanel() {
        this.window.find('#fileDeposit').empty().append('<h1 style="position:relative; top:40%;">Déplacez votre image</h1>');
        this.window.find('#inputFileName').val("");
        this.window.find('#btnSendFile').prop('disabled',true);
    }

    constructor(popup=false, callback=null) {
        if (!super("Bibliothèque de Medias"))
            return;

        this.mode = "operator";
        this.file_ready = false;
        this.current_file = null;
            
        this.window
            .setSize(950, 580)
            .setMinSize(815, 430)
            .setResizable()
            .setClosable()
            .setPinnable()

        this.img_select_callback = null;

        if (popup) {
            this.window.setBorderless().center().setLayer(3);
            this.window.find('#btnCancel').css('display','block').click((evt) => {
                this.window.close();
                callback();
            });
            this.img_select_callback = (uuid, title) => {
                this.window.close();
                callback(uuid, title);
            }
            this.window.onClose = () => { console.log("WE'RE CLOSING"); callback(); }
        }



        this.update_request = null;
        this.selected_operator = "";

        this.window.find('#btnContact').click((evt) => { 
            this.showPanel('thumbnails');
            $("#listTitle").html('Par opérateur');
            this.mode = "operator";
            $(this.currentTarget).addClass('selected');
            this.window.find('#btnCategory').removeClass('selected');
            this.update_category_list();
        });

        this.window.find('#fileDeposit').on('drop', (evt) => {
            evt.preventDefault();
            $(evt.currentTarget).removeClass('hovered');
            this.window.find('#btnSendFile').prop('disabled',false);
            if (evt.originalEvent.dataTransfer.items) {
                for (var i = 0; i < evt.originalEvent.dataTransfer.items.length; i++) {
                  if (evt.originalEvent.dataTransfer.items[i].kind === 'file') {
                    var file = evt.originalEvent.dataTransfer.items[i].getAsFile();
                    
                    $(evt.currentTarget).empty();
                    if(file.type.split("/")[0] == "image") {
                        const url = webkitURL.createObjectURL(file);
                        $(evt.currentTarget).append(`<img src="${url}" style="max-height:85%; max-width:85%;box-shadow:3px 3px 2px rgba(0,0,0,.25);" /><p class="grey">${file.name}</p>`);
                    } else {
                        const mime_img = "/assets/img/mime/" + file.type.replace("/","-") + ".png";
                        $(evt.currentTarget).append(`<img src="${mime_img}" /><p class="grey">${file.name}</p>`)
                    }
                    this.window.find('#inputFileName').val(file.name);
                    this.current_file = file;
                    this.file_ready = true;
                  }
                }
              }
        }).on('dragover', (evt) => {
            evt.preventDefault();
            $(evt.currentTarget).addClass('hovered');
        }).on('dragleave', (evt) => {
            evt.preventDefault();
            $(evt.currentTarget).removeClass('hovered');
        });

        this.window.find('#btnUpload').click((evt) => {
            this.showPanel('upload');
        });

        this.window.find('#btnSendFile').click((evt) => {
            if (this.file_ready) {
                this.file_ready = false;
                $(evt.currentTarget).prop('disabled',true);
                const modal = this.window.showModal('Envoi en cours...', 'Envoi', 'sand-clock', false, true);

                var formData = new FormData();
                formData.append('file', this.current_file);
                formData.append('filename', this.window.find('#inputFileName').val());
                //formData.append('public', false);

                Foroyar.post('/upload', formData, (response) => {
                    modal.close();
                    if (this.img_select_callback != null) {
                        this.img_select_callback(response);
                    }
                    this.emptyUploadPanel();
                }, (error,message) => {
                    modal.close();
                    this.window.showAlert('Une erreur est survenue : ' + message);
                }, null, true);

                /*$.ajax({
                    url: '/upload',
                    type: 'POST',
                    data: formData,
                    processData: false,
                    contentType: false,
                    success: (response) => {
                        if (response.status != 'OK') {
                            modal.close();
                            this.window.showAlert('Une erreur est survenue : ' + response.error.message);
                        } else {
                            modal.close();
                            if (this.img_select_callback != null) {
                                this.img_select_callback(response.data);
                            }
                        }
                        this.emptyUploadPanel();
                    }
                });*/
                
            }
        })

        this.window.find('#btnLayout').click(() => {
            if (!this.window.find('#thumbnailPanel').hasClass('small'))
                this.window.find('#thumbnailPanel').addClass('small');
            else
                this.window.find('#thumbnailPanel').removeClass('small');
        });

        this.window.find('#btnCategory').click((evt) => { 
            this.showPanel('thumbnails');
            $("#listTitle").html('Par catégorie');
            this.mode = "category";
            $(this.currentTarget).addClass('selected');
            this.window.find('#btnContact').removeClass('selected');
            this.update_category_list();
        });

        this.update_category_list();
        this.update_thumbnail_list();
        this.emptyUploadPanel();

    }
}

AppletMediaLibrary.Initialize();