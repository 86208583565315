import Applet from './applet.js';
import SettingsManager from './settingsManager.js';

export default class AppletVCard extends Applet {
    static unique = false;
    static instance = [];
    static shownInTaskBar = false;
    static taskbarIcon = "ui-v-card";
    static appname="vcard";

    static content = `
    <div id="WindowContact">
        <div class="flex row" style="margin-left:5px; margin-right:5px">
		    <h4 class="flex-grow" style="user-select:none;" id="fullname">List des contacts</h4>
            <i class="icofont-close grey lock-hide" style="font-size:x-large;" id="closeButton"></i>
        </div>
        <div class="flex row m-12">
            <img id="picture" src="/assets/img/contact.png" style="border-radius: 4px; box-shadow: 1px 1px 2px rgba(0,0,0,.5);"width=96 height=96>
            <div>
                <p class="condensed"><i class="icofont-tag grey"> </i><span id="nickname"></span></p>
                <p class="condensed"><i class="icofont-home grey"> </i><span id="company"></span></p>
                <p class="condensed"><i class="icofont-email grey"> </i><span id="email"></span></p>
                <p class="condensed"><i class="icofont-smart-phone grey"> </i><span id="phone"></span></p>
                
            </div>
        </div>
		
	</div>
    `;

    static New(contact_uuid=null) {
		var applet = super.New("Carte de visite");

        applet.load_card(contact_uuid);
        return applet;
	}

    loadPath(value) {
        this.load_card(value);
    }

    static FromPath(value) {
		var applet = super.New("Carte de visite");
		applet.loadPath(value);
		return applet;
	}

    load_card(contact_uuid) {
        var src_url = "/contact/get";
        if (contact_uuid == null )
            src_url = "/contact/operator";

        $.get(src_url, {uuid: contact_uuid}, (res) => {
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else {
                this.setPath(contact_uuid);
                if (contact_uuid == null)
                    this.setPath(response.data.uuid);
                this.window.find("#email").html(response.data.email);
                this.window.find("#nickname").html(response.data.nickname);
                this.window.find("#phone").html(response.data.phone);
                this.window.find("#fullname").html(response.data.fullname);
                this.window.find("#company").attr('value', response.data.company);
                this.fetchFieldTitle(this.window.find("#company"), 'organization');
                if (response.data.picture != "" && response.data.picture != null) {
                    this.window.find("#picture").attr('src', `${SettingsManager.cdn}i/small/${response.data.picture}`);
                }
            }
        });
    }

    constructor() {
        super("Carte de visite");
            
        this.window
            .setSize(380, 210)
            .setMinSize(210,210)
            .setBorderless()
            .setHandle('#fullname')
            .setLayer(3)
            .setOnTop()

        this.window.find("#closeButton").click(() => {this.window.close();})

    }
}

AppletVCard.Initialize();