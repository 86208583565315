import Applet from './applet.js';
import AppletChatConversation from './appletChatConversation.js';
import WSConnection from './websocket.js';

export default class AppletChat extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "people";
    static appname="chat";

    static content = `
    <div id="WindowContactList">
		<h3>Liste des contacts</h3>
		<p>Les personnes suivantes sont actuellement dans votre carnet d'adresse.</p>
		<ul class="contact-list">
        </ul>
        <!--<p>Personnes actuellement hors-ligne.</p>
        <ul class="contact-list">
            <li>Chloé Rousseau</li>
        </ul>-->
		
	</div>
    `;

    loadPath() {
        
    }

    static FromPath(value) {
		var applet = super.New("Contacts");
		applet.loadPath(value);
		return applet;
	}

    updateContactList() {
        $.get('/conversation', {}, (response) => {
            if (response.status != 'OK') {
                this.window.showAlert('Error occured : ' + response.error_message);
            } else {
                this.window.find('.contact-list').empty();
                response.data.forEach((c) => {
                    if (c[1] != Applet.user_uuid) {
                        this.window.find('.contact-list').append(`<li class="conversation-contact ${c[2] ? "online" : ""}" uuid="${c[1]}">${c[0]}</li>`)
                    }
                });
                this.window.find('.conversation-contact').click((evt) => {
                    $.post('/conversation', {"action":"open", "contact": $(evt.currentTarget).attr('uuid')}, (res) => {
                        const response = res;
                        const app = AppletChatConversation.New(response.data, this);
                        app.window.setTitle($(evt.currentTarget).html());
                    })
                })
            }
        });
    }

    constructor() {
        if (!super("Contacts"))
            return;
            
        this.window
            .setSize(300, 750)
            .setMinSize(180, 250)
            .setPosition(135,75)
            .setClosable(true)
            .setPinnable()
            .setExpandable(true)
            .setResizable();

        this.updateContactList();

        WSConnection.subscribe('operators', (data) => {
            console.log(data);
            if (data.status == "online")
                this.window.find(`[uuid=${data.user}]`).addClass('online');
            else
                this.window.find(`[uuid=${data.user}]`).removeClass('online');
        })

    }
}

AppletChat.Initialize();