import Applet from './applet.js';
import AppletLauncher from './appletLauncher.js';
import AppletPostIt from './appletPostIt.js';
import AppletWelcome from './appletWelcome.js';
import AppletSidePanel from './appletSidePanel.js';
import WSConnection from './websocket.js'
import Notification from './notifications.js'

import SettingsManager from './settingsManager.js';

export default class AppletLogin extends Applet {
    static unique = true;
    static instance = [];

    static content = `
    <div id="WindowLogin">
		<div class="w-100 h-100" style="backdrop-filter:blur(15px);">
            <div class="welcome-icon" style="border-bottom:0px; text-shadow: 1px 1px 1px rgba(0,0,0,.25); color:#ccc">
                <i class="icofont-sail-boat-alt-2"></i>
                <h1 style="text-align:center;">Føroyar</h1>
            </div>
            <div class="" style="width:450px; height:320px; margin:auto; padding:15px; color:#ccc;">
                <p class="condensed">
                    <i class="icofont-home grey"> </i>
                    <label style="width:110px;">Utilisateur</label>
                    <input style="color:#8fa3b3; text-shadow: 0px 0px 2px rgba(0,0,0,.25);" id="username"></input>
                </p>
                <p class="condensed">
                    <i class="icofont-key grey"> </i>
                    <label style="width:110px;">Mot de passe</label>
                    <input style="color:#8fa3b3; text-shadow: 0px 0px 2px rgba(0,0,0,.25);" type="password" id="password"></input>
                </p>
                <p class="condensed small" style="text-align:center; color:red;" id="errorMessage">&nbsp;</p>
                <div style="text-align:center;margin-top:5rem;">
                    <button id="submit" class="inline">Connexion</button>
                </div>
                <a style="text-align:center; display:block; margin-top:20px;" href="/login?action=password_reset" target="_blank">Mot de passe oublié</a>

            </div>

		</div>
	</div>
    `;

    static New(path=null, new_window=false, application=null) {
        var applet = super.New("Login", new_window, application);
        if (path != null) {
            applet.mono_path = path;
        } else {
            applet.mono_path =  null;
        }
        return applet;
    }

    static InitializeMonoApp(mono_path) {
        SettingsManager.Initialize();
        $('main').addClass('no-effects');
    
        var app = Applet.LoadFromPath(mono_path);
        app.window.setMonoApp();
        app.window.oncloseimmediate = () => {window.close();};
    
        SettingsManager.cdn = cdn_host;
        WSConnection.url = ws_host;
        WSConnection.session_id = ws_session_id;
    }

    constructor() {
        if (!super("Login"))
            return;
            
        this.window
            .setSize(300, 750)
            .setMinSize(180, 250)
            .setPosition(135,75)
            .setClosable(true)
            .setExpandable()
            .setLayer(3)
            .setBorderless()
            .center()
            .setResizable();

        this.window.window.addClass('login');

        //AppletTest.startGL();

        this.window.find('input').keyup((evt) => {
            if (evt.keyCode == 13) {
                this.window.find('#submit').click();
            }
        });



        this.window.find('#submit').click((evt) => {
            $.post('/api/login', {action:'login', username:this.window.find('#username').val(), password:this.window.find('#password').val()}, (res) => {
                if (res.status == "OK") {
                    Applet.user_uuid = res.message.user_uuid;
                    this.window.close();
                    WSConnection.on('statuschange', (status) => {
                        if (status == 'ready')
                            Notification.ConnectWS(res.message.user_uuid);
                    })
                    WSConnection.Initialize(res.message.session_id);

                    if (this.mono_path != null) {
                        AppletLogin.InitializeMonoApp(this.mono_path);
                    } else {

                        $('#navbar').css('opacity','100%');
                        setTimeout(() => {
                            new AppletWelcome();
                            new AppletLauncher();
                            new AppletSidePanel();
                            AppletPostIt.LoadPostIts();
                        }, 600);
                    }
                } else {
                    this.window.find("#errorMessage").html(res.message);
                }
            })
        })

    }
}