import Applet from './applet.js';
import Menus from './appletMenus.js';

export default class AppletLayout extends Applet {
    static unique = false;
    static instance = [];
    static shownInTaskBar = true;
    static appname="layout";
    static taskbarIcon = "layers";

    static columnLayout = `
    <div id="WindowLayout">
        <div class="hr-container h-100">
            <div class="wcontainer"></div>
            <div class="wcontainer"></div>
            <div class="wcontainer"></div>
        </div>
    </div>
    `;

    static titlebar_content = `
    <button style="margin-right:20px;" class="small inline" id="layoutMenu">
        <i style="color:#6ca8da;"class="icofont-caret-down"></i><span>Menu</span>
    </button>
    <button style="margin-right:20px;" class="small inline" id="toggleLock">
        <i style="color:#6ca8da;"class="icofont-lock"></i><span>Verrouiller</span>
    </button>`

    static content = `
    <div id="WindowLayout">
        <div class="hr-container h-100 kanban">
            <div class="h-100 m-12 overflow-hidden">
                <div class="w-100 h-100 hide-scrollbar card-list" style="overflow:auto;">
                    <div class="wcontainer"></div>
                    <!--<button class="inline add-container m-12" style="display:block;"><i class="icofont-plus"></i> Nouvelle carte</button>-->
                </div>
            </div>
            <div class="h-100 m-12 overflow-hidden">
                <div class="w-100 h-100 hide-scrollbar card-list" style="overflow:auto;">
                    <div class="wcontainer"></div>
                    <!--<button class="inline add-container m-12" style="display:block;"><i class="icofont-plus"></i> Nouvelle carte</button>-->
                </div>
            </div>
            <div class="h-100 m-12 overflow-hidden">
                <div class="w-100 h-100 hide-scrollbar card-list" style="overflow:auto;">
                    <div class="wcontainer"></div>
                    <!--<button class="inline add-container m-12" style="display:block;"><i class="icofont-plus"></i> Nouvelle carte</button>-->
                </div>
            </div>
        </div>
	</div>
    `;

    setupContainer(wcont) {
        wcont.on("dock", () => {            
            wcont.before(this.setupContainer($(`<div class="wcontainer"></div>`)))
                 .after(this.setupContainer($(`<div class="wcontainer"></div>`)));
        }).on('undock', () => {
            wcont.prev().remove();
            wcont.next().remove();
        });
        return wcont;
    }

    loadJSON(content) {
        this.empty();
        const layout = JSON.parse(content);
        var i = 0;
        var sizes = [];
        this.window.setTitle(layout.name);

        layout.columns.forEach((column) => {
            sizes.push(column.width);
            column.cards.forEach((app) => {
                const applet = Applet.LoadFromPath(app.path);
                this.append(i, applet.window);
                //applet.window.window.css('min-width', app.width).css('min-height', app.height);
                applet.window.setSize(parseInt(app.width.replace('px','')),parseInt(app.height.replace('px','')));
            });
            i+=1;
        });
        console.log(sizes.join('fr ') + "fr");
        this.window.find('.hr-container').attr('style', `display:grid; gap:0px; grid-template-columns: ${sizes.join('fr ') + "fr"};`);
        this.setLocked(layout.locked);
    }

    setLocked(value) {
        if (value) {
            this.window.find('.hr-container').addClass('locked');
            this.window.find('.wcontainer').addClass('locked');
        } else {
            this.window.find('.hr-container').removeClass('locked');
            this.window.find('.wcontainer').removeClass('locked');
        } 
    }

    exportJSON() {
        var data = [];
        this.window.find('.card-list').each((col_index, col_element) => {
            var col_data = [];
            $(col_element).find('.wcontainer').each((index, element) => {
                if (!($(element).is(':empty'))) {
                    const win = $($(element).find('.window')[0]);
                    col_data.push({
                        width:win.css('min-width'),
                        height:win.css('min-height'),
                        path:win.attr('path')
                    });
                } 
            });
            data.push({
                width:$(col_element).width(),
                cards:col_data
            });
        });

        const workspace = {
            name : "Tableau de bord",
            locked : this.window.find('.hr-container').hasClass('locked'),
            columns : data
        };

        return JSON.stringify(workspace);
    }

    empty() {
        this.window.subWindows.forEach((win) => {
            win.close(true);
        });
        this.window.find('.hr-container').attr('style', `display:grid; gap:0px; grid-template-columns: 1fr 1fr 1fr;`);
        this.setLocked(false);
    }

    append(column, window) {
        const col = $(this.window.find('.card-list')[column]);
        const containers = col.find('.wcontainer');
        const wcont = $(containers[containers.length-1]);
        window.dockWindow(wcont);
    }

    loadPath(value) {
        
    }

    static New(name) {
        var applet = super.New("Layout");
        return applet;
    }

    static FromPath(value) {
		var applet = super.New("Layout");
		applet.loadPath(value);
		return applet;
	}

    constructor() {
        if (!super("Espace de travail"))
            return;
            
        this.window
            .setSize(300, 750)
            .setMinSize(180, 250)
            .setPosition(135,75)
            .setPadding(0)
            .setExpandable()
            .setClosable(true)
            .setPinnable()
            .setResizable();

        this.window.find('#toggleLock').click((evt) => {
            this.window.find('.hr-container').toggleClass('locked');
            this.window.find('.wcontainer').toggleClass('locked');
        });

        this.window.find('.wcontainer').each((index, element) => {
            this.setupContainer($(element));
        });

        this.window.find('#layoutMenu').click((evt) => { 
            Menus.showMenu(this.window, $(evt.currentTarget), [
                ['Exporter en JSON','export'],
                ['Importer', 'import'],
                'separator',
                ['Vider','empty']
            ],
            (res) => {
                switch(res) {
                    case 'export':
                        this.window.showModal(this.exportJSON(), "Export JSON") 
                        break;
                    case 'import':
                        this.window.showInputBox("Contenu à importer", "Importer", (res) => {
                            this.loadJSON(res);
                        })
                        break;
                    case "empty":
                        this.empty();
                        break;
                    default:
                        break;
                }
            });
        });

    }
}

AppletLayout.Initialize();