import Applet from './applet.js';
import * as THREE from 'three';

import Stats from 'three/examples/jsm/libs/stats.module.js';

import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Water } from 'three/examples/jsm/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';



export default class AppletTest extends Applet {
    static unique = false;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "laboratory";
    static appname="test";

    static content = `
    <div id="WindowWelcome">
        <div class="flex flex-column h-100" style="overflow-y:scroll;">
            <div class="welcome-icon" style="display:flex; justify-content:center;">
                <i class="icofont-laboratory"></i>
                <h2 style="text-align:center;">Applet de test</h2>
            </div>
            <div class="flex row">
                <button id="btnRefreshForm">Refresh</button>
                <button id="btnStartGL">OpenGL</button>
            </div>
            <div class="form-test autoform">
				<widget type="mixed:autoform/vcard" label="Champ mixte" field="test_mixed"></widget>
                <widget type="string" options="multiple" label="Champ de test automatique" field="test_str_mul"></widget>
                <widget type="string" options="" label="Champ de test" field="test_str"></widget>
                <widget type="color" options="" field="test_col"></widget>
                <widget type="search:operators" options="multiple" label="Recherche" field="test_search"></widget>
                <widget type="image" options="" label="Image" field="test_img"></widget>
                <widget type="text" options="html" field="text_html"></widget>
            </div>
        </div>
	</div>
    `;

    loadPath(value) {

    }

    static FromPath(value) {
		var applet = super.New("Test Applet");
		applet.loadPath(value);
		return applet;
	}

    refreshForm() {
        this.window.widgets['test_str'].val('Valeur de test');
        this.window.widgets['test_str_mul'].val(['Valeur de test','pain',"oeufs","PQ"]);
        this.window.widgets['text_html'].val("Placeholder text");
		console.log(this.window.widgets['test_mixed'].val());
        console.log(this.window.widgets['test_str_mul'].val());
		this.window.widgets['test_mixed'].val([
			"text:ceci est un tes",
			"uuid:14fc43d178d645bd8d499f4a98dbdbd0",
			"uuid:b41a7bad36af49369dc0c75cb8647027"
		]);
    }

    static SwitchToDesktopImg() {
        $('.background').empty();
        $('.background').html(
            `<canvas id="glCanvas" width=1920 height=1080 style="width:100%; height:100%; background-color:aliceblue; background-image: url(/assets/img/vagar-island-faroe-islands-denmark.jpg); background-size:cover;"></canvas>`
        )
    }

    static startGL() {

			let container, stats;
			let camera, scene, renderer, composer;
			let controls, water, sun, mesh;

			let glitchPass;

			const params = {
				exposure: 1,
				bloomStrength: .35,
				bloomThreshold: 0,
				bloomRadius: 0.0
			};

			init();
			animate();

			function init() {

				//container = document.getElementById( 'container' );

				//

				renderer = new THREE.WebGLRenderer();
				renderer.setPixelRatio( window.devicePixelRatio );
				renderer.setSize( window.innerWidth, window.innerHeight );

                //renderer.toneMapping = THREE.ACESFilmicToneMapping;
				renderer.toneMapping = THREE.ReinhardToneMapping;
				renderer.toneMappingExposure = 1.0;

                $(".background").empty();
                $(".background").append(renderer.domElement);
				//

				scene = new THREE.Scene();

				camera = new THREE.PerspectiveCamera( 55, window.innerWidth / window.innerHeight, 1, 20000 );
				camera.position.set( 30, 30, 100 );

                

				const loader = new THREE.ObjectLoader();

                /*loader.load(
                    // resource URL
                    "/assets/scene.json",

                    // onLoad callback
                    // Here the loaded data is assumed to be an object
                    function ( obj ) {
                        scene = obj;
                        scene.add( water );
                        scene.add( sky );
                        updateSun();
                    },

                    // onProgress callback
                    function ( xhr ) {
                        console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
                    },

                    // onError callback
                    function ( err ) {
                        console.error( 'An error happened' );
                    }
                );*/


				sun = new THREE.Vector3();

				// Water

				const waterGeometry = new THREE.PlaneGeometry( 10000, 10000 );

				water = new Water(
					waterGeometry,
					{
						textureWidth: 512,
						textureHeight: 512,
						waterNormals: new THREE.TextureLoader().load( '/assets/img/waternormals.jpg', function ( texture ) {

							texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

						} ),
						sunDirection: new THREE.Vector3(),
						sunColor: 0xffffff,
						waterColor: 0x409fad,
						distortionScale: 3.7
						//fog: scene.fog !== undefined
					}
				);

				water.rotation.x = - Math.PI / 2;

				scene.add( water );

				// Skybox

				const sky = new Sky();
				sky.scale.setScalar( 10000 );
				scene.add( sky );

				const skyUniforms = sky.material.uniforms;

				skyUniforms[ 'turbidity' ].value = 10;
				skyUniforms[ 'rayleigh' ].value = 2;
				skyUniforms[ 'mieCoefficient' ].value = 0.005;
				skyUniforms[ 'mieDirectionalG' ].value = 0.8;

				const parameters = {
					elevation: 2,
					azimuth: 230
				};

				const pmremGenerator = new THREE.PMREMGenerator( renderer );

				function updateSun() {

					const phi = THREE.MathUtils.degToRad( 90 - parameters.elevation );
					const theta = THREE.MathUtils.degToRad( parameters.azimuth );

					sun.setFromSphericalCoords( 1, phi, theta );

					sky.material.uniforms[ 'sunPosition' ].value.copy( sun );
					water.material.uniforms[ 'sunDirection' ].value.copy( sun ).normalize();

					scene.environment = pmremGenerator.fromScene( sky ).texture;

				}

				updateSun();

				//

				const geometry = new THREE.BoxGeometry( 30, 30, 30 );
				const material = new THREE.MeshStandardMaterial( { roughness: 0 } );

				mesh = new THREE.Mesh( geometry, material );
				//scene.add( mesh );

				//

				controls = new OrbitControls( camera, renderer.domElement );
				controls.maxPolarAngle = Math.PI * 0.495;
				controls.target.set( 0, 10, 0 );
				controls.minDistance = 40.0;
				controls.maxDistance = 200.0;
				controls.update();

				//

				stats = new Stats();
				//container.appendChild( stats.dom );

				// GUI

				/*const gui = new GUI();

				const folderSky = gui.addFolder( 'Sky' );
				folderSky.add( parameters, 'elevation', 0, 90, 0.1 ).onChange( updateSun );
				folderSky.add( parameters, 'azimuth', - 180, 180, 0.1 ).onChange( updateSun );
				folderSky.open();

				const waterUniforms = water.material.uniforms;

				const folderWater = gui.addFolder( 'Water' );
				folderWater.add( waterUniforms.distortionScale, 'value', 0, 8, 0.1 ).name( 'distortionScale' );
				folderWater.add( waterUniforms.size, 'value', 0.1, 10, 0.1 ).name( 'size' );
				folderWater.open();*/

				//

				// postprocessing

				const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
				bloomPass.threshold = params.bloomThreshold;
				bloomPass.strength = params.bloomStrength;
				bloomPass.radius = params.bloomRadius;


				composer = new EffectComposer( renderer );
				composer.addPass( new RenderPass( scene, camera ) );

				composer.addPass( bloomPass );

				glitchPass = new GlitchPass();
				//composer.addPass( glitchPass );


				window.addEventListener( 'resize', onWindowResize );

			}

			function onWindowResize() {

				camera.aspect = window.innerWidth / window.innerHeight;
				camera.updateProjectionMatrix();

				renderer.setSize( window.innerWidth, window.innerHeight );
				composer.setSize( window.innerWidth, window.innerHeight );
			}

			function animate() {

				requestAnimationFrame( animate );
				render();
				//stats.update();

			}

			function render() {

				const time = performance.now() * 0.001;

				/*mesh.position.y = Math.sin( time ) * 20 + 5;
				mesh.rotation.x = time * 0.5;
				mesh.rotation.z = time * 0.51;*/

				water.material.uniforms[ 'time' ].value += 1.0 / 60.0;

				//renderer.render( scene, camera );
				composer.render();

            }
    }

    constructor() {
        if (!super('Test Applet'))
            return;
        this.window.setSize(850,660)
            .setClosable(true)
            .setMinSize(450,460)
            .setResizable()
            .setExpandable()
            .center()
            .setOnTop()
            .setPinnable()

            this.window.find('#btnStartGL').click((evt) => {
                console.log('GL');
                AppletTest.startGL();
            })

            this.window.find('#btnRefreshForm').click((evt) => {
                console.log('Refresh');
                this.refreshForm();
            })

    }
}

AppletTest.Initialize();