import Applet from './applet.js';
import Window from './window.js';
import AppletTickets from './appletTickets.js';

export default class AppletTicketList extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "list";
    static appname="ticketlist";


	static content = `
        <div>
            <div class="flex column m-12 h-100 quicklaunch" style="overflow:hidden;">
                <div class="h-100 d-flex flex-column">
                    <input class="w-90 m-12" style="font-size:x-large" id="search" autocomplete="off" placeholder="Recherche...">
                    <h4 id="listTitle">Résultats</h4>
                    <ul id="itemList" class="item-list flex-grow" style="overflow:auto;">

                    </ul>
                </div>
                <div class="d-none">
                    <button id="btnNew" class="inline">Nouveau</button>
                    <!--<button id="buttonDelete" class="inline"><i class="icofont-minus"></i></button>-->
                </div>
            </div>
        </div>
	`;

    static refresh() {
        if (AppletTicketList.instance.length > 0)
            AppletTicketList.instance[0].update_list();
    }

    update_tags() {
		this.window.find('.tag').each((index,tag) => {
			const uuid = $(tag).attr('uuid');
			if (uuid == "" || uuid == undefined)
				return

            if (uuid in this.operators) {
                const operator = this.operators[uuid];
                if (operator.nickname == "")
                    $(tag).html(operator.username);
                else
                $(tag).html(operator.nickname);

                var hue = operator.hue;

                $(tag).css('filter',`hue-rotate(${hue}deg)`);
            }
		});
	}

    update_list() {
        //this.window.find('#itemList').empty();
        if (this.update_request != null && this.update_request.state == "pending") {
            this.update_request.abort();
        }

        this.update_request = $.get("/ticket/list", {search:this.window.find('#search').val()}, (res) => {
            this.window.find('#itemList').empty();
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else {
                response.data.data.forEach((element) => {
                    var tags = ""
                    if (element.assignments != ""){
                        element.assignments.forEach((uuid) => {
                            tags += `<div class="tag condensed" uuid="${uuid}"></div>`;
                        })
                    }

                    const iconList = {
                        'open':'',
                        'closed':'lock',
                        'pending':'gear',
                        'waiting':'question-circle',
                        'postponed':'ui-clock',
                        'abandoned':'ui-block',
                        'evolution':'space-shuttle',
                        "to_be_closed": "eye",
                        "evol_pending_validation": "ui-clock"
                    };

                    this.window.find('#itemList').append(`
                        <li class="item" uuid="${element.uuid}"><i class="icofont-${iconList[element.status]}"></i> ${element.number} - ${element.title} ${tags}</li>
                    `);
                })
                this.window.find('#itemList').find('li').click((evt) => {
                    const uuid = $(evt.currentTarget).attr('uuid');
                    AppletTickets.New(uuid, true, this);
                    this.window.close();
                }).on('auxclick', (evt) => {
                    if (evt.button == 1) {
                        evt.preventDefault();
                        const uuid = $(evt.currentTarget).attr('uuid');
                        AppletTickets.New(uuid, true, this);
                    }
                });
                this.update_tags();
            }
        });
    }

    fetch_operators() {
        
		const req = $.get('/operator/list', (res) => {
            var operators = {}
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				operators = response.data;
                Object.keys(operators).forEach((op) => {
                    var hue = 0;
                    if (operators[op].color != "") {
                        var rgb = operators[op].color.split(',');
                        operators[op].rgb = rgb;
                        for (var i=0; i<3;i++)
                            rgb[i] = parseInt(rgb[i]);
                        const min = Math.min(rgb[0], rgb[1], rgb[2]);
                        const max = Math.max(rgb[0], rgb[1], rgb[2]);
        
                        if (min == max) {
                            hue = 0;
                        } else {
                            if (max == parseInt(rgb[0])) {
                                hue = (rgb[1] - rgb[2]) / (max - min);
                            } else if (max == rgb[1]) {
                                hue = 2 + (rgb[2] - rgb[0]) / (max - min);
                            } else {
                                hue = 4 + (rgb[0] - rgb[1]) / (max - min);
                            }
        
                            hue = Math.round(hue*60);
                        }
                    }
                    operators[op].hue = hue;
                });
                this.operators = operators;
                this.update_tags();
			}
		});
        return req;
    }

    loadPath(value) {
        this.window.find('#search').val(value);
        this.update_list();
    }

    static FromPath(value) {
		var applet = super.New("Tickets");
		applet.loadPath(value);
		return applet;
	}

    static New() {
        const applet = super.New("Tickets");
        applet.update_list();
        return applet;
    }

    constructor() {
        if (!super("Tickets"))
            return;

		this.window_list = new Window('')
            
        this.window
            .setSize(640, 550)
            .setMinSize(380, 420)
            .setClosable()
            .setPinnable()
            .setResizable()
            .setExpandable()
            .setBorderless()
            .setLayer(3)
            .center();

        this.update_request = null;

        this.window.find('#search').keyup(() => {
            this.update_list();
            this.setPath(this.window.find('#search').val());
        });

		this.fetch_operators();

        this.window.find('#btnNew').click(() => {
            AppletTickets.New(null, false, this);
        })

        this.window.find('#search').focus().keypress((evt) => {
            console.log(evt);
            if(evt.which == 13) {
                this.window.find('#itemList li').eq(0).click();
                this.window.close();
            }
        });

        //this.update_list();

    }
}

AppletTicketList.Initialize();