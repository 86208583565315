import WSConnection from './websocket.js';
import AppletChatConversation from './appletChatConversation.js';
import Widget from './widget.js';
import SettingsManager from './settingsManager.js';

export default class Notification {
    static html = `
    <div class="notification-row fade-in">
        <i class="icofont-close"></i>
        <img src="">
        <h5></h5>
        <p></p>
    </div>
    `;

    static sound = null;

    static ShowNotification(title, content, icon) {
        Notification.sound.play();
        const notification = $(Notification.html);
        notification.find('h5').html(title);
        notification.find('p').html(content);
        notification.find('img').attr('src', icon);
        Widget.SetupiLinks(notification);

        var notif = notification.clone();
        notif.find('i').click(() => { notif.remove();});
        Widget.SetupiLinks(notif);

        notification.find('i').click(() => { notification.remove();});
        setTimeout(() => {notification.remove();}, 15000);
        
        $('#notificationPanel').prepend(notification);
        $('.notifications').prepend(notif);

        this.ShowDesktopNotification(title,content,icon);
    }


    static ShowDesktopNotification(title,content,icon) {
        if (serviceWorker != null) {
            serviceWorker.postMessage({action:"notification",title:title,content:content,icon:icon});
        }
        
    }

    static ConnectWS(user_uuid) {
        Notification.sound = new Audio('/assets/sounds/quack.ogg');
        WSConnection.subscribe(`notification:${user_uuid}`, (content) => {
            //console.log(content);
            var icon = `/assets/img/${content.icon}.png`;
            if (content.icon.includes(':')) {
                icon = SettingsManager.cdn + "i/small/" + content.icon.split(':')[1];
            }

            var skip = false;
            if (content.origin.split(':')[0] == "conv") {
                AppletChatConversation.instance.forEach((i) => {
                    console.log(i.conversationUuid);
                    if (i.conversationUuid == content.origin.split(':')[1])
                        skip = true;
                })
            }

            if (!skip)
                Notification.ShowNotification(content.title, content.content, icon);
        })
    }
}