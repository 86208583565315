import AutoFormField from './autoFormFields.js';

export default class AutoForm {
    constructor(name) {
        this.name = name;
        this.ready = false;
        this.formData = null;
        this.container = null;
        this.currentObject = null;
        this.formCreated = false;
        this.formFields = {};

        this.errorCallback = console.log;
    }

    loadForm(callback) {
        $.get(`/autoform/${this.name}/get`, (res) => {
            const response = res;

            if (response.status != "OK") {
                this.errorCallback(`Error while retrieving form data ${this.name} : ${response.message}.`);
                return;
            } else {
                this.formData = response.data.fields;
                this.name = response.data.name;
                this.rights = response.data.rights;
                this.label = response.data.label;
                this.ready = true;
                callback(this);
            }
        });
    }

    loadItemByIndexValue(index, callback=null) {
        $.get(`/autoform/${this.name}/load`, {'uuid':index}, (res) => {
            const response = res;

            if (response.status != "OK") {
                this.errorCallback(`Error while retrieving form data ${this.name} : ${response.message}.`);
                return;
            } else {
                Object.keys(response.data.data).forEach((field) => {
                    //const type = this.formData[field][1];
                    //const options = this.formData[field][2];
                    const value = response.data.data[field];

                    if(this.formCreated) {
                        if (this.formFields[field] !== undefined)
                            this.formFields[field].set(value);
                    }
                });

                if (callback != null)
                    callback(response.data.title, response.data.uuid, response.data.data);
            }

        });
    }

    create(callback=null) {
        this.enableModifications(true);
        Object.keys(this.formData).forEach((field) => {
            this.formFields[field].empty();
        });
        
        if (callback != null)
            callback(response.data);

    }

    postData(data, callback) {
        $.post(`/autoform/${this.name}/update`, {data:JSON.stringify(data)}, (res) => {
            const response = res;

            if (response.status != "OK") {
                this.errorCallback(`Error while updating object ${this.name} : ${response.message}.`);
                return;
            } else {
                this.ready = true;
                callback(response.data);
            }
        });
    } 

    postForm(callback) {
        var data = {};
        Object.keys(this.formData).forEach((field) => {
            data[field] = this.formFields[field].read();
        });

        this.postData(data, callback);
    }

    loadList(search, callback, page=0,amount=50) {
        if (this.search_request != null && this.search_request.state == "pending") {
            this.search_request.abort();
        }

        this.search_request = $.get(`/autoform/${this.name}/list`, {'search':search, 'amount':amount, 'offset':page*amount}, (res) => {
            const response = res;

            if (response.status != "OK") {
                this.errorCallback(`Error while retrieving list ${this.name} : ${response.message}.`);
                return;
            } else {
                
                callback(response.data.count, response.data.items);
            }
        });
    }

    enableModifications(value) {
        Object.keys(this.formData).forEach((field) => {
            const options = this.formData[field][2];
            if (options.includes('readonly'))
                this.formFields[field].disable(true);
            else
                this.formFields[field].disable(!value);
        });
    }

    deleteItemByUuid(uuid, callback) {
        if (uuid == null)
            return;

        $.post(`/autoform/${this.name}/delete`, {uuid:uuid}, (res) => {
            const response = res;

            if (response.status != "OK") {
                this.errorCallback(`Error while deleting object ${this.name} : ${response.message}.`);
                return;
            } else {
                this.current_uuid = "";
                callback(uuid);
            }
        });
    }

    generateForm(target_container, applet) {
        this.container = target_container;
        this.formCreated = true;
        var html = $(`<div class="autoform" object="${this.name}"></div>`);

        Object.keys(this.formData).forEach((field) => {
            const label = this.formData[field][0];
            const type = this.formData[field][1];
            const options = this.formData[field][2];

            const formClass = AutoFormField.FindFormClass(type, options);
            if (formClass == null)
                return;

            this.formFields[field] = new formClass(field, label, type, options);
            this.formFields[field].generate();
            html.append(this.formFields[field].element);

            target_container.append($(html));
        });
    }
}