import Applet from './applet.js';
import Menus from './appletMenus.js';
import SettingsManager from './settingsManager.js';

import createEditorInline from './ckeditor/ckutils.js';
import Utils from './utils.js';

export default class AppletSendMail extends Applet {
    static unique = false;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "send-mail";
    static appname = "sendmail";

    static content = `
    <div id="Window2">
        <div class="form-container topless">
            <h3>Destinataire</h3>
            <div class="inline-input"><label style="width:95px;">De</label><input id="from" placeholder="" /></div>
            <!--<div class="inline-input"><label style="width:95px;">A</label><input id="to" placeholder="" /></div>-->
            <widget type="mixed:autoform/vcard" options="noicon" label="A" field="to"/>
            <!--<div class="inline-input"><label style="width:95px;">Cc</label><input id="cc" placeholder="" /></div>-->

            <widget class="flex-grow" label="Cc" type="mixed:autoform/vcard" options="noicon" field="cc"/>
            <!--<div class="inline-input"><label style="width:95px;">CCi</label><input id="cci" placeholder="" /></div>-->
            <div class="inline-input"><label style="width:95px;">Objet</label><input id="subject" placeholder="" /></div>
        </div>
        <h3>Corps du mail</h3>
        <div id="editor" class="text-editor" style="flex-grow:3; overflow:scroll;">Just some text to see how the formatting works.</div>
        <div class="attachments d-flex flex-wrap">
        </div>
        <div style="padding-top:15px; text-align:right;">
            <button id="btnAttachment" class="inline">Pièce-jointe</button>
            <button id="btnSignature" class="inline">Signature</button>
            <button id="btnCancel" class="warning">Annuler</button>
            <button id="btnSend">Envoyer</button>
        </div>
    </div>
    `

    loadPath() {
        
    }

    static FromPath(value) {
		var applet = super.New("Nouveau Mail");
		applet.loadPath(value);
		return applet;
	}

    static New(mail_account=null, to=[], cc=[], subject="Nouveau mail", content="<p>Tapez votre mail...</p>", reply_to_mail=null, sent_callback=null) {
		var applet = super.New("Nouveau Mail", true);

        applet.sent_callback = sent_callback;
        applet.reply_to_mail = reply_to_mail
		
        applet.window.find('#from').focus((evt) => {
            Menus.showSearchPopup(applet.window, applet.window.find('#from'), 'mail_account');
        });

        if (mail_account != null) {
            applet.window.find('#from').attr('value', mail_account);
            applet.fetchFieldTitle(applet.window.find("#from"), 'mail_account');
        } else {
            $.get('/mail', {action:"default_reply"}, (res) => {
                const response = res;

                if (response.status != "OK") {
                    return;
                } else {
                    applet.window.find('#from').attr('value', response.data);
                    applet.fetchFieldTitle(applet.window.find("#from"), 'mail_account');
                }
            })
        }

        if (reply_to_mail != null) {
            $.get('/autoform/mail_archive/load', {uuid:reply_to_mail}, (res) => {
                const response = res;

                if (response.status != "OK") {
                    this.window.showAlert(`Error while retrieving form data ${this.name} : ${response.message}.`);
                    return;
                } else {
                    const mail = response.data.data;
                    var archive_content = mail.content.replaceAll("vid:", `${SettingsManager.cdn}i/orig/`);
                    const comment_regex = /<!--.*-->/gs;
                    archive_content = archive_content.replace(comment_regex, "").replaceAll('width="1920"','');
                    applet.window.find("#subject").val(mail.subject);
                    applet.setMailContent(content + `<blockquote>${archive_content}</blockquote>`)

                    if(mail.mailbox == "outbox" || mail.mailbox == "sent")
                        applet.window.w('to').val(mail.hdr_to);
                    else
                        applet.window.w('to').val(mail.hdr_from);
                    applet.window.w('cc').val(mail.hdr_cc);
                }
            });
        } else {
            applet.window.find("#subject").val(subject);
            applet.setMailContent(content)
            //applet.window.find('#to').val(to.join(', '));
            applet.window.w('to').val(to);
            applet.window.w('cc').val(cc);
            //applet.window.find('#cc').val(cc.join(', '));
        }

		return applet;
	}

    setMailContent(content) {
        if (this.contenteditor == undefined)
            this.contenteditordata = content;
        else
            this.contenteditor.setData(content);
    }

    constructor() {
        if (!super("Nouveau Mail"))
        return;
        
        this.window
            .setMinSize(450, 420)
            .setTopPadding(0)
            .setSize(680, 720)
            .setClosable()
            .setPinnable()
            .setResizable()
            .setExpandable()
            .center()
        
        this.window.find('#btnAttachment').click((evt) => {
            Utils.OpenMediaLibrary(this.window, (uuid,title) => {
                var node = $(`<div class="mail-attachment" uuid="${uuid}">
                <img src="${SettingsManager.cdn}i/small/${uuid}" width=36 height=36>
                <span>${title}</span>
                </div>`);
                node.click(() => { node.remove(); });
                this.window.find(".attachments").append(node);
            });

        });
        
        this.window.find('#btnSignature').click((evt) => {
            Menus.showSearchPopup(this.window, $(evt.currentTarget), "signature", "callback", (value,label) => {
                $.get('/autoform/signature/load', {uuid:value}, (res) => {
                    const response = res;
    
                    if (response.status != "OK") {
                        this.window.showAlert(`Error while retrieving form data ${this.name} : ${response.message}.`);
                        return;
                    } else {
                        const viewFragment = this.contenteditor.data.processor.toView( response.data.data.content );
                        const modelFragment = this.contenteditor.data.toModel( viewFragment );

                        this.contenteditor.model.insertContent( modelFragment );
                        /*content += response.data.data.content;
                        this.contenteditor.setData(content);*/
                    }
                });
            });
        })

        this.window.find('#btnCancel').click((evt) => {
            this.window.close();
        });

        this.window.find('#btnSend').click((evt) => {
            var re = /\s*(,|;|$)\s*/;
            var attachments = [];
            this.window.find(".mail-attachment").each((idx,elt) => {
                attachments.push($(elt).attr("uuid"));
            });

            var mail_data = {
                _from: this.window.find('#from').attr('value'),
                //to: this.window.find('#to').val().split(re),
                //cc: this.window.find('#cc').val().split(re),
                //cci:this.window.find('#cci').val().split(re),
                to: this.window.w('to').val(),
                cc: this.window.w('cc').val(),
                subject: this.window.find('#subject').val(),
                content: this.contenteditor.getData(),
                reply: this.reply_to_mail,
                attachments: attachments
            };

            $.post('/mail/sendmail', {data:JSON.stringify(mail_data)}, (res) => {
                const response = res;
                if (response.status != 'OK') {
                    this.window.showAlert(response.message);
                } else {
                    if (this.sent_callback != null)
                        this.sent_callback(response.data);
                    else
                        this.window.showAlert('Message enregistré');
                    this.window.close();
                }
            })

        });

        createEditorInline(this.window.uid, '#editor', 
            (editor) => {
                this.contenteditor = editor;
                this.created = true;
                if (this.contenteditordata != undefined)
                    this.contenteditor.setData(this.contenteditordata);
            },
            this.window.showAlert
        );       

    }
}