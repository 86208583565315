
export default class WSConnection {
        static url = "localhost";
        static session_id = "";
        static conn_in = null;
        static conn_out = null;
        static conn_id = "undefined";

        static events = { 'statuschange':[]}

        static Initialize(session_id=null) {
            if (session_id != null)
                WSConnection.session_id = session_id;

            var ws = new WSConnection();
            ws.Connect()
        }

        static on(event, callback) {
            if (Object.keys(WSConnection.events).includes(event)) {
                WSConnection.events[event].push(callback);
            }
        }

        static trigger_events(event, args) {
            WSConnection.events[event].forEach((callback) => {callback(args)});
        }

        static subscribe(event, callback) {
            $.post('/subscribe', {channel:event,conn_id:WSConnection.conn_id}, (res) => {
                const response = res;
                if (response.status == "error") {
                    console.log(`Error while subscribing to ${response.error_message}`);
                } else {
                    if (!(Object.keys(WSConnection.events).includes(event))) {
                        WSConnection.events[event] = [];
                    }
                    WSConnection.on(event, callback);
                }
            });
        }

        static unsubscribe(event) {
            $.post('/unsubscribe', {channel:event, conn_id:WSConnection.conn_id}, (res) => {
                const response = res;
                if (response.status == "error") {
                    console.log(`Error while subscribing to ${response.error_message}`);
                } else {
                    WSConnection.events[event] = [];
                }
            });
        }

		Connect() {
			var ws = new WebSocket(`wss://${WSConnection.url}/ws/`);
			ws.onopen = () => {
				ws.send(WSConnection.session_id);
                WSConnection.trigger_events('statuschange','open');
			}
			ws.onmessage = (evt) => {
				var msg = JSON.parse(evt.data);
				switch(msg.type) {
					case "notification":
					    updateNotifications("[1,[" + evt.data + "],1]");
					    break;
					case "info":
                        WSConnection.conn_id = msg.conn_id;
                        WSConnection.trigger_events('statuschange','ready');
					    break;
					default:
					    break;
				}
                if(Object.keys(WSConnection.events).includes(msg.type)) {
                    WSConnection.trigger_events(msg.type, msg.content);
                }
			}
		
			ws.onclose = () => {
                WSConnection.trigger_events('statuschange','close');
			}
            this.conn_in = ws;
		}
}