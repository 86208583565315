import * as THREE from 'three';

import Stats from 'three/examples/jsm/libs/stats.module.js';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Water } from 'three/examples/jsm/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

export default class SettingsManager {

    static cdn = "https://localhost/i/";

    static getValue(name) {
        return localStorage.getItem(name);
    }

    static setValue(name,value) {
        return localStorage.setItem(name,value);
    }

    static Initialize() {
        if (SettingsManager.getValue("FX") == "true") {
            $('main').removeClass('no-effects');
        }
        else {
            $('main').addClass('no-effects');
        }

        if (SettingsManager.getValue("GL") == "true") {
            SettingsManager.startGL();
        }
        else {
            SettingsManager.SwitchToDesktopImg();
        }
    }


    static SwitchToDesktopImg() {
        SettingsManager.setValue("GL",false);
        var url="/assets/img/vagar-island-faroe-islands-denmark.jpg";
        const customBG = SettingsManager.getValue("CustomBackground");

        if (customBG != null && customBG.length > 0)
            url =`${SettingsManager.cdn}i/original/${customBG}`;

        $('.background').empty();
        $('.background').html(
            `<canvas id="glCanvas" width=1920 height=1080 style="width:100%; height:100%; background-color:aliceblue; background-image: url(${url}); background-size:cover;"></canvas>`
        )
    }

    static startGL() {
        SettingsManager.setValue("GL",true);
			let container, stats;
			let camera, scene, renderer, composer;
			let controls, water, sun, mesh;

			let glitchPass;

			const params = {
				exposure: 1,
				bloomStrength: .35,
				bloomThreshold: 0,
				bloomRadius: 0.0
			};

			init();
			animate();

			function init() {

				renderer = new THREE.WebGLRenderer();
				renderer.setPixelRatio( window.devicePixelRatio );
				renderer.setSize( window.innerWidth, window.innerHeight );

                //renderer.toneMapping = THREE.ACESFilmicToneMapping;
				renderer.toneMapping = THREE.ReinhardToneMapping;
				renderer.toneMappingExposure = 1.0;

                $(".background").empty();
                $(".background").append(renderer.domElement);
				//

				scene = new THREE.Scene();

				camera = new THREE.PerspectiveCamera( 55, window.innerWidth / window.innerHeight, 1, 20000 );
				camera.position.set( 30, 30, 100 );

                

				const loader = new THREE.ObjectLoader();
				sun = new THREE.Vector3();

				// Water

				const waterGeometry = new THREE.PlaneGeometry( 10000, 10000 );

				water = new Water(
					waterGeometry,
					{
						textureWidth: 512,
						textureHeight: 512,
						waterNormals: new THREE.TextureLoader().load( '/assets/img/waternormals.jpg', function ( texture ) {

							texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

						} ),
						sunDirection: new THREE.Vector3(),
						sunColor: 0xffffff,
						waterColor: 0x409fad,
						distortionScale: 3.7
						//fog: scene.fog !== undefined
					}
				);

				water.rotation.x = - Math.PI / 2;

				scene.add( water );

				// Skybox

				const sky = new Sky();
				sky.scale.setScalar( 10000 );
				scene.add( sky );

				const skyUniforms = sky.material.uniforms;

				skyUniforms[ 'turbidity' ].value = 10;
				skyUniforms[ 'rayleigh' ].value = 2;
				skyUniforms[ 'mieCoefficient' ].value = 0.005;
				skyUniforms[ 'mieDirectionalG' ].value = 0.8;

				const parameters = {
					elevation: 2,
					azimuth: 230
				};

				const pmremGenerator = new THREE.PMREMGenerator( renderer );

				function updateSun() {

					const phi = THREE.MathUtils.degToRad( 90 - parameters.elevation );
					const theta = THREE.MathUtils.degToRad( parameters.azimuth );

					sun.setFromSphericalCoords( 1, phi, theta );

					sky.material.uniforms[ 'sunPosition' ].value.copy( sun );
					water.material.uniforms[ 'sunDirection' ].value.copy( sun ).normalize();

					scene.environment = pmremGenerator.fromScene( sky ).texture;

				}

				updateSun();

				//

				const geometry = new THREE.BoxGeometry( 30, 30, 30 );
				const material = new THREE.MeshStandardMaterial( { roughness: 0 } );

				mesh = new THREE.Mesh( geometry, material );
				//scene.add( mesh );

				//

				controls = new OrbitControls( camera, renderer.domElement );
				controls.maxPolarAngle = Math.PI * 0.495;
				controls.target.set( 0, 10, 0 );
				controls.minDistance = 40.0;
				controls.maxDistance = 200.0;
				controls.update();

				stats = new Stats();

				// postprocessing

				const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
				bloomPass.threshold = params.bloomThreshold;
				bloomPass.strength = params.bloomStrength;
				bloomPass.radius = params.bloomRadius;


				composer = new EffectComposer( renderer );
				composer.addPass( new RenderPass( scene, camera ) );

				composer.addPass( bloomPass );

				glitchPass = new GlitchPass();

				window.addEventListener( 'resize', onWindowResize );

			}

			function onWindowResize() {

				camera.aspect = window.innerWidth / window.innerHeight;
				camera.updateProjectionMatrix();

				renderer.setSize( window.innerWidth, window.innerHeight );
				composer.setSize( window.innerWidth, window.innerHeight );
			}

			function animate() {

				requestAnimationFrame( animate );
				render();

			}

			function render() {

				const time = performance.now() * 0.001;
				water.material.uniforms[ 'time' ].value += 1.0 / 60.0;
				composer.render();

            }
        }

}