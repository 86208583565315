import Applet from './applet.js';
import Window from './window.js';

import AppletTicketList from './appletTicketList.js';
import AppletTicketMini from './appletTicketMini.js';
import AppletMail from './appletMail.js';
import AppletChat from './appletChat.js';
import AppletAddressBook from './appletAddressBook.js';
import AppletMediaLibrary from './appletMediaLibrary.js';
import AppletKnowledgeBase from './appletKnowledgeBase.js';
import AppletSysMon from './appletSysMon.js';
import AppletMailAccounts from './appletMailAccounts.js';
import AppletLayout from './appletLayout.js';
import AppletAutoForm from './appletAutoForm.js';
import AppletTicketGrid from './appletTicketGrid.js';
import AppletPostIt from './appletPostIt.js';
import AppletTest from './appletTest.js';
import Menus from './appletMenus.js';
import Widget from './widget.js';
import AppletMailDatabase from './appletMailDatabase.js';
import AppletReporting from './appletReporting.js';

export default class AppletLauncher extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "rocket";
    static appname="launcher";
    static content = `
    <div id="Window1">
        <div id="appList" class="icon-box hide-scrollbar">

        </div>  
    </div>
    `;

    addApplication(appinfo) {
        this.window.find('#appList').append(
            `
            <div class="app-icon app-${appinfo.className}" target="${appinfo.className}" tooltip="${appinfo.description}">
                <i class="icofont-${appinfo.icon}"></i>
                <!--<p style="padding-left:0px">${appinfo.name}</p>-->
            </div>
            `
        )
    }

    AddWindow(window) {
        this.appList.forEach((app) => {
            if (window.application instanceof app.classRef) {
                app.windows.push(window);
                return;
            }
        });
        this.UpdateAppIcons();
    }

    UpdateAppIcons() {
        this.appList.forEach((app) => { 
            if(app.windows.length == 0) {
                this.window.find(`.app-${app.className}`).removeClass("has-window").removeClass("multiple");
            } else if(app.windows.length == 1) {
                this.window.find(`.app-${app.className}`).addClass("has-window").removeClass("multiple");
            } else if (app.windows.length > 1) {
                this.window.find(`.app-${app.className}`).addClass("has-window").addClass("multiple");
            }
        });
    }

    RemoveWindow(window) {
        this.appList.forEach((app) => { 
            if (window.application instanceof app.classRef)
            { app.windows = app.windows.filter(function(item) {
                return item !== window
            })}
        });
        this.UpdateAppIcons();
    }

    windowClicked(window) {
        if(window == this.window)
            return;

        this.window.find(".app-icon").removeClass("current");
        this.topWindow = window;
        if(window == null)
            return;

        this.appList.forEach((app) => { 
            if(window.application instanceof app.classRef)
                this.window.find(`.app-${app.className}`).addClass("current");
        });
    }

    loadPath() {
        
    }

    menuCallback(res) {
        var uid = res.split(":")[0];
        var action = res.split(":")[1];

        if(action == "close_all") {
            const app = this.get_app(uid);
            app.windows.forEach((win) => { win.close();});
            return;
        }

        const win = Window.GetWindowByUid(uid);
            switch(action) {
                case "rename":
                    win.showInputBox("", "Nom de la fenêtre", (value) => { win.setTitle(value);});
                    break;
                case "reduce":
                    win.setReduced(!win.reduced);
                    break;
                case "decorate":
                    win.setBorderless(!win.window.hasClass('borderless'));
                    break;
                case "center":
                    win.center().setOnTop();
                    break;
                case "popout":
                    win.browserPopUp();
                    break;
                case "restore":
                    win.setSize(win.minSize[0],win.minSize[1]).center().setOnTop();
                    break;
                case "close":
                    win.close();
                    break;
                default:
                    break;
            }
    }

    createContextMenu(uid) {

        return [
            ['Renommer',`${uid}:rename`],
            ['Décoration',`${uid}:decorate`],
            ['Réduire',`${uid}:reduce`],
            ['Centrer',`${uid}:center`],
            ['Restaurer',`${uid}:restore`],
            ['Pop',`${uid}:popout`],
            ["Fermer", `${uid}:close`]
        ];
    }

    static FromPath(value) {
		var applet = super.New("Lanceur");
		applet.loadPath(value);
		return applet;
	}

    static appinfo(name, description, icon, className, classRef) {
        return {
            "name":name,
            "description":description,
            "icon":icon,
            "className":className,
            "classRef":classRef,
            "windows" : []
        }
    }

    get_app(app_name) {
        var target_app = null;
        this.appList.forEach((app) => {
            if (app.className == app_name) {
                target_app = app;
                return;
            }
        });
        return target_app;
    }

    static LaunchApp(appName) {
        AppletLauncher.get_app_list().forEach((app) => {
            if (app.className == appName)
                app.classRef.New();
        })
    }

    static get_app_list() {
        var list = [
            this.appinfo('Tickets','Lanceur rapide', 'ticket', 'AppletTicketList', AppletTicketList),
            this.appinfo('Evènements','Créer un nouvel évènement', 'zigzag', 'AppletTicketMini', AppletTicketMini),
            this.appinfo('TicketGrid','Gestion des tickets', 'table', 'AppletTicketGrid', AppletTicketGrid),
            this.appinfo('Messagerie', 'Consulter et envoyer des mails', 'email', 'AppletMail', AppletMail),
            this.appinfo('Base de données mails', 'Base de données des mails enregistrés dans Foroyar', "mail-box", "AppletMailDatabase", AppletMailDatabase),
            this.appinfo('Chat', 'Logiciel de chat', "chat", "AppletChat", AppletChat),
            this.appinfo('Carnet d\'adresse', 'Consulter et modifier votre carnet d\'adresse', "address-book", "AppletAddressBook", AppletAddressBook),
            this.appinfo('Bibliothèque de medias', 'Traitement des images', "image", "AppletMediaLibrary", AppletMediaLibrary),
            this.appinfo('Base de connaissances', 'Bases de connaissance', "library", "AppletKnowledgeBase", AppletKnowledgeBase),
            this.appinfo('Moniteur', 'Surveillance du serveur', "server", "AppletSysMon", AppletSysMon),
            this.appinfo('Paramètres', 'Modification des paramètres système', "gear", "AppletMailAccounts", AppletMailAccounts),
            this.appinfo('Espace de travail', 'Créée un nouvel espace de travail vierge', "layers", "AppletLayout", AppletLayout),
            this.appinfo('Base de données', 'Editer le contenu de la base de données avec AutoForms', "database", "AppletAutoForm", AppletAutoForm),
            this.appinfo('Rapport', 'Rapport sur les tickets ouvert', "data", "AppletReporting", AppletReporting),
            //this.appinfo('Test Applet', 'Test des nouvelles fontionnalités', "laboratory", "AppletTest", AppletTest) 
        ];
        return list;
    }

    constructor() {
        if (!super("Lanceur"))
            return;
        
        $(document).keydown((evt) => {
            if(evt.key == " " && evt.ctrlKey) {
                AppletTicketList.New();
            }
        } )
            
        this.topWindow = null;

        this.window
            .setSize(180, 780)
            .setMinSize(180, 250)
            .setPosition(35,95)
            .setClosable()
            .setPinnable()
            .setBorderless()
            .setResizable(false)
            .setExpandable()
            .setLayer(3)

        this.window.window.addClass('launcher');

        $(document).on("addWindow", (evt,window) => {
            this.AddWindow(window)
        })

        $(document).on("removeWindow", (evt,window) => {
            this.RemoveWindow(window)
        })

        $(document).on("windowClicked", (evt,window) => {
            this.windowClicked(window);
        })

        $(document).on("windowClosed", (evt,window) => {
            if(window == this.topWindow)
                this.windowClicked(null)
        })

        $(document).on("fullscreen", () => {
            if (Window.IsFullScreen())
                this.window.window.addClass("auto-hide");
            else
                this.window.window.removeClass("auto-hide");
        });

        this.appList = AppletLauncher.get_app_list();
        this.appList.forEach((app) => {
            this.addApplication(app);
        })

        this.window.find('.app-icon').click((evt) => {
            var newApp = null;
            const target = $(evt.currentTarget).attr('target');
            this.appList.forEach((app) => {
                if (app.className == target) {
                    if (app.windows.length == 0)
                        newApp = app.classRef.New();
                    else if (app.windows.length == 1) {
                        if (app.windows[0] == this.topWindow && !app.windows[0].reduced) {
                            app.windows[0].setReduced(true);
                        } else {
                            app.windows[0].setReduced(false);
                            app.windows[0].setOnTop();
                        }
                    }
                    else {
                        var menu_list = [];
                        app.windows.forEach((win) => {
                            menu_list.push([win.title, win.uid]);
                        });
                        Widget.UpdateTooltip();
                        Menus.showMenu(this.window.window, $(evt.currentTarget), menu_list, (uid) => {
                            var win = Window.GetWindowByUid(uid);
                            win.setReduced(false);
                            win.setOnTop();
                        });
                    }
                }
            })
        }).on('auxclick', (evt) => {
            evt.preventDefault();
            if(evt.button == 1)  {
                var app = this.get_app($(evt.currentTarget).attr("target"));
                var newApp = app.classRef.New();
            }
        })
        .on('contextmenu', (evt) => {
            evt.preventDefault();
            const app = this.get_app($(evt.currentTarget).attr('target'));
            if (app.windows.length == 1) {
                Menus.showMenu(this.window.window, $(evt.currentTarget), this.createContextMenu(app.windows[0].uid), this.menuCallback);
            } else if (app.windows.length > 1) {
                var menu_list = [];
                app.windows.forEach((win) => {
                    menu_list.push([win.title, this.createContextMenu(win.uid)]);
                });
                menu_list.push(["Fermer tout", `${app.className}:close_all`]);
                Menus.showMenu(this.window.window, $(evt.currentTarget), menu_list, (res) => this.menuCallback(res));
            }
        });

        this.window.find('#accountSettings').click((evt) => {
            const winSettings = AppletMailAccounts.New();
        });

        Widget.SetupTooltips(this.window);


    }
}

AppletLauncher.Initialize();