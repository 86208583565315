import Applet from './applet.js';
import Window from './window.js';
import Utils from './utils.js';

export default class Menus extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = false;

    static colorPickerPopup = `
        <div id="colorPickerPopup">
            <div id="colorField" style="width:228px;height:128px;background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,0,0,1) 100%);">
                <div id="subColorField" style="width: 100%; height: 100%; background: linear-gradient(0deg, rgba(0,0,0) 0%, rgba(255,255,2555,0) 100%);">
                    <div id="cursor" style="position:relative; filter:opacity(.5); width:8px; height:8px; border-radius:4px; border:1px solid white;">
                </div>
            </div>
            <div id="hue" style="width:228px;height:28px;margin-top:10px;background: linear-gradient(to right, red 0%, #ff0 17%, lime 33%, cyan 50%, blue 66%, #f0f 83%, red 100%);">
                <div id="hcursor" style="position:relative; width:4px; height:32px; filter:opacity(.5); top: -2px; border-radius:2px; border:1px solid white;"></div>
            </div>
            <div>
                <div id="finalColor" style="display:inline-block; margin:auto; width:48px;height:48px;border-radius:24px; background-color:red;">
                    
                </div>
                <button id="submit" class="inline">Valider</button>
            </div>
        </div>
    `;

    static menuPopup = `
        <div id="menuPopup">
            <ul id="menu" class="menu">

            </ul>
        </div>
    `

    static searchPopup = `
        <div id="searchPopup">
            <input id="searchInput" placeholder="Recherche..."></input>
            <ul id="searchResults" class="item-list h-100" style="overflow-y:auto;">
                
            </ul>
        </div>
    `

    static content = ``;

    static updateSearch(window, type, value, resultField, input_type, callback=null) {

        $.get(`/search/${type}`, {search:value}, (response) => {
            window.find('#searchResults').empty();
            if (response.status != 'OK') {
                window.showAlert(response.message)
            } else {
                response.data.forEach((element) => {
                    window.find('#searchResults').append(`<li class="item" value="${element.value}">${element.label}</li>`);
                });
                window.find('.item').click((evt) => {
                    const dom = $(evt.currentTarget);
                    const value = dom.attr('value');
                    const label = dom.html();
                    if (input_type == 'callback') {
                        callback(value,label);
                        window.close(true);
                    } else if (input_type == 'input') {
                        resultField.val(label).attr('value',value);
                        window.close(true);
                    } else if (input_type == 'list') {
                        const content = resultField.attr('value').split(',');
                        if (!content.includes(value)) {
                            resultField.attr('value', content.concat([value]).join(','));
                            const line = $(`<li class='item-removable' value='${value}'>${label}</li>`);
                            line.click((evt) => {
                                if (!(resultField.hasClass('disabled'))) {
                                    const uuid = line.attr('value');
                                    resultField.attr('value', resultField.attr('value').replace(uuid, '').replace(',,',','));
                                    line.remove();
                                }
                            })
                            resultField.append(line);
                        }
                        window.close(true)
                    }
                });
            }
        });
    }

    static update_position(popup_window, button, width, height, horizontal=false) {
        var position_x;
        var position_y;
        if (horizontal) {
            position_x = button.offset().left + button.outerWidth();
            position_y = button.offset().top - 15;
            if (position_x + width > $(document).width())
                position_x -= button.outerWidth() + width;
            if (position_y + height > $(document).outerHeight())
                position_y += button.outerHeight() - height + 30; 
        } else {
            position_x = Math.min(button.offset().left, $(document).outerWidth() - width - 4);
            position_y = button.offset().top + button.outerHeight() + 4;
            if (position_y + height > $(document).outerHeight()) {
                position_y = Math.min(position_y - button.outerHeight() + 4 + height, $(document).outerHeight() - height - 4); 
            }
        }
        
        popup_window.setPosition(position_x,position_y).setOnTop();
    }

    static _showPopup(parent, resultField, width, height, content) {
        if (resultField.is(':disabled'))
            return null;

        const window = new Window(Utils.generate_uid('popup_'), "popup_", 100, 100, 100, 100, $(content));

        Menus.update_position(window, resultField, width, height)

        window
            .setSize(width, height)
            .setBorderless()
            .setTransition()
            .setLite().setLayer(4);

        setTimeout(() => {
            $('.background').click(() => { window.close(true);})
            $(document).on("windowClicked", (evt, win) => {
                if (win.application != null)
                window.close(true);
            });
            /*if ("window" in parent)
                parent.window.mouseup((evt) => { window.close(true); });
            else
                parent.mouseup((evt) => { window.close(true); });*/
        }, 250);
        return window;

    }

    static showMenu(parent, resultField, menuItems, callback=null) {
        return this._showMenu(parent, resultField, menuItems, callback);
    }

    static _showMenu(parent, resultField, menuItems, callback=null, is_submenu = false, root_menu=null) {
        const window = Menus._showPopup(parent, resultField, 180, 25, Menus.menuPopup);

        var submenus = [];
        var submenu_windows = {};
        var callbacks = []

        if (root_menu == null)
            root_menu = window;
        
        menuItems.forEach((element) => {
            if (element == "separator") {
                window.find('#menu').append(`<li class='separator'> </li>`);
            } else if (Array.isArray(element[1])) {
                window.find('#menu').append(`<li class='submenu' index='${submenus.length}'>${element[0]} <i class="icofont-caret-right"></i></li>`);
                submenus.push(element[1]);
            } else {
                if (element.length == 3) {
                    window.find('#menu').append(`<li class='button' index='${callbacks.length}' value='${element[1]}'>${element[0]}</li>`);
                    callbacks.push(element[2]);
                } else {
                    window.find('#menu').append(`<li class='button' value='${element[1]}'>${element[0]}</li>`);
                }
            }
        });
        window.find('#menu').find('li')
        .click((evt) => {
            if ($(evt.currentTarget).hasClass('button')) {
                var index = $(evt.currentTarget).attr('index');
                if (index !== undefined) {
                    index = parseInt(index);
                    var res = callbacks[index]($(evt.currentTarget).attr('value'), $(evt.currentTarget));
                    if (res == true) {
                        if (is_submenu)
                            root_menu.close(true);
                        else
                            window.close(true);
                    }
                } else {

                    if (callback != null) {
                        callback($(evt.currentTarget).attr('value'));
                    }
                    if (is_submenu)
                        root_menu.close(true);
                    else
                        window.close(true);
                }
            }
        })
        .mouseover((evt) => {
            evt.preventDefault();
            if ($(evt.currentTarget).hasClass('submenu')) {
                const index = parseInt($(evt.currentTarget).attr('index'));
                if (!(index in submenu_windows) || submenu_windows[index] == null) {
                    submenu_windows[index] = this._showMenu(parent, $(evt.currentTarget), submenus[index], callback, true, root_menu);
                }
                Object.keys(submenu_windows).forEach((findex) => {
                    if (findex == index)
                        return;
                    if (submenu_windows[findex] != null) {
                        submenu_windows[findex].close(true);
                        submenu_windows[findex] = null;
                    }
                })
            } else if ($(evt.currentTarget).hasClass('button')) {
                Object.keys(submenu_windows).forEach((index) => {
                    if (submenu_windows[index] != null) {
                        submenu_windows[index].close(true);
                        submenu_windows[index] = null;
                    }
                })
            }
        });
        window.oncloseimmediate = () => {
            Object.keys(submenu_windows).forEach((index) => {
                if (submenu_windows[index] != null)
                    submenu_windows[index].close(true);
            })
        }
        window.setSize(180, Math.min(500,window.find('#menu').height()) + 30);
        window.content.addClass("menu");
        Menus.update_position(window, resultField, 180, window.window.height(),is_submenu)
        return window;
    }

    static showColorPickerPopup(parent, resultField, callback=null) {
        const window = Menus._showPopup(parent, resultField, 260, 260, Menus.colorPickerPopup);
        if (window == null)
        return;

        window.hue = [255,0,0];
        window.percent_x = 1;
        window.percent_y = 0;
        window.fcol = [255,0,0];

        const lerp = (x, y, a) => Math.floor(x * (1 - a) + y * a);
        const color_lerp = (colora, colorb, a) => {
            var col = [];
            for (var i=0; i < 3; i++) 
                col.push(lerp(colora[i], colorb[i], a));
            return col;
        }

        const gradient = {
            0 : [255,  0,  0],
            1 : [255,255,  0],
            2 : [0  ,255,  0],
            3 : [0,  255,255],
            4 : [0,    0,255],
            5 : [255,  0,255],
            6 : [255,  0,  0],
        }

        const update_color = () => {
            const color_a = color_lerp([255,255,255], window.hue, window.percent_x);
            const fcol = color_lerp(color_a, [0,0,0], window.percent_y);
            window.fcol = fcol;
            window.find('#finalColor').css('background-color', `rgb(${fcol[0]} ${fcol[1]} ${fcol[2]})`);
        }

        window.find('#subColorField').click((evt) => {
            window.find('#cursor').css('left', (evt.offsetX -4 )+ "px").css('top', (evt.offsetY -4) + "px");
            window.percent_x = evt.offsetX / $(evt.currentTarget).width();
            window.percent_y = evt.offsetY / $(evt.currentTarget).height();  
            update_color();
        })

        window.find('#hue').click((evt) => {
            window.find('#hcursor').css('left', (evt.offsetX -3 )+ "px");
            const percent = evt.offsetX / $(evt.currentTarget).width() * 6;
            const gstep = Math.min(5,Math.floor(percent));

            var color = color_lerp(gradient[gstep], gradient[gstep+1], percent - gstep);

            window.hue = color;

            window.find('#colorField').css('background', `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(${color[0]},${color[1]},${color[2]},1) 100%)`);
            update_color();            
        });

        window.find('#submit').click((evt) => {
            if (callback != null)
                callback(window.fcol);

            window.close(true);
        })
       

    }
    
    static showSearchPopup(parent, resultField, search_type, input_type="input", callback=null) {

        const window = Menus._showPopup(parent, resultField, 260, 400, Menus.searchPopup);

        if (window == null)
            return;


        Menus.updateSearch(window, search_type, window.find('#searchInput').val(), resultField, input_type, callback);

        window.find('#searchInput').focus().keyup(() => {
            Menus.updateSearch(window, search_type, window.find('#searchInput').val(), resultField, input_type, callback);
        });

    }

    constructor() {

    }
}