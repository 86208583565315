import Applet from './applet.js';
import Foroyar from './foroyar.js';
import SettingsManager from './settingsManager.js';

export default class AppletViewer extends Applet {
    static unique = false;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "image";
    static appname="viewer";

    static content = `
    <div id="WindowViewer">
		<div class="h-100 w-100 img-box flex column viewer" id="imgBox">
            <div class="flex row hover-visible" id="titleRow" style="margin:12px; white-space:nowrap;">
                <i class="lock-hide icofont-download grey m-auto pr-2" style="font-size:x-large;" id="downloadButton"></i>
                <i class="lock-hide icofont-paint-brush grey m-auto pr-2" style="font-size:x-large;" id="wallpaperButton"></i>
                <h3 class="subtitle flex-grow m-auto" style="user-select:none; width:20px; overflow:hidden; text-overflow:ellipsis;" id="title">Image</h3>
                <i class="lock-hide icofont-close grey m-auto px-2" style="font-size:x-large; cursor:pointer;" id="closeButton"></i>
            </div>
        </div>
	</div>
    `;

    static titlebar_content = `
    <button style="margin-right:20px;" class="small btn btn-viewer-download">
        <span>Télécharger</span><i style="color:#6ca8da;"class="icofont-download"></i>
    </button>`

    load_document(doc_uuid) {
        this.doc_uuid = doc_uuid;
        $.get(`/media/get`, {uuid:doc_uuid}, (response) => {
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else {
                this.setPath(doc_uuid);
                this.window.setTitle(response.data.title);
                if(response.data.mimetype.split("/")[0] == "image") {
                    this.load_image(doc_uuid, response.data.title);
                } else {
                    this.load_viewer(doc_uuid, response.data.title);
                }

                this.window.find('.btn-viewer-download').click(() => {
                    window.location = `${SettingsManager.cdn}orig/${doc_uuid}?download=1`;
                });
                
            }
        });
    }

    load_image(img_uuid, title) {
        this.img_uuid = img_uuid;
        this.window.find('#imgBox').css("background-image",`url(${SettingsManager.cdn}i/original/${img_uuid})`);
        this.image.src = `${SettingsManager.cdn}i/original/${img_uuid}`;
        this.window.find("#downloadButton").click(() => {
            window.location = `${SettingsManager.cdn}orig/${img_uuid}?download=1`;
        })
        this.window.find('#title').html(`${title} <span class="zoom">100%</span>`);
        this.window.find('.zoom').click(() => {
            this.currentMediaSize = 100;
            this.setMediaSize(100);
            var pos = this.centerImage();
            this.setImagePosition(pos[0], pos[1]);
        });
        
        this.currentMediaSize = 100;
        this.setMediaSize(100);
        var pos = this.centerImage();
        this.setImagePosition(pos[0], pos[1]);
    }

    load_viewer(doc_uuid, title) {
        this.window.find('#titleRow').remove();
        this.window.setBorderless(false);
        $.get(`/embed/${doc_uuid}`, (response) => {
            this.window.find('#imgBox').append($(response));
        })
    }

    loadPath(value) {
        this.load_document(value);
    }

    getCurrent01Pos() {
        var ctn_w = this.window.find('#imgBox').width();
        var ctn_h = this.window.find('#imgBox').height();
        const ratio_img = this.image.width / this.image.height;
        var img_w = ctn_w * this.zoom;
        var img_h = img_w / ratio_img;

        var pos01_x = (-this.imgPos[0] + ctn_w / 2) / img_w;
        var pos01_y = (ctn_h / 2 - this.imgPos[1]) / img_h;

        return [Math.max(0,Math.min(1,pos01_x)), Math.max(0,Math.min(1,pos01_y))];
    }

    centerImage(xratio = .5, yratio = .5) {
        var ctn_w = this.window.find('#imgBox').width();
        var ctn_h = this.window.find('#imgBox').height();

        var img_w = -1;
        var img_h = -1;

        const ratio_img = this.image.width / this.image.height;
        const ratio_ctn = ctn_w / ctn_h;

        //if (ratio_img > ratio_ctn) {
            img_w = ctn_w;
            img_h = img_w / ratio_img;
        /*} else {
            img_h = ctn_h;
            img_w = img_h * ratio_img
        }*/

        img_w *= this.zoom;
        img_h *= this.zoom;

        const posX = ctn_w *.5 - img_w * xratio;
        const posY = ctn_h *.5 - img_h * yratio;

        return [posX, posY];
    }

    setImagePosition(posX, posY) {
        this.imgPos = [posX, posY];
        this.window.find('#imgBox').css('background-position', `${posX}px ${posY}px`);
    }

    setMediaSize(zoom) {
        this.zoom = zoom/100;
        this.window.find('#imgBox').css('background-size', `${zoom}%`);
        this.window.find(".zoom").html(`${Math.floor(zoom)}%`);
    }

    static FromPath(value) {
		var applet = super.New("Image");
		applet.loadPath(value);
        applet.window.find("#closeButton").click(() => {applet.window.close();})
		return applet;
	}

    static New(img_uuid=null) {
        var applet = super.New('Image');
        if (img_uuid != null)
            applet.load_document(img_uuid)
        applet.window.find("#closeButton").click(() => {applet.window.close();})
        return applet;
    }

    constructor() {
        super("Image");

        this.image = new Image();
        this.img_uuid = null;

        this.window.find('#imgBox').on('mousewheel', (evt) => {
            var pos01 = this.getCurrent01Pos();
            const delta = evt.originalEvent.deltaY;
            this.currentMediaSize = Math.max(12.5, Math.min(800, this.currentMediaSize - delta * .001 * this.currentMediaSize));
            this.setMediaSize(this.currentMediaSize);
            var pos = this.centerImage(pos01[0], pos01[1]);
            this.setImagePosition(pos[0], pos[1]);
        })

        this.currentMediaSize = 100;
        this.dragging=false;
        this.zoom = 1;

        this.origCursorPos = null;
        this.imgPos = [0, 0];
        this.origImgPos = [0 ,0];

        this.window.find('#imgBox').mousedown((evt) => {
            if (this.window.dragging)
                return;
            window.getSelection().empty();
            this.dragging = true;
            this.origImgPos = this.imgPos;
            this.origCursorPos = [evt.screenX, evt.screenY];
        });

        $('#wallpaperButton').click((evt) => {
            Applet.SetWallPaper(this.img_uuid);
        })
        
        $(document).mousemove((evt) => {
            
            if (this.dragging) {
                //console.log(evt);
                window.getSelection().empty();
                var posX = evt.originalEvent.screenX - this.origCursorPos[0] + this.origImgPos[0];
                var posY = evt.originalEvent.screenY - this.origCursorPos[1] + this.origImgPos[1];
                this.window.find('#imgBox').css('background-position', `${posX}px ${posY}px`);
            }
        });
        $(document).mouseup((evt) => {
            if (this.dragging) {
                window.getSelection().empty();
                var posX = evt.originalEvent.screenX - this.origCursorPos[0] + this.origImgPos[0];
                var posY = evt.originalEvent.screenY - this.origCursorPos[1] + this.origImgPos[1];
                this.imgPos = [posX, posY];
                this.dragging = false;
            }
        })
            
        this.window
            .setSize(600, 600)
            .setMinSize(100, 100)
            .center()
            .setPadding(0)
            .setClosable()
            .setBorderless()
            .setExpandable()
            .setResizable(true)
            .setHandle('#titleRow');

        this.window.onresize = () => {
            var pos01 = this.getCurrent01Pos();
            var pos = this.centerImage(pos01[0], pos01[1]);
            this.setImagePosition(pos[0], pos[1]);
        }

    }
}

AppletViewer.Initialize();