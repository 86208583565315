import Applet from './applet.js';
import AppletTickets from './appletTickets.js';
import AppletSendMail from './appletSendMail.js';
import AppletMailAccounts from './appletMailAccounts.js';
import Menus from './appletMenus';
import Widget from './widget.js';
import AppletTicketMini from './appletTicketMini.js';
import SettingsManager from './settingsManager.js';
import Foroyar from './foroyar.js';

export default class AppletMail extends Applet {
    static unique = false;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "email";
    static appname="mail";
    static help_page = "applet_mails";

    static content = `
    <div id='TicketWindow'>
        <div style="display:flex; flex-direction:row;" class="h-100">

            <div style="display:flex; flex-direction:column; min-width:450px; width:450px; background-color:white;" class="h-100">
                <div class="iflex">
                    <select id="mailAccountSelect" class="m-12 flex-grow"></select>
                    <button id="accountSettings" class="inline"><i class="icofont-gear"></i></button>
                    <button class="inline" id="btnRefresh"><i class="icofont-refresh"></i></button>
                </div>
                
                <div style="flex-grow:2;overflow-y:scroll;" id="mailListParent" class="hide-scrollbar">
                    <ul id="mailList" class="listbox mail-list">
                    </ul>
                </div>
                <div class="mail-toolbar toolbar">
                    <span class="m-auto p-2 badge badge-primary"><span class="mail-amount">5</span> mail(s)</span>
                    <button class="btn-move-mail spam"><i class="icofont-trash"></i>Spam</button>
                    <button class="btn-move-mail toolbar-end"><i class="icofont-archive"></i>Archiver</button>
                </div>
            </div>

            <div class="scrollbar-v h-100" target="#mailListParent" style="background-color:white;"></div>

            <div style="display:flex; flex-direction:column; flex-grow:2; overflow-x:hidden;position:relative;">
                

                <div class="form-container topless" style="margin-left:15px; margin-right:15px;">
                <h3>Destinataire</h3>
                <div class="inline-input"><label style="width:95px;">Expéditeur</label><input id="inputFrom" placeholder="" disabled /></div>
                <div class="inline-input"><label style="width:95px;">Destinataire</label><input id="inputTo" placeholder="" disabled /></div>
                <div class="inline-input"><label style="width:95px;">Cc</label><input id="inputCc" placeholder="" disabled /></div>
                <div class="inline-input"><label style="width:95px;">Objet</label><input id="inputSubject" placeholder="" disabled /></div>
                </div>
                <div class="flex toolbar row" >
                    <button id="btnTicket" disabled><i class="icofont-ticket"></i> Ticket</button>
                    <button id="btnQuickRe" class=""><i class="icofont-email"></i> Réponse Rapide</button>
                    <button id="btnArchiveMail" class=""><i class="icofont-ui-rate-add"></i> Nouveau ticket</button>
                    <button id="btnAddToTicket" class="toolbar-end"><i class="icofont-ticket"></i> Associer</i></button>
                </div>
                <div id="emailContainer" style="width:100%; overflow:auto; background-color:white; padding:0px; flex-grow:2;">
                    <div id="emailBody" class="mail-content flex-grow w-100 h-100" style="padding:12px;background-color:white;"></div>
                </div>
                <div id="emailAttachments" class="attachments d-flex flex-wrap">
                </div>
                <div class="load-panel d-none">
                    <div class="spinner">
                        <i class="icofont-spinner-alt-2"></i>
                    </div>
                </div>
                
            </div>


        </div>
	</div>
    `;

    loadPath() {
        
    }

    static FromPath(value) {
		var applet = super.New("Messagerie");
		applet.loadPath(value);
		return applet;
	}

    update_tags() {
        if (this.operators === undefined)
            return;

        this.window.find('.tag').each((index,tag) => {
            const uuid = $(tag).attr('uuid');
            if (uuid == "" || uuid == undefined)
                return

            if (uuid in this.operators) {
                const operator = this.operators[uuid];
                if (operator.nickname == "")
                    $(tag).html(operator.username);
                else
                $(tag).html(operator.nickname);

                var hue = operator.hue;

                $(tag).css('filter',`hue-rotate(${hue}deg)`);
            }
        });
    }

    

    showLoadingPanel(value) {
        if (value) {
            this.loading = true;
            this.window.find('.load-panel').addClass("show").removeClass("d-none");
        } else {
            this.loading = false;
            this.window.find('.load-panel').removeClass("show");
            setTimeout(() => {if (!this.loading) this.window.find('.load-panel').addClass("d-none"); }, 250);
        }
    }

    addMailToTicket(value,label) {
        var attachments = [];
        this.window.find(".attachments .mail-attachment").each((idx,elt)=> {
            attachments.push($(elt).attr("uuid"));
        });
        this.window.showYesNoModal(`Rattacher le mail au ticket suivant ? <b>${label}</b>`, 'Rattachement','ticket', () => {
            var uuid = this.window.find('#mailAccountSelect').val();
            $.post('/mail', {"action":"link","uuid":uuid, "uid":this.uid, "ticket":value}, (res) => {
                const response = res;
                if (response.status == 'OK') {
                    if(attachments.length > 0) {
                        this.window.showYesNoModal(`Des pièces-jointes sont présentes dans le mail, voulez-vous les rattacher au ticket?`, 'Rattachement','ticket', () => {
                            attachments.forEach((elt) => {
                                Foroyar.post("/ticket/attachment", {"uuid":value, "attachment":elt}, () => {}, (error, message) => {
                                    this.window.showAlert(message);
                                });
                            })
                        });
                    } else {
                        this.window.showAlert("Rattachement effectué.");
                    }
                } else {
                    this.window.showAlert(response.message);
                }

            });
        });
    }

    getDayShort(n) {
        var days = [
            "lun.",
            "mar.",
            "mer.",
            "jeu.",
            "ven.",
            "sam.",
            "dim."
        ];
        return days[n-1];
    }

    formatDate(timestamp) {

        var date = new Date(timestamp*1000);

        var d = new Date();
        var day = d.getDay();
        var diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        
        var first_day_of_week = new Date(d.setDate(diff));

        var time = `${String(date.getHours()).padStart(2,'0')}:${String(date.getMinutes()).padStart(2,"0")}`;

        if (date.getTime() >= first_day_of_week.getTime()) {
            if(date.getDay() == (new Date()).getDay())
                return `Aujourd'hui ${time}`;
            return `${this.getDayShort(date.getDay())} ${time}`;
        } else {
            return `${String(date.getDate()).padStart(2,'0')}/${String(date.getMonth()+1).padStart(2,"0")}/${date.getFullYear()} ${time}`;
        }
        
    }

    updateMailToolbar() {
        var amount=0;
        this.window.find("li.mail").each((idx, elt) => {
            if ($(elt).hasClass("selected") || $(elt).hasClass("selected-multiple"))
                amount++;
        });
        if (amount > 0) {
            this.window.find(".mail-toolbar").addClass("visible");
            this.window.find(".mail-amount").html(amount);
        } else {
            this.window.find(".mail-toolbar").removeClass("visible");
        }
    }


    constructor() {
        if (!super("Messagerie"))
            return;
            
        this.window
            .setSize(1500, 750)
            .setMinSize(750, 500)
            .setClosable()
            .setPinnable()
            .setResizable()
            .setExpandable()
            .setPadding(0)
            .center();

        Applet.FetchOperators((operators) => { this.operators = operators; this.update_tags()});

        this.busy=false;

        $.get('/mail', (res) => {
            const data = res;
            data.data.forEach((element) => {
                this.window.find('#mailAccountSelect').append(
                    `<option value="${element.uuid}">${element.name} (${element.address})</option>`
                );
                this.window.find('#btnRefresh').click();
            })
        })

        this.window.find("#btnAddToTicket").click((evt) => {
            Menus.showSearchPopup(this.window.window, $(evt.currentTarget), 'ticket','callback', (value,label) => this.addMailToTicket(value,label));
        });

        this.window.find(".btn-move-mail").click((evt) => {
            var account_uuid = this.window.find('#mailAccountSelect').val();
            var data = [];
            this.window.find("li.mail").each((idx, elt) => {
                if ($(elt).hasClass("selected") || $(elt).hasClass("selected-multiple"))
                    data.push($(elt).attr("uid"));
            });

            var path = "INBOX/Archives Foroyar"
            if($(evt.currentTarget).hasClass("spam"))
                path="INBOX/Spam";

            $.post('/mail/move', {data:JSON.stringify({mails:data, account_uuid:account_uuid, destination:path})}, (response) => {
                if (response.status == 'OK') {
                    this.window.showAlert("Archivage effectué.");
                    data.forEach((uid) => {
                        this.window.find(`[uid=${uid}]`).remove();
                    })
                    //this.window.find('#btnRefresh').click();
                } else {
                    this.window.showAlert(response.message);
                }
            });
        });
        

        this.window.find('#accountSettings').click((evt) => {
            const winSettings = new AppletMailAccounts();
        });

        this.window.find('#btnQuickRe').click((evt) => {
            var uuid = this.window.find('#mailAccountSelect').val();
            $.get('/mail', {"action":"archive","uuid":uuid, "uid":this.uid}, (res) => {
                const response = res;
                if (response.status == 'OK') {
                    //this.window.showAlert("Archivage effectué.");
                    AppletTicketMini.New(response.data);
                } else {
                    this.window.showAlert(response.message);
                    AppletTicketMini.New(response.data);
                }

            });
        });

        this.window.find('#btnArchiveMail').click((evt) => {
            var uuid = this.window.find('#mailAccountSelect').val();
            $.get('/mail', {"action":"archive","uuid":uuid, "uid":this.uid}, (res) => {
                const response = res;
                if (response.status == 'OK') {
                    //this.window.showAlert("Archivage effectué.");
                    AppletTickets.NewFromMail(response.data, this);
                } else {
                    this.window.showAlert(response.message);
                    AppletTickets.NewFromMail(response.data, this);
                }

            });
        });
        
        this.window.find('#btnRefresh').click((evt)=> {
            if (this.busy)
                return;

            this.busy = true;
            var uuid = this.window.find('#mailAccountSelect').val();
            this.window.find('#mailList').empty();
            $.get('/mail', {"action":"retrieve","uuid":uuid}, (res) => {
                this.busy = false;
                const response = res;
                if (response.status == 'OK') {
                    Object.keys(response.data[1]).reverse().forEach((key) => {
                        const mail = response.data[1][key];
                        var css_class = "mail";
                        if (mail.flags.includes("\\Seen"))
                            css_class += " seen";
                        var extra = "";
                        if (mail.ticket != undefined) {
                            extra = ' <i class="icofont-ticket grey"></i>'
                            mail.ticket.assignments.forEach((op) => {
                                if (op != "")
                                    extra += `<span class="tag condensed" uuid="${op}"></span>`;
                            });
                        }
                        const comment_regex = /<.*>/gs;
						var mail_from = mail.from.replace(comment_regex, "");
                        var datef = this.formatDate(mail.date);
                        this.window.find('#mailList').append(
                            `<li class="${css_class}" uid="${key}"><p style="padding-left:0px; margin-bottom:0px;"><span>${mail_from}</span>${extra}</p><p style="float:right;">${datef}</p><div style="height:22px; overflow:hidden;">${mail.subject}</div></li>`
                        );
                    })

                    this.update_tags();

                    this.window.find('.mail').click((evt) => {
                        this.window.find('#btnTicket').prop("disabled",true).off();
                        if(this.window.find(".mail.selected").length > 0) {
                            if (evt.ctrlKey) {
                                $(evt.currentTarget).toggleClass('selected-multiple');
                                this.updateMailToolbar();
                                return;
                            } else if (evt.shiftKey) {
                                this.window.find(".mail.selected-multiple").removeClass("selected-multiple");
                                if ($(evt.currentTarget).index() > this.window.find(".mail.selected").index()) {
                                    this.window.find(".mail.selected").nextUntil($(evt.currentTarget)).addClass("selected-multiple");
                                    $(evt.currentTarget).addClass("selected-multiple");
                                } else {
                                    $(evt.currentTarget).nextUntil(this.window.find(".mail.selected")).addClass("selected-multiple");
                                    $(evt.currentTarget).addClass("selected-multiple");
                                }
                                this.updateMailToolbar();
                                return;
                            }
                        }
                        this.window.find(".mail.selected-multiple").removeClass("selected-multiple");
                        this.window.onresize();
                        this.window.find('.selected').removeClass('selected');
                        $(evt.currentTarget).addClass('selected');
                        this.updateMailToolbar();

                        const uid = $(evt.currentTarget).attr('uid');
                        this.uid = uid;
                        this.showLoadingPanel(true);
                        $.get('/mail', {'action':'get', 'uuid':uuid, 'uid':uid}, (response) => {
                            this.showLoadingPanel(false);
                            if (response.status == 'OK') {
                                if (response.data.from != null)
                                    this.window.find('#inputFrom').val(response.data.from.join(', '));
                                else
                                    this.window.find('#inputFrom').val('');
                                if (response.data.to != null)
                                    this.window.find('#inputTo').val(response.data.to.join(', '));
                                else
                                    this.window.find('#inputTo').val('');
                                if (response.data.cc != null)
                                    this.window.find('#inputCc').val(response.data.cc.join(', '));
                                else
                                    this.window.find('#inputCc').val('');
                                if (response.data.subject != null)
                                    this.window.find('#inputSubject').val(response.data.subject);
                                else
                                    this.window.find('#inputSubject').val('');

                                if (response.data.content == "")
                                    this.window.find('#emailBody').html(response.data['content-text'].replaceAll('\n', "<br>"));
                                else
                                    this.window.find('#emailBody').html(response.data.content);

                                this.window.find('#emailBody a').attr("target","_blank");
                                this.window.find('#emailContainer').scrollTop(0)
                                
                                this.window.find('#emailBody').find('style').each((index, node) => { 
                                    node.remove();
                                });
                                this.window.find('#emailBody').find('img').each((index, img) => {
                                    const src = $(img).attr('src');
                                    if (src != undefined) {
                                        if (src.substr(0,4).toLowerCase() == 'cid:') {
                                            const cid = src.replace('cid:','').replace('CID:','');
                                            if (Object.keys(response.data.files).includes(cid))
                                                $(img).attr('src', `${SettingsManager.cdn}i/original/${response.data.files[cid].vault_doc}`)
                                                //$(img).attr('src', `data:${response.data.files[cid].content_type};base64, ${response.data.files[cid].content}`);
                                        }
                                    }
                                });

                                // Ticket associé
                                if(response.data.ticket != undefined) {
                                    this.window.find('#btnTicket').prop("disabled",false).click(() => { 
                                        Applet.LoadFromPath(`/ticket/${response.data.ticket.uuid}`) 
                                    });
                                }
                                this.window.find('#btnAddToTicket').prop("disabled",response.data.ticket != undefined)

                                // Traitement des pièces-jointes
                                this.window.find('#emailAttachments').empty();
                                Object.keys(response.data.files).forEach((cid) => {
                                    var attachment = response.data.files[cid];
                                    if (attachment.content_disposition == "attachment") {
                                        var node = $(`
                                        <div class="mail-attachment" uuid="${attachment.vault_doc}">
                                            <img src="/assets/img/mime/${attachment.content_type.replace("/","-")}.png" width=36 height=36>
                                            <span href="">${attachment.filename}</span>
                                            <!--<span>521 Ko</span>-->
                                        </div>`);
                                        node.click(() => {Applet.LoadFromPath(`/viewer/${attachment.vault_doc}`);});
                                        this.window.find('#emailAttachments').append(node);
                                    }
                                });

                                // Suggestion d'association à un ticket
                                if (response.data.ticket_suggestion.length > 0 && response.data.ticket == undefined)
                                    Widget.ShowInfoPopUp(
                                        `Ce mail peut-être associé au ticket <strong>${response.data.ticket_suggestion[1]}</strong>.`, 
                                        $('#emailContainer'), 
                                        'Associer', 
                                        () => {
                                            this.addMailToTicket(response.data.ticket_suggestion[0],response.data.ticket_suggestion[1])
                                        });
                                else
                                    Widget.HideInfoPopUp($('#emailContainer'));
                                
                            } else {
                                this.window.showAlert(response.message);
                            }
                        }).fail((response) => {
                            this.showLoadingPanel(false);
                            this.window.showAlert(response.responseJSON.message);
                        });
                    });
                } else {
                    this.window.showAlert(response.message);
                }
            });
        });
    }
}

AppletMail.Initialize();