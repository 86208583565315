import Applet from './applet.js';
import AppletTest from './appletTest.js';
import AppletVCard from './appletVCard.js';
import AppletPostIt from './appletPostIt.js';
import SettingsManager from './settingsManager.js';

export default class AppletSidePanel extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = false;
    static taskbarIcon = "people";
    static appname="sidepanel";

    static content = `
    <div id="WindowRoot">
    <div id="sidePanel" class="side-panel">
        <div class="side-panel-button-container" id="sidePanelButtonContainer">
            <div class="side-panel-button-container-spacer"></div>
            <div class="side-panel-button" id="sidePanelButton">
                <i id="sidePanelButtonContent" class="icofont-rounded-left"></i>
            </div>
            <div class="side-panel-button-container-spacer"></div>
        </div>
        <div class="side-panel-content-container" id="sidePanelContentContainer">
            <div class="side-panel-content" id="sidePanelContent">
                <h3>Options</h3>
                    <div class="flex row justify-content-center">
                        <button class="btn btn-sm btn-post-it" type="button" tooltip="Nouveau Post-It">
                            <i class="icofont-notepad" style="color:aliceblue;"></i>
                        </button>
                        
                        <button class="btn btn-sm btn-vcard" type="button" tooltip="Carte de visite">
                            <i class="icofont-id" style="color:aliceblue;"></i>
                        </button>
                        
                        <button class="btn btn-sm btn-toggle-fx" type="button" tooltip="Désactiver les effets graphiques">
                            <i class="icofont-magic" style="color:aliceblue;"></i>
                        </button>

                        <button class="btn btn-sm btn-toggle-gl" type="button" tooltip="Désactiver l'arrière-plan animé">
                            <i class="icofont-sail-boat" style="color:aliceblue;"></i>
                        </button>

                        <button class="btn btn-sm btn-toggle-navbar" type="button" tooltip="Cacher la barre de navigation">
                            <i class="icofont-navigation-menu" style="color:aliceblue;"></i>
                        </button>
                        
                        <button class="btn btn-sm btn-websocket" type="button" tooltip="Statut de la connexion Websocket" disabled>
                            <i class="icofont-bubble-down icon-websocket" style="color:rgb(183, 189, 194);font-size:x-large;"></i>
                        </button>

                        <button href='/logout' class="p-2 text-nowrap btn btn-sm btn-logout" tooltip="Deconnexion"> 
                            <i class="icofont-power" style="color:aliceblue;"></i>
                        </button>
                    </div>
                    
                <div class="notification" style="padding:0px;margin:-16px;transform:scale(.75);">
                    <div class="wcontainer vcard-container" style="height:210px;overflow-y:auto;"></div>
                </div>
                    
                <h3>Notifications</h3>
                <div class="notifications">
                    
                </div>
                <h3>Post-Its</h3>
                <div class="postit-panel kanban">
                        <div class="postit-container wcontainer" restrict="postit" style="overflow-y:clip;"></div>
                </div>
            </div>
        </div>
    </div>
    </div>
    `;

    loadPath() {
        
    }

    static FromPath(value) {
		var applet = super.New("SidePanel");
		applet.loadPath(value);
		return applet;
	}

    addPostIt(postit) {
        const node = $(`<div class="postit-container wcontainer" restrict="postit" style="overflow-y:clip;"></div>`);
        $('.postit-panel').prepend(node);
        postit.window.dockWindow(node);

        node.on("dock", (evt, win) => {
            win.application.setFolder("sidebar");
            this.updateEmptyContainers();
        });

        node.on("undock", (evt, win) => {
            win.application.setFolder("desktop");
            setTimeout(() => {
                $(evt.currentTarget).remove();
                this.updateEmptyContainers();
            },50);
        });
        
    }

    updateEmptyContainers() {
        this.window.find(".wcontainer:empty").remove();
        this.window.find(".postit-container").after($(`<div class="postit-container wcontainer" restrict="postit" style="overflow-y:clip;"></div>`));
        this.window.find('.postit-panel').prepend($(`<div class="postit-container wcontainer" restrict="postit" style="overflow-y:clip;"></div>`))

        this.window.find('.postit-container:empty').on("dock", (evt, win) => {
            win.application.setFolder("sidebar");
            this.updateEmptyContainers();
        });

        this.window.find('.postit-container:empty').on("undock", (evt, win) => {
            win.application.setFolder("desktop");
            setTimeout(() => {
                $(evt.currentTarget).remove();
                this.updateEmptyContainers();
            },50);
        });
    }

    LoadPostIts() {
        $.get('/autoform/postit/list',{folder:"sidebar"},(res) => {
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else {
                response.data.items.forEach((p) => {
                    const data = {
                        uuid : p.uuid,
                        content : p.title,
                        folder : "desktop",
                        options : JSON.parse(p.options.replaceAll("\\",""))
                    };
                    var postit = AppletPostIt.New(data);
                    this.addPostIt(postit);
                });
                this.updateEmptyContainers();
            }
        });
        
    }

    constructor() {
        if (!super("SidePanel"))
            return;
            
        this.window
            .setSize(0, 0)
            .setMinSize(0, 0)
            .setLite(true)
            .setPosition(0,0)
            .setClosable(true)
            .setPinnable()
            .setLayer(3)
            .setBorderless(true)
            .setResizable(false);

        this.window.window.css('height','100%').css('right','0px').css('left','unset');

        this.window.find(".btn-vcard").click(() => {
            var vcard = AppletVCard.New();
            vcard.window.center();
        })

        

        
        
        this.window.find(".btn-post-it").click(() => {
            var postit = AppletPostIt.New();
            postit.window.center();
        });

        this.window.find(".btn-logout").click(() => {
            location.href = "/logout";
        });

        this.window.find('.btn-toggle-fx').click(() => {
            if ($('main').hasClass('no-effects')) {
                SettingsManager.setValue("FX",true);
                $('main').removeClass('no-effects');
            }
            else {
                $('main').addClass('no-effects');
                SettingsManager.setValue("FX",false);
            }
        });

        this.window.find('.btn-toggle-gl').click(() => {
            if (SettingsManager.getValue("GL") != "true") {
                SettingsManager.setValue("GL",true);
                SettingsManager.startGL();
            }
            else {
                SettingsManager.setValue("GL",false);
                SettingsManager.SwitchToDesktopImg();
            }
        });

        this.window.find('.btn-toggle-navbar').click(() => {
            if ($('main').hasClass('hidden-navbar')) {
                $('main').removeClass('hidden-navbar');
                $('.navbar').css("display","flex");
            }
            else {
                $('main').addClass('hidden-navbar');
                $('.navbar').css("display","none");
            }
        });

        this.panelVisible = true;
        $('#sidePanelButton').click(() => {
            if (this.panelVisible) {
                this.window.find('#sidePanelContentContainer').css('width','0px');
                this.window.find('#sidePanelButtonContent').css('transform','rotate(0deg)');
            }
            else { 
                this.window.find('#sidePanelContentContainer').css('width','400px');
                this.window.find('#sidePanelButtonContent').css('transform','rotate(180deg)');
            }
            this.panelVisible = !this.panelVisible;
        });

        $('#sidePanelButton').click();

        var my_postit = AppletVCard.New();
        my_postit.window.dockWindow(this.window.find(".vcard-container"));

        $(".vcard-container").on("undock", () => {
            var vcard = AppletVCard.New();
            vcard.window.dockWindow(this.window.find(".vcard-container"));
        });

        this.LoadPostIts();

        /*WSConnection.subscribe('operators', (data) => {
            console.log(data);
            if (data.status == "online")
                this.window.find(`[uuid=${data.user}]`).addClass('online');
            else
                this.window.find(`[uuid=${data.user}]`).removeClass('online');
        })*/

    }
}

AppletSidePanel.Initialize();