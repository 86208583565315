import TaskBar from './taskBar.js'
import AppletWelcome from './appletWelcome.js'
import AppletLogin from './appletLogin.js'
import AppletVCard from './appletVCard.js'
import AppletLauncher from './appletLauncher.js'
import AppletPostIt from './appletPostIt.js'
import AppletSidePanel from './appletSidePanel.js';
import Applet from './applet.js'
import WSConnection from './websocket.js'
import Notification from './notifications.js'
import SettingsManager from './settingsManager.js'



if (mono_path != null) {
	$.get('/api/login', (res) => {
		const response = res;
		if (response.status == true) {
			Applet.user_uuid = response.user_uuid;
			AppletLogin.InitializeMonoApp(mono_path);
			WSConnection.on('statuschange', (status) => {
				if (status == 'ready')
					Notification.ConnectWS(response.user_uuid);
			});
			WSConnection.Initialize(response.session_id);
		} else {
			var appletLogin = AppletLogin.New(mono_path);
			appletLogin.window.setMonoApp();
		}
	});
} else {
	TaskBar.Initialize();

	SettingsManager.cdn = cdn_host;
	WSConnection.url = ws_host;
	WSConnection.session_id = ws_session_id;

	WSConnection.on('statuschange', (status) => {
		switch(status) {
			case "close":
				$('.icon-websocket').css('color','red');
				break;
			case "open":
				$('.icon-websocket').css('color','yellow');
				break;
			case "ready":
				$('.icon-websocket').css('color','lime');
				break;
			default:
				$('.icon-websocket').css('color','purple');
				break;
		}
	})

	$(document).on("mousedown", function (e1) {
		if(e1.which == 2)
			e1.preventDefault();
	});

	//AppletTest.startGL();

	$.get('/api/login', (res) => {
		const response = res;
		if (response.status == true) {
			Applet.user_uuid = response.user_uuid;
			var appletWelcome = AppletWelcome.New();
			var appletLauncher = AppletLauncher.New();
			var appletSidePanel = new AppletSidePanel();
			$('#navbar').css('opacity','100%');
			WSConnection.on('statuschange', (status) => {
				if (status == 'ready')
					Notification.ConnectWS(response.user_uuid);
			})
			WSConnection.Initialize(response.session_id);
			AppletPostIt.LoadPostIts();
		} else {
			var appletLogin = AppletLogin.New();
		}
	})

	SettingsManager.Initialize();

	$("#userVCard").click(() => {
		var vcard = AppletVCard.New();
		vcard.window.center();
	})

	$("#btnPostIt").click(() => {
		var postit = AppletPostIt.New();
		postit.window.center();
	});

	$('#toggleFX').click(() => {
		if ($('main').hasClass('no-effects')) {
			SettingsManager.setValue("FX",true);
			$('main').removeClass('no-effects');
		}
		else {
			$('main').addClass('no-effects');
			SettingsManager.setValue("FX",false);
		}
	});

	const bc = new BroadcastChannel("foroyar_link_handler");
	bc.onmessage = (evt) => {
		if (evt.data.type == "link") {
			//console.log(evt.data);
			Applet.LoadFromPath(evt.data.content);
			bc.postMessage({type:"ack", id:evt.data.id});
		}
	}

	var serviceWorker = null;

	if ('serviceWorker' in navigator) {
		window.addEventListener('load', function() {
		navigator.serviceWorker.register('/sw.js').then(function(registration) {
			// Registration was successful
			serviceWorker = registration.active;
			console.log('ServiceWorker registration successful with scope: ', registration.scope);
		}, function(err) {
			// registration failed :(
			console.log('ServiceWorker registration failed: ', err);
		});
		});
	}
}