//import ClassicEditor from '../npm/node_modules/@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor';
//import InlineEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
// ...

import Markdown from '@ckeditor/ckeditor5-markdown-gfm/src/markdown.js';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';

import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import Font from "@ckeditor/ckeditor5-font/src/font";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageInline from "@ckeditor/ckeditor5-image/src/imageinline";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageBlock from "@ckeditor/ckeditor5-image/src/imageblock";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import ToDoList from "@ckeditor/ckeditor5-list/src/todolist";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import RemoveFormatLinks from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
import Table from "@ckeditor/ckeditor5-table/src/table";
import Typing from "@ckeditor/ckeditor5-typing/src/typing";
import Undo from "@ckeditor/ckeditor5-undo/src/undo";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";

import Kblink from './kblinks';

export default function createEditorInline(window_id, element_id, callback, error_callback, with_markdown=false, with_kblink=false) {
    var config = {
        plugins: [
            Essentials,Bold,Italic,Paragraph,Alignment,Autoformat,BlockQuote,CodeBlock,Font,HorizontalLine,Image,
            Link,TableToolbar,TextTransformation,List,MediaEmbed,PasteFromOffice,RemoveFormat,RemoveFormatLinks,SourceEditing,Table, Typing,
            Undo, SimpleUploadAdapter, ImageResize, ImageToolbar, ImageCaption, ImageInline, ImageStyle, ImageBlock,
            Code, Strikethrough, Indent, Heading, Underline, ImageInsert, ImageUpload, ToDoList
        ],
        toolbar: {
            items: [
                'heading','|','removeFormat','fontfamily','fontsize','bold','italic','link','bulletedList','numberedList','strikethrough','underline','|','alignment','outdent','indent',
                '|','undo','redo','|','horizontalLine','imageUpload','imageInsert','blockQuote','insertTable','mediaEmbed','codeBlock'
            ],
            shouldNotGroupWhenFull:false
        },
        language: 'fr',
        image: {
            styles: [ 'full', 'alignLeft','alignRight' ],
            toolbar: [ 'imageTextAlternative','imageStyle:full','imageStyle:side' ]
        },
        table: {
            contentToolbar: [ 'tableColumn','tableRow','mergeTableCells','tableCellProperties','tableProperties']
        },
        simpleUpload: {
            uploadUrl: '/simple_upload',
            withCredentials: false,

            // Headers sent along with the XMLHttpRequest to the upload server.
            headers: {
                //'X-CSRF-TOKEN': 'CSRF-Token',
                //Authorization: 'Bearer <JSON Web Token>'
            }
        },
        licenseKey: '',
    };

    if (with_markdown)
        config.plugins.push(Markdown);
    if (with_kblink)
        config.plugins.push(Kblink);

    var orig = document;
    if (window_id != null)
        orig = document.querySelector('#' + window_id);

    InlineEditor.create( orig.querySelector(element_id), config)
		.then( editor => { callback(editor); })
        .catch(error => {error_callback(error)});
}