import createEditorInline from './ckeditor/ckutils.js';
import hljs from 'highlight.js';

import Applet from './applet.js';
import AutoForm from './autoForm.js';

export default class AppletKnowledgeBase extends Applet {
    static unique = false;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "library";
    static appname="kb";

    static content = `
    <div id="WindowAutoForm">
        <div class="flex row nowrap m-12 h-100">
            <div class="hr-container w-100">
                <div class="flex column h-100 flex-grow overflow-x-hidden overflow-y-auto" id ="listPanel" style="overflow-y:auto;">
                    <!--<input class="w-90 m-12" id="search" placeholder="Recherche...">
                    <h4>Résultats</h4>
                    <ul id="itemList" class="item-list flex-grow" style="overflow-y:auto; overflow-x:hidden;">

                    </ul>-->
                    <widget class="flex flex-grow" type="list" field="obj_list"></widget>
                    <div>
                        <button id="btnAdd" class="inline"><i class="icofont-plus"></i></button>
                        <button id="btnDelete" class="inline"><i class="icofont-minus"></i></button>
                    </div>
                    <button id='btnCancel' style="display:none;margin-right:58px; margin-bottom:24px;" class="">Annuler</button>
                </div>

                <div id="formPanel" class="flex-grow flex column overflow-y-auto" style="padding-left:12px; overflow-y:auto; overflow-x:hidden;">
                    <div class="flex row w-100" style="padding-left:18px;">
                        <input id="inputTitle" class="seamless flex-grow" style="font-size:xx-large; margin:auto;" value="" placeholder="Titre..." disabled></input>
                        <i class="icofont-pen grey" id="btnModify" style="font-size:xx-large"></i>
                        <i class="icofont-diskette grey" id="btnSave" style="display:none; font-size:xx-large"></i>
                    </div>
                    <div class="flex row w-100" style="padding-left:15px;">
                        <i class="icofont-link grey" style="margin-left:10px;"></i>
                        <input id="inputLink" class="seamless" style="margin-bottom:auto;display:inline-block;" value="" placeholder="Lien..." disabled></input>
                        <div class="flex-grow grey" style="text-align:right;"><a id="btnSource" style="display:none;" href="">source</a></div>
                    </div>
                    <textarea class="hide-scrollbar flex-grow" id="sourceContent" style="padding-left:12px; padding-bottom:120px;display:none;"></textarea>
                    <div class="hide-scrollbar flex-grow" id="pageContent" style="padding-left:12px; padding-bottom:120px;"></div>
                </div>
            </div>
        </div>
		
	</div>
    `;

    loadPath(value) {
        this.loadContent(value);
        this.list_window = null;
        this.ready = false;
        this.autoform = new AutoForm('kbpage');
        this.setupListWidget(value);
    }

    static FromPath(value="main_page") {
		var applet = super.New("Base de connaissances");
		applet.loadPath(value);
		return applet;
	}

    static New(name, link="main_page", minified=false) {
        var applet = super.New("Base de connaissances");
        applet.loadContent(name);
        applet.list_window = null;
        applet.ready = false;
        applet.autoform = new AutoForm('kbpage');
        applet.setupListWidget(link);

        applet.minified = minified;
        if (minified)
            applet.minify();
        return applet;
    }

    setupListWidget(link) {
        this.autoform.loadForm(() => { 
            this.ready = true; 
            this.loadPageByLink(link); 
            this.window.widgets['obj_list'].SetOptions(25);
            this.window.widgets['obj_list'].SetAutoform(this.autoform);
            this.window.widgets['obj_list'].SetElementClickedCallback((elt, uuid) => {
                this.loadContent(uuid);
            });
            this.window.widgets['obj_list'].updateList();
        });
    }

    loadPageByLink(link) {
        $.get('/kb/' + link, (res) => {
            const response = res;
            if (response.status == 'error') {
                if (response.error_code == "kbpage_not_found") {
                    this.clear();
                    this.window.find('#inputTitle').val(link.replace('_', ' '));
                    this.window.find('#inputLink').val(link);
                    this.contenteditor.setData("Créer une nouvelle page...");
                    this.window.setTitle('Créer une nouvelle page')
                } else {
                    this.window.showAlert(response.message);
                }
            } else {
                const data = response.data.data;
                this.current_uuid = data.uuid;
                this.window.find('#inputTitle').val(data.title);
                this.window.find('#inputLink').val(data.link);
                this.contenteditor.setData(data.content);
                this.setModifiable(false);
                this.processKBLinks(this.window.find('#pageContent'));
                this.window.setTitle(data.title)
            }
        })
    }

    minify() {
        this.window.setSize(600, 650)
        this.window.find('#listPanel').remove();
        this.window.find('.whandle').remove();
        this.window.find('#formPanel').attr('style','');
        this.minified = true;
    }

    loadContent(uuid) {
        if (!this.ready)
            return;

        console.log(uuid);

        this.setPath(uuid);

        this.autoform.loadItemByIndexValue(uuid, (title, uuid, data) => {
            this.current_uuid = uuid;
            this.window.find('#inputTitle').val(data.title);
            this.window.find('#inputLink').val(data.link);
            this.contenteditor.setData(data.content);
            this.setModifiable(false);
            this.processKBLinks(this.window.find('#pageContent'));
            this.toggleSourceView(false);
            this.window.setTitle(data.title);

            /*this.window.find('#pageContent').find('code').each((index,element) => {
                console.log(index,element);
                hljs.highlightElement(element);
            })*/
        })
    }

    processKBLinks(element) {
        element.find('.kblink')
            .off()
            .click((evt) => { this.loadPageByLink($(evt.currentTarget).attr('link'));})
            .on('auxclick', (evt) => {
                evt.preventDefault();
                AppletKnowledgeBase.New('', $(evt.currentTarget).attr('link'), true);
                
            });
    }

    toggleSourceView(value) {
        if (this.sourceView == value)
            return;
        this.sourceView = value;

        if (value) {
            this.window.find('#sourceContent').css('display','unset').val(this.contenteditor.getData());
            this.window.find('#pageContent').css('display','none');
        } else {
            this.window.find('#sourceContent').css('display','none');
            this.window.find('#pageContent').css('display','unset');
            this.contenteditor.setData(this.window.find('#sourceContent').val());
        }
    }

    updateList(search) {
        this.window.widgets['obj_list'].updateList(search);
    }

    setModifiable(value) {
        this.window.find('#inputTitle').prop('disabled', !value);
        this.window.find('#inputLink').prop('disabled', !value);
        this.contenteditor.isReadOnly = !value;

        if (value) {
            this.window.find('#btnSave').css('display', 'unset');
            this.window.find('#btnModify').css('display', 'none');
            this.window.find('#btnSource').css('display', 'unset');
        } else {
            this.window.find('#btnModify').css('display', 'unset');
            this.window.find('#btnSave').css('display', 'none');
            this.window.find('#btnSource').css('display', 'none');
        }
    }

    updatePage() {
        if (!this.ready)
            return;

        if (this.sourceView)
            this.contenteditor.setData(this.window.find('#sourceContent').val());

        var data = {
            uuid: "",
            title: this.window.find('#inputTitle').val(),
            link: this.window.find('#inputLink').val(),
            content: this.contenteditor.getData()
        }
        
        this.toggleSourceView(false);
        if (this.current_uuid != null)
            data['uuid'] = this.current_uuid;
        this.autoform.postData(data, () => { 
            this.setModifiable(false); 
            this.updateList(this.list.find('#search').val());
            this.loadPageByLink(data.link);
        });
        
    }

    loadEditor() {
        createEditorInline(this.window.uid, '#pageContent', 
            (editor) => {
                this.contenteditor = editor;
                this.contenteditor.isReadOnly = true;
                this.sourceView = false;
            },
            this.window.showAlert, true, true,
        );  
    }

    clear() {
        this.setModifiable(true);
        this.current_uuid = null;
        this.window.find('#inputTitle').val("Nouvelle page");
        this.window.find('#inputLink').val("new_page");
        this.contenteditor.setData("Tapez votre texte ici.");
    }

    constructor() {
        super("Base de connaissances");
            
        this.window
            .setSize(900, 650)
            .setMinSize(300, 250)
            .setPosition(135,75)
            .setClosable(true)
            .setPinnable()
            .setExpandable()
            .setResizable();

        this.list = this.window.find('#listPanel');

        this.window.find('#btnSave').click(() => { this.updatePage(); });
        this.window.find('#btnModify').click(() => { this.setModifiable(true); });

        this.window.find('#btnSource').click((evt) => { evt.preventDefault(); this.toggleSourceView(!this.sourceView); });

        this.window.find('#btnAdd').click(() => { this.clear(); });
        this.window.find('#btnDelete').click(() => { this.window.showAlert("Suppression des pages non implémenté pour l'instant parce que j'ai la flemme. Merci de passer par l'application <b>Base de données</b> pour faire ça.", true);});

        this.current_uuid = null;
        this.loadEditor();

    }
}

AppletKnowledgeBase.Initialize();