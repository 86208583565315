import Window from './window.js';
import Utils from './utils.js';
import SettingsManager from './settingsManager.js';

export default class Applet {
    static unique = false;
    static shownInTaskBar = false;
    static taskbarIcon = "file-exe";
    static appname = "root";

    static user_uuid = "";

    static instance = [];
    static content = "<div style='text-align:center;'>No content defined.</div>";
    static header_content = null;
    static titlebar_content = null;

    static help_page = "";

    static applets = {};

    static cdn = "https://localhost/i/";

    static Initialize() {
        Applet.applets[this.appname] = this;
    }

    static SetWallPaper(uuid) {
        const bg_url = `${SettingsManager.cdn}i/original/${uuid}`;
        $('#glCanvas').css('background-image',`url(${bg_url})`);
    }

    setPath(value) {
        this.path = value;
        if(this.window!=null && this.window !=undefined)
            this.window.setPath("/" + this.constructor.appname + "/" + value);
    }

    getPath() {
        return "/" + this.appname + "/" + this.path;
    }

    loadPath(value) {

    }

    close() {
        //this.constructor.instance = null;
        const index = this.constructor.instance.indexOf(this);
		this.constructor.instance.splice(index, 1);
        //TaskBar.RemoveApplet(this);
        delete this;
    }

    static FetchProjects(callback, software_only=false, clients_only=false) {
        var type = "project";
        if(software_only) type="software";
        if(clients_only) type="client";
        const req = $.get('/search', {type:type}, (response) => {
            if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
                var projects = {};
                response.data.forEach((proj) => {
                    projects[proj[1]] = proj[0]
                });
                callback(projects);
            }
        });
        return req;
    }

    static FetchOperators(callback) {
        
		const req = $.get('/operator/list', (response) => {
            var operators = {}
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				operators = response.data;
                Object.keys(operators).forEach((op) => {
                    var hue = 0;
                    if (operators[op].color != "") {
                        var rgb = operators[op].color.split(',');
                        operators[op].rgb = rgb;
                        for (var i=0; i<3;i++)
                            rgb[i] = parseInt(rgb[i]);
                        const min = Math.min(rgb[0], rgb[1], rgb[2]);
                        const max = Math.max(rgb[0], rgb[1], rgb[2]);
        
                        if (min == max) {
                            hue = 0;
                        } else {
                            if (max == parseInt(rgb[0])) {
                                hue = (rgb[1] - rgb[2]) / (max - min);
                            } else if (max == rgb[1]) {
                                hue = 2 + (rgb[2] - rgb[0]) / (max - min);
                            } else {
                                hue = 4 + (rgb[0] - rgb[1]) / (max - min);
                            }
        
                            hue = Math.round(hue*60);
                        }
                    }
                    operators[op].hue = hue;
                });
                callback(operators);
			}
		});
        return req;
    }

    openMediaLibrary(callback) {
        Utils.OpenMediaLibrary(this.window, callback);

    }

    fetchSelectOptions(field, field_type) {
        $.get('/search', {type:field_type, uuid:field.attr('value')}, (response) => {
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else {
                response.data.forEach((element) => {
                    field.append(`<option value="${element[1]}">${element[0]}</option>`);
                })
            }
        });
    }

    fetchListTitles(field, field_type) {
        Utils.fetchListTitles(field, field_type, this.window.showAlert);
    }


    fetchFieldTitle(field, field_type) {
        Utils.fetchFieldTitle(field, field_type, this.window.showAlert);
    }

    static FromPath(value) {
        const applet = New('Applet');
        return applet;
    }

    static LoadFromPath(path) {
        const spath = path.split('/');
        const new_applet = Applet.applets[spath[1]].FromPath(spath[2]);
        new_applet.setPath(spath[2]);
        //new_applet.loadPath(spath[2]);
        return new_applet;
    }

    static New(title, new_window=null, application=null) {
        var unique = this.unique;

        if (new_window != null)
            unique = !new_window;

        if (unique && this.instance.length > 0) {
            this.instance[0].window.setOnTop();
            return this.instance[0];
        }

        const applet = new this(title, application);

        return applet;
    }

    constructor(title, application=null) {
        this.window = new Window(Utils.generate_uid(), title, 100, 100, 100, 100, $(this.constructor.content), "", application == null ? this : application, this.constructor.header_content);

        this.setPath("");

        if (this.constructor.titlebar_content != null) {
            this.window.setTitleBarContent(this.constructor.titlebar_content)
        }

        if (this.constructor.help_page != "") {
            this.window.setHelpPage(this.constructor.help_page);
        }

        if (this.constructor.shownInTaskBar)
            this.window.showInTaskBar(this.constructor.taskbarIcon);

        this.constructor.instance.push(this);
        
        this.window.onclose = () => {this.close();};

    }


}