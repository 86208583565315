import Window from './window.js';
import Menus from './appletMenus.js';

import createEditorInline from './ckeditor/ckutils.js';
import Foroyar from './foroyar.js';

import SettingsManager from './settingsManager.js';

import Utils from './utils.js';
import Applet from './applet.js';

export default class AutoFormField {

    static FindFormClass(type, options) {
        const stype = type.split(':')[0];
        switch(stype) {
            case 'uuid':
            case 'integer':
            case 'string':
                if (options.includes('multiple'))
                    return AutoFormFieldStringMultiple;
                return AutoFormFieldString;
            case 'text':
                if (options.includes('html'))
                    return AutoFormFieldTextHtml;
                return AutoFormFieldText;
            case 'search':
                return AutoFormFieldSearch;
            case 'mixed':
                return WidgetMixedField;
            case 'image':
                if (options.includes('multiple'))
                    return AutoFormFieldImageMultiple;
                return AutoFormFieldImage;
            case 'color':
                return AutoFormFieldColor;
            case 'date':
                return AutoFormFieldDate;
            case 'boolean':
                return AutoFormFieldBoolean;
            case 'grid':
                return WidgetGrid;
            case 'list':
                return WidgetList;
            default:
                return null;
        }
    }

    constructor(field, label, type, options) {
        this.type = "none";
        this.label = label;
        this.field = field;
        this.type = type;
        this.disabled = false;
        this.options = options;
        this.icon = "tag";
        options.forEach((opt) => {
            if (opt.substr(0,2) == "i:")
                this.icon = opt.substr(2);
        });

        this.field_element = null;
        this.element = null;
    }

    generate() {
        this.generate_label();
        this.generate_field();

        this.element = $(`<div class="autoform_field autoform_${this.type}" id="${this.field}_panel"></div>`);
        if (this.label != undefined)
            this.element.append(this.label_element)
        this.element.append(this.field_element);

        if (this.options.includes('readonly'))
            this.disable(true);
        if (this.options.includes('invisible'))
            this.element.addClass('d-none');
    }

    appendTo(dest) {
        this.generate_label();
        this.generate_field();

        //this.element = $(`<div class="autoform_field autoform_${this.type}" id="${this.field}_panel"></div>`);
        //this.element.append(this.label_element).append(this.field_element);

        if (this.options.includes('readonly'))
            this.disable(true);
        //if (this.options.includes('invisible'))
        //    this.element.addClass('d-none');
        dest.append(this.label_element).append(this.field_element);
    }

    generate_label() {
        if (this.options.includes('nolabel'))
            return $('');
        if (this.options.includes('noicon'))
            this.label_element = $(`<label class="autoform_label">${this.label}</label>`)
        else
            this.label_element = $(`<i class="icofont-${this.icon} grey"></i><label class="autoform_label">${this.label}</label>`)
    }

    generate_field() {

    }

    empty() {
        this.field_element.attr('value','');
        this.field_element.val('');
    }

    disable(value) {
        this.field_element.prop('disabled', value)
        this.disabled = value;
        if (value)
            this.field_element.addClass('disabled');
        else
            this.field_element.removeClass('disabled');
    }

    val(value=undefined) {
        if (value == undefined)
            return this.read();
        return this.set(value);
    }

    set(value) {
        this.field_element.val(value);
    }

    read(value) {
        return this.field_element.val();
    }
}

class WidgetGrid extends AutoFormField {
    generate_field() {
        this.field_element = $(`
        <div class="widget-grid">
            <div class="widget-grid-table-container">
                <table class="widget-grid-table">
                    <thead class="widget-grid-header">
                        <tr class="widget-grid-header-tr"></tr>
                    </thead>
                    <tbody class="widget-grid-body"></tbody>
                </table>
            </div>
            <div class="widget-grid-pager">
                <button class="small inline btn-first"><i class="icofont-double-left"></i></button>
                <button class="small inline btn-prev"><i class="icofont-caret-left"></i></button>
                Page <span class="widget-grid-page">N/A</span> sur <span class="widget-grid-page-total">N/A</span>
                (<span class="widget-grid-from"></span>-<span class="widget-grid-to"></span> sur un total de <span class="widget-grid-total"></span>)
                <button class="small inline btn-next"><i class="icofont-caret-right"></i></button>
                <button class="small inline btn-last"><i class="icofont-double-right"></i></button>
            </div>
        </div>`);

        this.field_element.find('.btn-next').click((evt) => {
            this.page = Math.min(this.page+1,this.page_max);
            this.update_data();
        });
        this.field_element.find('.btn-prev').click((evt) => {
            this.page = Math.max(this.page-1,0);
            this.update_data();
        });
        this.field_element.find('.btn-first').click((evt) => {
            this.page = 0;
            this.update_data();
        });
        this.field_element.find('.btn-last').click((evt) => {
            this.page = this.page_max;
            this.update_data();
        });
    }

    setFilter(filter_array) {
        this.filter = filter_array;
        this.offset=0;
        this.page=0;
        this.update_data();
    }

    setFormatter(formatter=null) {
        this.formatter=formatter;
    }

    setColumns(columns) {
        this.columns = columns;
    }

    setColumnOptions(options) {
        this.options = options;
    }

    load_autoform(name) {
        this.offset=0;
        this.page=0;
        this.page_max=0;
        this.count = 0;
        this.options = {};

        this.filter = {};

        this.fields=null;
        this.formatter = null;

        this.order_by=null;
        this.direction="asc";
        this.items_per_page=20;
        this.name = name;

        Foroyar.get(`/autoform/${name}/get`, {}, (data) => {
            
            this.field_element.find('.widget-grid-body').empty();
            this.fields = data.list_fields;
            this.field_info = data.fields;
            if(this.columns == undefined)
                this.columns = this.fields;

            this.update_header();
            this.update_data();
        }, (error_code, error_message) => {
            console.log(error_code, error_message)
        })
    }

    set_spinner(value) {

    }

    update_header() {
        this.field_element.find('.widget-grid-header-tr').empty();
        this.columns.forEach((field) => {
            var node = $(`<th class="widget-grid-header-th" field="${field}"></th>`);
            if(Object.keys(this.field_info).includes(field))
                node.html(this.field_info[field][0]);
            if(Object.keys(this.options).includes(field)) {
                if (Object.keys(this.options[field]).includes("width"))
                    node.css('width', this.options[field]["width"]);
                if(Object.keys(this.options[field]).includes("title"))
                    node.html(this.options[field]["title"]);
            }

            
            this.field_element.find('.widget-grid-header-tr').append(node);
        });

        this.field_element.find('.widget-grid-header-th').click((evt) => {
            const f = $(evt.currentTarget).attr('field');
            if(this.order_by == f) {
                this.direction = (this.direction == 'asc') ? 'desc':'asc';
            }
            
            this.field_element.find('.widget-grid-header-th').removeClass('desc').removeClass('asc');
            $(evt.currentTarget).addClass(this.direction);

            this.order_by = f;
            this.update_data();
        });
    }

    update_data() {
        this.offset = this.page*this.items_per_page;
        var payload = {
            offset:this.page*this.items_per_page,
            amount:this.items_per_page
        };
        if (this.order_by != null) {
            payload.order_by = this.order_by;
            payload.direction = this.direction;
        }

        Object.keys(this.filter).forEach((k) => {
            payload[k] = this.filter[k]
        })

        this.set_spinner(true);

        Foroyar.get(`/autoform/${this.name}/list`, payload, (data) => {
            this.set_spinner(false);
            this.count = data.count;

            this.page_max = Math.floor(data.count/this.items_per_page);
            if(data.count % this.items_per_page == 0)
                this.page_max = Math.max(0,this.page_max-1);

            this.field_element.find('.widget-grid-body').empty();

            this.field_element.find('.widget-grid-total').html(data.count);
            this.field_element.find('.widget-grid-page').html(this.page+1);
            this.field_element.find('.widget-grid-page-total').html(this.page_max+1);

            this.field_element.find('.widget-grid-from').html(this.offset+1);
            this.field_element.find('.widget-grid-to').html(Math.min(data.count,this.offset+this.items_per_page));
            this.field_element.find('.widget-grid-total').html(this.count);

            this.items = [];

            data.items.forEach((item) => {
                var item_array = {}
                this.fields.forEach((field) => {
                    var content = item[field];
                    if(this.formatter != null && Object.keys(this.formatter).includes(field)) {
                        content = this.formatter[field](item);
                    }
                    item_array[field] = content;
                });
                item_array._data = item;
                this.items.push(item_array);
            });
            this.items.forEach((item) => {
                var row = $(`<tr class="widget-grid-body-tr" uuid="${item._data.uuid}"></tr>`);
                this.columns.forEach((col) => {
                    if(Object.keys(item).includes(col)) {
                        row.append(`<td class="widget-grid-body-td" field="${col}">${item[col]}</td>`);
                    } else if (this.formatter != null && Object.keys(this.formatter).includes(col)) {
                        row.append(`<td class="widget-grid-body-td" field="${col}">${this.formatter[col](item._data)}</td>`);
                    } else {
                        row.append(`<td class="widget-grid-body-td" field="${col}">ERROR</td>`);
                    }
                });
                this.field_element.find('.widget-grid-body').append(row);
            });
            this.element.trigger('update');

        }, (error_code, error_message) => {
            this.set_spinner(false);
            console.log(error_code,error_message);
        });
        
    }

}

class WidgetMixedField extends AutoFormField {
    generate_field() {
        this.disabled = false;
        this.search = this.type.split(":")[1];
        this.separators = ",;";
        this.forbidden = "\"'";

        this.field_element = $(`
        <div class="widget-mixed-field">
            <div class="mixed-input-container d-flex flex-wrap">
                <div class="mixed-content"></div>
                <input class="mixed-input flex-grow">
                <i class="mixed-input-search icofont-ui-search"></i>
            </div>
        </div>
        `);

        this.field_element.find('.mixed-input-search').click((evt) => {
            if(this.disabled) {
                return;
            }

            const win = Window.FindWindowFromElement($(evt.currentTarget))
            Menus.showSearchPopup(win, this.field_element.find('.mixed-input'), this.search,"callback", (value,label) => {
                this.add_reference_value(value,label);
            });
        });

        this.field_element.find(".mixed-input").keydown((evt) => {
            if(this.disabled)
                return;
            if(evt.keyCode == 8) {
                if($(evt.currentTarget).val() == "") {
                    this.remove_last_entry();
                    return;
                }
            }
            if(this.separators.includes(evt.key)) {
                evt.preventDefault();
                this.add_free_value($(evt.currentTarget).val());
                $(evt.currentTarget).val('');
            } else if (this.forbidden.includes(evt.key))
                evt.preventDefault;
        }).keyup((evt) => {
            if (this.forbidden.includes(evt.key))
                evt.preventDefault;
        }).focusout((evt) => {
            if(this.disabled)
                return;
            var val = $(evt.currentTarget).val();
            if (val != "") {
                this.add_free_value(val);
                $(evt.currentTarget).val('');
            }

        });
    }

    SetOptions(separators=",;", forbidden="\"'") {
        this.separators = separators;
        this.forbidden = forbidden;
    }

    remove_last_entry() {
        if(this.disabled)
                return;
        this.field_element.find(".mixed-entry").last().remove();
    }

    add_reference_value(uuid,label=null) {

        if(this.field_element.find(".mixed-content").find(`[uuid=${uuid}]`).length == 0) {
            var node = $(`<div class="mixed-entry mixed-reference" uuid="${uuid}"><span class="mixed-entry-content">${label == null ? "Chargement..." : label}</span><i class="d-inline-block mixed-reference-delete icofont-close"></i></div>`);
            this.field_element.find(".mixed-content").append(node);
            node.find(".mixed-reference-delete").click(() => {
                node.remove();
            })

            if (label == null) {
                Foroyar.get(`/search/${this.search}`, {"uuid":uuid}, (response) => {
                    node.find(".mixed-entry-content").html(response[0].label);
                })
            }
        }
    }

    add_free_value(value) {
        value = value.replaceAll("\"","").trim();

        if (value == "") {
            this.field_element.find(".mixed-input").val("");
            return;
        }

        if(this.field_element.find(".mixed-content").find(`[value="${value}"]`).length == 0) {
            var node = $(`<div class="mixed-entry mixed-value" value="${value}">${Utils.escapeHtml(value)}<i class="d-inline-block mixed-value-delete icofont-close"></i></div>`);
            this.field_element.find(".mixed-content").append(node);
            node.find(".mixed-value-delete").click(() => {
                node.remove();
            });
        }
    }

    empty() {
        this.field_element.find(".mixed-content").empty();
        this.field_element.find(".mixed-input").val("");
    }

    set(value) {
        this.empty();
        value.forEach((row) => {
            switch(row.split(":")[0]) {
                case "text":
                    this.add_free_value(row.split(":")[1])
                    break;
                case "uuid":
                    this.add_reference_value(row.split(":")[1],null);
                    break;
                default:
                    var new_val = row.replaceAll("\"","").trim();
                    if (new_val != "")
                    this.add_free_value(new_val);
                    break;
            }
        })
    }

    read() {
        var res = [];
        this.field_element.find(".mixed-entry").each((idx,elt) => {
            if ($(elt).hasClass("mixed-reference"))
                res.push(`uuid:${$(elt).attr("uuid")}`);
            else if ($(elt).hasClass("mixed-value"))
                res.push(`text:${$(elt).attr("value")}`);
        });
        return res;
    }

    disable(value) {
        if(value)
            this.field_element.addClass("disabled");
        else 
            this.field_element.removeClass("disabled");
        this.disabled = value;
    }

}

class WidgetList extends AutoFormField {
    generate_field() {
        this.field_element = $(`
        <div class="widget-list">
            <div class="widget-list-container">
                <input class="w-90 m-12" id="search" autocomplete="off" placeholder="Recherche...">
                <h4>Résultats</h4>
                <ul id="itemList" class="item-list h-100 flex-grow" style="overflow-y:auto; overflow-x:hidden;">

                </ul>
            </div>
            <div class="widget-list-pager small">
                <button class="small inline btn-prev"><i class="icofont-caret-left" disabled></i></button>
                Page <span class="widget-list-page">N/A</span> sur <span class="widget-list-page-total">N/A</span>
                (<span class="widget-list-from"></span>-<span class="widget-list-to"></span> total <span class="widget-list-total"></span>)
                <button class="small inline btn-next"><i class="icofont-caret-right"></i></button>
            </div>
        </div>`);

        this.field_element.find('#search').keyup(() => {
            this.updateList(this.field_element.find('#search').val());
        });

        this.field_element.find(".btn-next").click(() => {
            this.UpdatePageNumber(this.page+1);
        });

        this.field_element.find(".btn-prev").click(() => {
            this.UpdatePageNumber(this.page-1);
        });



    }

    UpdatePageButtons(page) {
        this.field_element.find(".btn-prev").prop("disabled",false);
        this.field_element.find(".btn-next").prop("disabled",false);

        if (page <= 0)
            this.field_element.find(".btn-prev").prop("disabled",true);

        if (page+1 >= Math.ceil(this.total/this.amount))
            this.field_element.find(".btn-next").prop("disabled",true);
    }

    UpdatePageNumber(page=null) {
        var npage = this.page;
        if (page != null)
            npage = Math.max(0, Math.min(page, Math.ceil(this.total/this.amount)));

        this.UpdatePageButtons(npage);

        this.page = npage;
        this.updateList(this.field_element.find("#search").val())
    }

    SetAutoform(autoform) {
        this.autoform = autoform;
    }

    SetOptions(amount=50) {
        this.amount = amount;
        this.page = 0;
        this.total=0;
    }

    SetElementClickedCallback(callback) {
        this.click_callback = callback;
    }

    updateList(search="") {
        this.autoform.loadList(search, (count, items) => {
            this.field_element.find('#itemList').empty();
            items.forEach((element) => {
                this.field_element.find('#itemList').append(
                    `<li class="item" uuid="${element.uuid}">${element.title}</li>`
                );
            });
            this.field_element.find('#itemList').find('li').click((evt) => {
                this.selected_uuid = $(evt.currentTarget).attr('uuid');
                this.field_element.find('#itemList').find('li').removeClass("selected");
                $(evt.currentTarget).addClass("selected");
                this.click_callback($(evt.currentTarget), $(evt.currentTarget).attr('uuid'));
            });

            this.field_element.find(".widget-list-page").html(this.page+1)
            this.field_element.find(".widget-list-page-total").html(Math.ceil(count/this.amount))
            this.field_element.find(".widget-list-from").html(this.page*this.amount+1)
            this.field_element.find(".widget-list-to").html(Math.min(count,(this.page+1)*this.amount))
            this.field_element.find(".widget-list-total").html(count);

            this.total = count;
            this.UpdatePageButtons(this.page);

        }, this.page, this.amount);
    }
}

class AutoFormFieldText extends AutoFormField {
    generate_field() {
        this.field_element = $(`<textarea rows=4 field="${this.field}"></textarea>`)
    }

}

class AutoFormFieldTextHtml extends AutoFormField {
    generate_field() {
        var height = 550;
        this.options.forEach((opt) => {
            if (opt.substr(0,2) == "h:")
                height = opt.substr(2);
        });
        this.uid = Utils.generate_uid("text-editor-", 8);
        this.field_element = $(`<div id="${this.uid}" class="text-editor" style="height:${height}px; width:100%; margin:5px; overflow:scroll;"></div>`);
        this.created = false;
     
    }

    empty() {
        this.set("");
    }

    disable(value) {
        if (this.contenteditor != undefined)
            this.contenteditor.isReadOnly = value;
        else
            setTimeout(() => { this.disable(value); }, 200);
    }

    set(value) {
        value = value.replaceAll('vid:', `${SettingsManager.cdn}orig/`);

        if (!this.created) {
            createEditorInline(null, `#${this.uid}`, 
                (editor) => {
                    this.contenteditor = editor;
                    this.contenteditor.setData(value);
                    this.created = true;
                },
            );  
        } else {
            this.contenteditor.setData(value);
        }
        
    }

    read() {
        var value = this.contenteditor.getData();
        value = value.replaceAll(`${SettingsManager.cdn}orig/`, 'vid:');
        return value;
    }


}

class AutoFormFieldString extends AutoFormField {
    generate_field() {
        this.field_element = $(`<input field="${this.field}">`)

        if (this.options.includes('password'))
            this.field_element.attr('type',"password");
            
    }

}

class AutoFormFieldStringMultiple extends AutoFormField {
    add_input(value="") {
        const input = $('<li class="flex row"><i class="icofont-close" style="color:red;"></i><input class="flex-grow" placeholder="..."></li>')
        input.find('i').click(() => {
            if (!this.disabled)
                input.remove();
        });
        input.find('input').val(value);
        this.field_element.find(`[field=${this.field}]`).append(input);
    }


    generate_field() {
        this.field_element = $(`
        <div class="flex row">
            <button class="inline" style="height:43px;" field="${this.field}_add"><i class="icofont-plus"></i></button>
            <ul class="input-list flex-grow" field="${this.field}"></ul>
        </div>`);
        this.field_element.find('button').click((evt) => {
            this.add_input();
        })
    }

    disable(value) {
        this.disabled = value;
        this.field_element.find('button').prop('disabled', value);  
        this.field_element.find('input').prop('disabled', value)
        if (value) {
            this.field_element.find('.input-list').find('i').css('display', 'none');
            this.field_element.find('button').css('display','none');
            this.field_element.addClass('disabled');
        } else {
            this.field_element.find('.input-list').find('i').css('display', 'unset');
            this.field_element.find('button').css('display','unset');
            this.field_element.removeClass('disabled');
        }
    }

    read() {
        var val = [];
        this.field_element.find('input').each((i,element) => {
            if ($(element).val() != "")
                val.push($(element).val());
        })
        return val;
    }

    set(value) {
        this.field_element.find(`[field=${this.field}]`).empty();
        value.forEach((val) => {
            if (val != "")
                this.add_input(val);
        })
    }

    empty() {
        this.field_element.find(`[field=${this.field}]`).empty();
    }
}

class AutoFormFieldSearch extends AutoFormField {
    generate_field() {
        this.multiple = this.options.includes('multiple');
        this.search = this.type.substr(7);

        if (this.multiple) {
            this.field_element = $(`
            <div class="flex row">
                <button class="inline" style="height:43px;" field="${this.field}_add"><i class="icofont-plus"></i></button>
                <ul class="item-list h-100 flex-grow" field="${this.field}" value=""></ul>
            </div>`);
            this.field_element.find('button').click((evt) => {
                const win = Window.FindWindowFromElement($(evt.currentTarget))
                Menus.showSearchPopup(win, this.field_element.find('ul'), this.search, 'list');
            });

        } else {
            this.field_element = $(`<div><input value="" field="${this.field}"><i class="icofont-delete grey" field="${this.field}_delete"></i></div>`);

            this.field_element.find('input').on('focus', (evt) => {
                const win = Window.FindWindowFromElement($(evt.currentTarget))
                Menus.showSearchPopup(win, $(evt.currentTarget), this.search);
            });

            this.field_element.find(`i`).click((evt) => {
                if (!this.field_element.hasClass('disabled'))
                    this.empty();
            });
        }

    }

    disable(value) {
        if (this.multiple) {
            this.field_element.find('button').prop('disabled', value)
        } else {
            this.field_element.find('input').prop('disabled', value)
        }

        this.disabled = value;
        if (value) {
            this.field_element.addClass('disabled');
            this.field_element.find('ul').addClass('disabled');
            this.field_element.find('button').css('display','none');
        } else {
            this.field_element.removeClass('disabled');
            this.field_element.find('ul').removeClass('disabled');
            this.field_element.find('button').css('display','unset');
        }
    }

    read() {
        if (this.multiple) {
            var res = [];
            this.field_element.find('ul').attr('value').split(',').forEach((val) => {
                if (val != "")
                    res.push(val);
            });
            return res;
        } else {
            return this.field_element.find('input').attr('value');
        }
    }

    set(value) {
        if (this.multiple) {
            this.field_element.find('ul').empty().attr('value','');
            value.forEach((val) => {
                if (val == "")
                    return;
                const line = $(`<li class='item-removable' value='${val}'></li>`);
                line.click((evt) => {
                    if (!this.disabled) {
                        const uuid = line.attr('value');
                        this.field_element.find('ul').attr('value', this.field_element.find('ul').attr('value').replace(uuid, '').replace(',,',','));
                        line.remove();
                    }
                })
                this.field_element.find('ul').append(line).attr('value', value.join(','));
            });
            Utils.fetchListTitles(this.field_element.find('ul'), this.search);
        } else {
            this.field_element.find('input').attr('value',value);
            Utils.fetchFieldTitle(this.field_element.find('input'), this.search);
        }
    }

    empty() {
        if (this.multiple) {
            this.field_element.find('ul').empty().attr('value','');
        } else {
            this.field_element.find('input').attr('value','').val('');
        }
    }
}

class AutoFormFieldDate extends AutoFormField {
    generate_field() {
        this.field_element = $(`<input field="${this.field}" value="">`);            
    }

    read() {
        return this.field_element.attr('value');
    }

    set(value) {
        const date = new Date(parseFloat(value)*1000);
        var formatted_date = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
        this.field_element.attr('value',value).val(formatted_date);

    }

    empty() {
        this.field_element.attr('value','').val('');
    }

}

class AutoFormFieldBoolean extends AutoFormField {
    generate_field() {
        this.field_element = $(`<div class="switch" field="${this.field}"></div>`);
        this.field_element.click((evt) => {
            if (!$(evt.currentTarget).hasClass('disabled'))
                $(evt.currentTarget).toggleClass('off');
        });
    }

    set(value) {
        if (value)
            this.field_element.removeClass('off');
        else
            this.field_element.addClass('off');
    }

    read() {
        return !this.field_element.hasClass('off')
    }

    empty() {
        this.set(false);
    }

}

class AutoFormFieldImageMultiple extends AutoFormField {
    add_image(uuid) {
        if (this.values.includes(uuid))
            return;
        this.image_list.append($(`
            <profile-img field="${this.field}" uuid="${uuid}" style="display:inline-block; background-image: url(${SettingsManager.cdn}i/small/${uuid});">
                <button field="${this.field}_delete" class="inline small solid">
                    <i class="icofont-close"></i>
                </button>
            </profile-img>`));
        this.image_list.find(`[uuid=${uuid}]`).click(() => {
            if (this.disabled) {
                Applet.LoadFromPath(`/viewer/${uuid}`);
            }
        })
        this.image_list.find(`[uuid=${uuid}]`).find('button').click(() => {
            this.remove_image(uuid);
        });
        this.values.push(uuid);
    }

    remove_image(uuid) {
        if (!this.values.includes(uuid))
            return;
        this.image_list.find(`[uuid=${uuid}]`).remove();
        this.values.splice(this.values.indexOf(uuid),1);
    }

    generate_field() {
        this.type = "image_multiple";
        this.field_element =$(
            `<div>
                <div class="image-list">
                </div>
                <button class="inline"><i class="icofont-add"></i>Ajouter</button>
            </div>
            `
        )
        this.image_list = this.field_element.find('.image-list');
        this.values = [];
        this.button = this.field_element.find('button');
        this.button.click((evt) => {
            const win = Window.FindWindowFromElement($(evt.currentTarget))
            Utils.OpenMediaLibrary(win, (uuid) => {
                if (uuid != null) {
                    this.add_image(uuid);
                }
            });
        });
    }

    read() {
        return this.values;
    }

    set(value) {
        this.empty();
        value.forEach((val) => { if(val != "") {this.add_image(val); }});
    }

    empty() {
        this.image_list.empty();
        this.values = [];
    }

    disable(value) {
        this.disabled = value;
        if (value) {
            this.image_list.find('button').css('display','none');
            this.button.css('display','none');
        } else {
            this.image_list.find('button').css('display','unset');
            this.button.css('display','unset');
        }
    }
}

class AutoFormFieldImage extends AutoFormField {
    generate_field() {
        this.field_element = $(
            `<profile-img field="${this.field}" uuid="">
                <button field="${this.field}_delete" class="inline small solid">
                    <i class="icofont-close"></i>
                </button>
            </profile-img>`);
        this.field_element.click((evt) => {
            if ($(evt.currentTarget).prop('disabled')) {
                Applet.LoadFromPath(`/viewer/${$(evt.currentTarget).attr("uuid")}`);
            } else {
                const win = Window.FindWindowFromElement($(evt.currentTarget))
                Utils.OpenMediaLibrary(win, (uuid) => {
                    if (uuid != null) {
                        $(evt.currentTarget).css('background-image', `url(${SettingsManager.cdn}i/small/${uuid})`).attr('uuid',uuid);
                    }
                });
            }
        });
        this.field_element.find(`[field=${this.field}_delete]`).click((evt) => {
            evt.preventDefault();
            if (!this.disabled) {
                this.empty();
            }
        });
    }

    read() {
        return this.field_element.attr('uuid');
    }

    set(value) {
        if (value == "")
            this.field_element.attr('uuid',value).css('background-image', '');
        else
            this.field_element.attr('uuid',value).css('background-image', `url(${SettingsManager.cdn}i/small/${value})`);
    }

    empty() {
        this.set('');
    }
}

class AutoFormFieldColor extends AutoFormField {
    generate_field() {
        this.field_element = $(`<button class="inline color-container" color="" style="color: rgb(255, 255, 255);" field="${this.field}">
        <i style="font-size:xx-large; text-shadow: 0px 1px 2px #00000038;" class="icofont-paint"></i>
        </button>`);
        this.field_element.click((evt) => {
            if ($(evt.currentTarget).prop('disabled'))
                return;

            const colorButton = $(evt.currentTarget);
            Menus.showColorPickerPopup(Window.FindWindowFromElement($(evt.currentTarget)), $(evt.currentTarget), (col) => {
                colorButton
                    .css('color', `rgb(${col[0]} ${col[1]} ${col[2]})`)
                    .attr('color', `${col[0]},${col[1]},${col[2]}`);
            });
        });
    }

    read() {
        return this.field_element.attr('color');
    }

    set(value) {
        this.field_element.css('color', `rgb(${value})`).attr('color',value);
    }

    empty() {
        this.set('255,255,255');
    }
}