import Applet from './applet.js';

export default class AppletPostIt extends Applet {
    static unique = false;
    static instance = [];
    static shownInTaskBar = false;
    static taskbarIcon = "tack-pin";
    static appname="post-it";

    static content = `
    <div id="WindowPostIt">
        <div class="flex row" style="margin-left:5px; margin-right:5px">
		    <div class="flex-grow" style="margin:12px;margin-bottom:0px;user-select:none;height:12px;background-color:rgba(0,0,0,0.05); cursor:grab;" id="postItHandle"></div>
            
        </div>
        <textarea class="flex row m-12 post-it-content" placeholder"Insérez votre texte..." disabled></textarea>
		<div class="post-it-toolbar" style="height:20px; padding-right:5px; padding-bottom:5px; text-align:right; width:100%;">
            <i class="icofont-tack-pin lock-hide" style="font-size:large;" id="pinButton"></i>
            <i class="icofont-trash lock-hide" style="font-size:large;" id="closeButton"></i>
            <i class="icofont-resize lock-hide" style="font-size:large;" id="resizeButton"></i>
        </div>
	</div>
    `;

    loadPostItFromData(content, options) {
        this.window.find('textarea').val(content);
        this.hue = options.hue;
        this.rotation = options.rotation;
        this.window.window.css("transform", `rotate(${options.rotation}deg)`);
        this.window.window.find('.content').attr('style',`padding:0px;display:flex; flex-direction:column;backdrop-filter:hue-rotate(${options.hue}deg);`);
        this.window.setSize(options.size[0], options.size[1]);
        this.window.setPosition(options.position[0], options.position[1]);
        this.window.setPinned(options.pinned);
        this.window.find("textarea").prop("disabled",true); 
        this.window.show(true);
    }

    static NewFromUuid(uuid="") {
        var applet = super.New("Post-It");
        applet.window.show(uuid == "")
        applet.uuid = uuid;

        if (uuid != "") {
            applet.window.window.attr("uuid",uuid);
            $.get('/autoform/postit/load', {uuid:uuid}, (res) => {
                const response = res;
                if (response.status != 'OK') {
                    applet.window.showAlert(response.message);
                } else {
                    applet.folder = response.data.data.folder;
                    const options = JSON.parse(response.data.data.options.replaceAll("\\",""));
                    applet.loadPostItFromData(response.data.data.content, options);
                }
            });
        }
    }

    static New(data=null, folder="desktop") {
		var applet = super.New("Post-It");
        //applet.window.show(false);
        applet.folder = folder;
        applet.uuid = "";

        if (data != null) {
            applet.uuid = data.uuid;
            applet.folder = data.folder;
            applet.loadPostItFromData(data.content, data.options);
        } else {
            applet.window.find("textarea").prop("disabled",false).focus(); 
        }

        return applet;
	}

    static LoadPostIts() {
        $.get('/autoform/postit/list',{folder:"desktop"},(res) => {
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else {
                response.data.items.forEach((p) => {
                    const data = {
                        uuid : p.uuid,
                        content : p.title,
                        folder : "desktop",
                        options : JSON.parse(p.options.replaceAll("\\",""))
                    };
                    AppletPostIt.New(data);
                }) ;
            }
        })
    }

    loadPath(value) {
        
    }

    static FromPath(value) {
		var applet = super.New("Post-It");
		applet.loadPath(value);
    }

    delete() {
        if (this.uuid == "")
            return;

        $.post('/autoform/postit/delete', {uuid:this.uuid}, (res) => {

        });
    }

    setFolder(folder) {
        this.folder = folder;
        this.schedule_update();
    }

    update() {
        var options =  {
            position : this.window.position,
            size : this.window.size,
            pinned : this.window.pinned,
            rotation : this.rotation,
            hue : this.hue
        };

        var data = {
            content : this.window.find("textarea").val(),
            folder : this.folder,
            uuid : this.uuid,
            options : JSON.stringify(options)
        };

        $.post('/autoform/postit/update', {data:JSON.stringify(data)}, (res) => {
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else {
                if (this.uuid == "")
                    this.uuid = response.data;
            }
            this.update_scheduled = false;
        })
    }

    schedule_update() {
        if (!this.update_scheduled) {
            setTimeout(() => {this.update()}, 5000);
            this.update_scheduled = true;
        }
    }

    constructor() {
        super("Post-It");

        this.folder="";
        this.uuid="";

        this.update_scheduled = false;
            
        this.window
            .setSize(380, 210)
            .setMinSize(120,60)
            .setBorderless()
            .setPadding(0)
            .setHandle('#postItHandle')
            .setResizeHandle('#resizeButton')
            .setOnTop()
            .setClass("postit")

        this.window.window.addClass("post-it");

        this.window.find("#closeButton").click(() => {
            if (this.window.find('textarea').val().length > 0) {
                this.window.showYesNoModal("Êtes-vous sûr de vouloir supprimer ce post-it ?","Attention","warning", () => {this.window.close(); this.delete();});
            } else {
                this.window.close();
            }
        
        });
        this.window.find("#pinButton").click(() => { this.window.togglePin(); });
        this.window.content.dblclick(() => {
            this.window.find('textarea').prop("disabled", false).focus();
        })
        this.rotation = Math.random()*6-3;
        this.window.window.css("transform", `rotate(${this.rotation}deg)`);
        this.hue = Math.round(Math.random()*360);
        this.window.window.find('.content').attr('style',`padding:0px;display:flex; flex-direction:column;backdrop-filter:hue-rotate(${this.hue}deg);`);
        this.window.find('textarea').focus();

        this.window.find('textarea').on('keydown', (evt) => {this.schedule_update();});
        this.window.find('textarea').on('paste', (evt) => {this.schedule_update();});

        this.window.onresized = () => {  this.schedule_update();  }
        this.window.onmoved = () => { this.schedule_update(); }

    }
}

AppletPostIt.Initialize();