import Foroyar from './foroyar.js';

export default class Utils {

    static generate_uid(prefix="window_", uid_length=8) {
        const charArray = ["0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f"];
        var uid = prefix;
        for (let i =0; i<uid_length; i++) {
            uid += charArray[Math.floor(Math.random() * charArray.length)];
        }
        return uid;
    }
    
    static escapeHtml(str) {
        return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
    }

    static fetchFieldTitle(field, field_type, error_callback=null) {
        if (field.attr('value') == '') {
            field.val('').html('');
            return
        } else {
            $.get('/search', {type:field_type, uuid:field.attr('value')}, (res) => {
                const response = res;
                if (response.status != 'OK') {
                    if (error_callback == null)
                        console.log(response.message)
                    else
                        error_callback(response.message);
                } else {
                    if (response.data[0][1] == field.attr('value')) {
                        field.val(response.data[0][0]).html(response.data[0][0]);
                    }
                }
            });
        }
         
    }

    static formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
      
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
      
        const i = Math.floor(Math.log(bytes) / Math.log(k));
      
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    static generateMailTag(text) {
        var node = $(`<div class="mail-tag loading"></div>`);
        if(text.includes(":")) {
            switch(text.split(":")[0]) {
                case "text":
                    node.html(text.split(":")[1])
                    node.addClass("text");
                    break;
                case "uuid":
                case "vcard":
                    var uuid = text.split(":")[1];
                    node.attr("uuid", uuid);
                    node.html("Chargement...");
                    node.addClass("uuid");
                    break;
            }
        } else {
            node.html(text);
            node.addClass("text");
        }
        return node;
    }

    static lookUpMailTags(root, cache={}) {
        var lookup_uuids=[];
        var tag_uuids = [];
        root.find(".mail-tag.loading").each((idx, elt) => {
            var uuid = $(elt).attr("uuid")
            if (uuid != undefined) {
                tag_uuids.push(uuid);
                if(!Object.keys(cache).includes(uuid))
                    lookup_uuids.push($(elt).attr("uuid"));

                $(elt).click((evt) => {
                    import('./appletVCard.js').then(module => {
                        const app = module.default.FromPath(uuid);
                        app.window.setPosition(evt.pageX, evt.pageY);
                    });
                })
            }
        });
        if(tag_uuids.length == 0)
            return;
        Foroyar.post('/search/contact', {uuid:lookup_uuids.join(",")},  (response) => {
            response.forEach((res) => {
                cache[res.value] = res.label;
            });
            tag_uuids.forEach((uuid) => {
                root.find(`.mail-tag.loading[uuid=${uuid}]`).each((idx,elt) => {
                    $(elt).removeClass("loading");
                    $(elt).html(cache[uuid]);
                });
            })
        })
    }

    static fetchListTitles(field, field_type, error_callback=null) {
        var values = [];
        field.find('li').each((i,element) => {
            values.push($(element).attr('value'));
        });

        if (values.length = 0)
            return;

        $.get('/search', {type:field_type, uuid:values.join(',')}, (response) => {
            if (response.status != 'OK') {
                if (error_callback == null)
                    console.log(response.message)
                else
                    error_callback(response.message);
            } else {
                field.find('li').each((i,element) => {
                    const uuid = $(element).attr('value');
                    response.data.forEach((tuple) => {
                        if (tuple[1] == uuid)
                            $(element).html(tuple[0]);
                    });
                    if($(element).html() == "")
                        $(element).html(uuid);
                });
            }
        });
    }

    static OpenMediaLibrary(window, callback) {
        window.showOverlay();
        import('./appletMediaLibrary.js').then(module => {
            //module.default.New('', this.help_page, true); 
            if (module.default.instance.length > 0) {
                module.default.instance[0].window.close();
                module.default.instance[0].close();
            }
            const app = new module.default(true, (uuid, title) => {
                if(uuid != undefined)
                    callback(uuid, title);
                window.hideOverlay();
    
            });
        });
    }
}