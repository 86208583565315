import TaskBar from './taskBar.js';
import Widget from './widget.js';
import Utils from './utils.js';


export default class Window {

    static html = `<div class="window fade-in" id="Window2">
    <div class="resize-bar left"></div>
    <div class="resize-bar top"></div>
    <div class="resize-bar right"></div>
    <div class="resize-bar bottom"></div>
    <div class="resize-bar corner top-left"></div>
    <div class="resize-bar corner top-right"></div>
    <div class="resize-bar corner bottom-right"></div>
    <div class="resize-bar corner bottom-left"></div>
    <div class="window-title iflex w-100">
        <i class="icofont-tack-pin window-icon icon-pin"></i>
        <i class="icofont-sail-boat window-icon icon-popin"></i>
        <a class="icon-path" href="" target="_blank"><i class="icofont-link window-icon"></i></a>
        <p>Nouvelle fenêtre</p>
        <div class="titlebar-content"></div>
        <i class="icofont-question window-icon icon-help"></i>
        <i class="icofont-minus window-icon icon-reduce"></i>
        <i class="icofont-plus window-icon icon-expand"></i>
        <i class="icofont-close window-icon icon-close"></i>
    </div>
    <div class="header-content"></div>
    <div class="content" style="display:flex; flex-direction:column">
        <p>An error occured while creating the window.</p>
    </div>
    <div class="window-overlay" style="opacity:0%; display:none;">

    </div>
    <div class="window-footer">
        <div class="footer-content"></div>
        <i class="icofont-minus window-icon icon-reduce-footer"></i>
        <i class="icofont-resize window-icon icon-expand-footer"></i>
        <i class="d-none icofont-rounded-right-down window-icon icon-resize resize-handle"></i>
    </div>
        <div class="hover-visible" style="width:100%; text-align:right">
            <div style="display:none;" class="h-resize"></div>
        </div>
    </div>`;

    static modal_html = `
        <div>
            <div class="modal-icon">
                <i id="modalIcon" class=""></i>
            </div>
            <p id="modalContent" style="text-align:center; overflow-y:auto;">Une erreur inattendue est survenue.</p>
        </div>
    `;

    static RegisterWindow(window) {
        if (Window.windowList == undefined) {
            Window.windowList = [];
        }
        Window.windowList.push(window);
    }

    static GetWindowByUid(uid) {
        var win = null;
        Window.windowList.forEach((w) => {
            if (w.uid == uid)
                win = w;
        });
        return win;   
    }

    static FindWindowFromElement(element, level=0) {
        var win_doms = element.parents('.window');
        
        if (win_doms.length <= level)
            return null;
        var uid = $(win_doms[level]).attr('id');

        var win = null;
        Window.windowList.forEach((w) => {
            if (w.uid == uid)
                win = w;
        });
        return win;        
    }


    constructor(content_id, title, pos_x=100, pos_y=50, width=300, height=200, node=null, icon="", application=null, header_content=null) {
        this.subWindows = [];
        this.mono = false;
        this.position = [pos_x,pos_y];
        this.size = [width, height];
        this.path = "";
        this.shownInTaskBar = false;
        this.minSize = [0, 0];
        this.embedded = false;
        this.fullscreen = false;
        this.reduced = false;
        this.class = ""
        Window.RegisterWindow(this);
        this.zIndex = Window.windowList.length;
        this.application = application;

        this.window = $($.parseHTML(Window.html));
        this.window.attr('id', content_id);
        this.icon=icon;

        this.layer = 0;


        //this.window = $('#' + content_id);

        this.titleBar = this.window.find('.window-title');
        this.titleBar.find('p').html(title);
        this.title = title

        this.content = this.window.find('.content');
        this.uid = content_id;

        this.help_page = "";

        var content;
        if (node == null) {
            content = $('#' + content_id).html();
            $('#' + content_id).replaceWith(this.window);
            this.content.html(content);
        } else {
            content = node;
            $('main').append(this.window)
            node.appendTo(this.content);
            this.content.html(node.html());
        }

        this.pinned = false;

        this.window.mousedown((evt) => {this.setOnTop(); $(document).trigger("windowClicked", [this]); TaskBar.SetLastClickedWindow(this);});
        this.titleBar.mousedown((evt) => { this.dragWindowStart(evt) }).on('auxclick', (evt) => {
            if (this.embedded && evt.button == 1 && !this.window.parent('.wcontainer').hasClass('locked')) {
                this._popOut();
            }
        });
        this.window.find('.resize-handle').mousedown((evt) => { this.resizeWindowStart(evt) });
        this.window.find('.h-resize').mousedown((evt) => { this.resizeWindowStart(evt, true) });

        this.window.find('.resize-bar.top').mousedown((evt) => { this.resizeWindowStart(evt, false, [0,1,0,0]); });
        this.window.find('.resize-bar.left').mousedown((evt) => { this.resizeWindowStart(evt, false, [1,0,0,0]); });
        this.window.find('.resize-bar.bottom').mousedown((evt) => { this.resizeWindowStart(evt, false, [0,0,0,1]); });
        this.window.find('.resize-bar.right').mousedown((evt) => { this.resizeWindowStart(evt, false, [0,0,1,0]); });

        this.window.find('.resize-bar.top-right').mousedown((evt) => { this.resizeWindowStart(evt, false, [0,1,1,0]); });
        this.window.find('.resize-bar.top-left').mousedown((evt) => { this.resizeWindowStart(evt, false, [1,1,0,0]); });
        this.window.find('.resize-bar.bottom-right').mousedown((evt) => { this.resizeWindowStart(evt, false, [0,0,1,1]); });
        this.window.find('.resize-bar.bottom-left').mousedown((evt) => { this.resizeWindowStart(evt, false, [1,0,0,1]); });

        this.window.find('.icon-close').click((evt) => { this.close() });
        this.window.find('.icon-pin').click((evt) => { this.togglePin() });
        this.window.find('.icon-reduce').click((evt) => { this.setReduced(true) });
        this.window.find('.icon-reduce-footer').click((evt) => { this.setReduced(true) });
        this.window.find('.icon-expand').click((evt) => { this.setFullScreen() });
        this.window.find('.icon-expand-footer').click((evt) => { this.setFullScreen(false) });

        this.window.find('.icon-popin').click((evt) => { this.popBackIn(); });

        this.window.find('.icon-path').click((evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            //var path = $(evt.currentTarget).attr("href");
            //window.open(path, "_blank", `popup,width=${this.size[0]},height=${this.size[1]}`);
            this.browserPopUp();
        });

        this.window.find('.icon-help').click((evt) => { 
            if (this.help_page != "") { 
                import('./appletKnowledgeBase.js').then(module => {
                    module.default.New('', this.help_page, true); 
                });
            }
        });

        this.setPosition(pos_x,pos_y);
        this.setSize(width,height);
        this.updateZIndex();

        this.overlay = 0;

        if(header_content != null)
            this.setHeaderContent(header_content);

        TaskBar.SetLastClickedWindow(this);
        $(document).trigger("windowClicked", [this]);
        Widget.Setup(this.window, this);

    }

    browserPopUp()
    {
        window.open("/p" + this.path, "_blank", `popup,width=${this.size[0]},height=${this.size[1]},left=${window.screenX + this.position[0]},top=${window.screenY + this.position[1]}`);
        this.close();
    }

    _popOut() {
        this.embedded = false;
        const winparent = Window.FindWindowFromElement(this.window);

        const wcontainer = this.window.parent()
        $(wcontainer).trigger('undock', [this]);
        this.setMinSize(this.minSize[0], this.minSize[1]);

        const index = winparent.subWindows.indexOf(this);
        winparent.subWindows.splice(index, 1);
        this.setOnTop()
        $('main').append(this.window)
        this.showInTaskBar()
    }

    setPath(path) {
        this.path = path;
        this.window.find('.icon-path').attr('href', "/p" + this.path);
        this.window.attr('path', this.path);
        if (this.mono)
            window.history.pushState('page', this.title, "/p" + this.path);

    }

    popBackIn() {
        if(this.mono) {
            var message_id = Utils.generate_uid("",32);
            const bc = new BroadcastChannel("foroyar_link_handler");
            bc.postMessage({type:"link", id:message_id, content:this.path, args:mono_args});
            bc.onmessage = (evt) => {
                if (evt.data.type == "ack" && evt.data.id == message_id)
                    this.close();
            }
        }
    }

    setHandle(target) {
        var dom = this.content.find(target)
        dom.addClass('drag-handle').mousedown((evt) => { this.dragWindowStart(evt) })
        return this;
    }

    setClass(className) {
        this.class = className;
        return this;
    }

    setResizeHandle(target) {
        var dom = this.content.find(target)
        dom.addClass('resize-handle').mousedown((evt) => { this.resizeWindowStart(evt) });
        return this;
    }

    setHeaderContent(content) {
        this.window.find('.header-content').append(content);
    }

    setTitleBarContent(content) {
        this.window.find('.titlebar-content').append(content);
    }

    find(selector) {
        return this.window.find(selector);
    }

    show(value=true) {
        if (value)
            this.window.css('display','flex');
        else
            this.window.css('display', 'none');
    }

    showAlert(content, transparent=false) {
        this.showModal(content, 'Alerte', 'warning', true, transparent);
    }

    showInTaskBar(icon=null) {
        if(!TaskBar.initialized)
            return;
        if (!this.shownInTaskBar) {
            if (icon == null)
                icon = this.icon;
            else
                this.icon = icon;

            TaskBar.AddWindow(this, this.title, icon);
            $(document).trigger("addWindow", [this]);
            this.shownInTaskBar = true;
        }
    }

    hideInTaskBar() {;
        if(!TaskBar.initialized)
            return;
        if (this.shownInTaskBar) {
            TaskBar.RemoveWindow(this);
            $(document).trigger("removeWindow", [this]);
            this.shownInTaskBar = false;
        }
    }

    showInputBox(content, title, callback, transparent=false, default_value="") {
        const html = `${content}<input style="font-size:x-large; margin:15px;" id="modalInput"></input>`;
        const win = this.showModal(html, title, "pen", true, transparent);
        win.find('#modalInput').val(default_value).focus();
        win.addOkButtonToFooter('Valider', () => {
            callback(win.find("#modalInput").val());
            win.close();
        });

    }

    showYesNoModal(content, title="", icon="info-circle", yes_callback=null, no_callback=null, transparent=false) {
        const win = this.showModal(content, title, icon, false, transparent);
        //win.setFooter('<div style="display:none;"></div>');
        win.addButtonToFooter('Oui', 
            () => { 
                win.close(); 
                if (yes_callback != null) 
                    yes_callback();
            }).addButtonToFooter('Non', 
            () => { 
                win.close(); 
                if (no_callback != null) 
                    no_callback();
            }, 'warning');
    }

    showModal(content, title="", icon="info-circle", closeButton=true, transparent=false) {
        this.showOverlay();
        var winModal = new Window(this.uid + '_modal', title, 0, 0, 500, 420, $(Window.modal_html)).setClosable(true).setTransparent(transparent);
        winModal.layer=2;

        winModal.center(this).setOnTop().setBorderless(true);
        winModal.find('.content').append('<div id="modalFooter" style="text-align: center; margin:12px;"></div>').addClass('content-modal');

        if (closeButton) {
            const button = $(`<button>Fermer</button>`);
            winModal.find('#modalFooter').append(button);
            button.click(() => { winModal.close(); });
        }

        winModal.onclose = () => {
            this.hideOverlay();
        }

        winModal.find('#modalIcon').addClass('icofont-' + icon);
        winModal.find('#modalContent').html(content);
        return winModal;
    }


    setTransition(value="") {
        if (value == "") {
            this.window.removeClass('fade-in');
        }
        return this;
    }

    showOverlay() {
        const overlay = $(this.window.find('.window-overlay')[0]);
        overlay.css('display','block');
        setTimeout(() => {overlay.css('opacity', '100%');}, 10);
    }

    hideOverlay() {
        const overlay = $(this.window.find('.window-overlay')[0]);
        overlay.css('opacity', '0%');
        setTimeout(() => {overlay.css('display', 'none');}, 250);
    }

    setOverflow(value="auto") {
        this.content.css('overflow', value);
        return this;
    }

    setLayer(value) {
        this.layer = value;
        this.updateZIndex();
        return this;
    }

    setBorderless(value=true) {
        if (value)
            this.window.addClass('borderless');
        else
            this.window.removeClass('borderless');
        return this;
    }

    setTransparent(value=true) {
        if (value) {
            this.setBorderless(true);
            this.window.addClass('transparent');
        }
        return this;
    }

    setLite(value=true) {
        if (value)
            this.window.addClass('lite');
        else
            this.window.removeClass('lite');
        return this;
    }

    setReduced(value=true) {
        if(this.fullscreen)
            $(document).trigger("fullscreen", [this]);
        if (value) {
            this.window.addClass('fade-out-reduce');
            this.onreduce();
            TaskBar.SetReduced(this,true);
            setTimeout(() => {
                this.window.removeClass('fade-out-reduce')
                if (this.reduced) {
                    this.window.addClass('reduced');
                }
            }, 200);
        } else {
            this.window.removeClass('reduced');
            this.onrestore();
            TaskBar.SetReduced(this,false);
        }
        this.reduced = value;

        if(this.fullscreen)
            $(document).trigger("fullscreen", [this]);

        return this;
    }

    static IsFullScreen() {
        var value = false;
        Window.windowList.forEach((win) => {
            if (win.fullscreen && !win.reduced)
                value = true;
        });
        return value;
    }

    w(name) {
        return this.widgets[name];
    }

    setMonoApp() {
        this.setFullScreen();
        this.window.addClass("mono");
        document.title = this.title;
        this.mono = true;
    }

    setFullScreen(value=true) {
        if (value) {
            this.fullscreen = true;
            this.unpin();
            this.window.addClass('full')
            this.window.attr('style','').css('z-index', '-1');
            this.onresize();
            this.onresized();
            this.setOnTop();
        } else {
            this.fullscreen = false;
            this.window.removeClass('full')
            //this.layer=0;
            this.setOnTop();
            this.window.css('top', this.position[1] + 'px').css('left', this.position[0] + 'px');
            this.window.css('width', this.size[0] + 'px').css('height', this.size[1] + 'px');
            this.window.css('z-index', this.zIndex);
            this.onresized();
            this.onresize();
        }
        $(document).trigger("fullscreen", [this]);
        return this;
    }

    setHelpPage(page) {
        this.window.find('.icon-help').addClass('enabled');
        this.help_page = page;
    }

    togglePin() {
        if (this.pinned) {
            this.unpin()
            this.window.find('.icon-pin').removeClass('activated');
        } else {
            this.pin()
            this.window.find('.icon-pin').addClass('activated');
        }
    }

    setPinned(value) {
        if (value)
            this.pin();
        else
            this.unpin();
        return this;
    }

    unpin() {
        this.pinned=false;
        this.layer = 0;
        this.setOnTop();
        return this;
    }

    pin() {
        if (this.pinned) {
            return this;
        }

        this.pinned=true;
        this.layer = 1;
        this.zIndex += 10000;
        this.updateZIndex();
        return this;
    }

    setTopPadding(value) {
        this.window.find('.content').css('padding-top', '0px');
        return this;
    }

    setPadding(value) {
        this.window.find('.content').css('padding', '0px');
        return this;
    }

    setTitle(value) {
        this.titleBar.find('p').html(value);
        this.title=value;
        if (this.shownInTaskBar) {
            TaskBar.SetTitle(this, value)
        }
        if (this.mono)
            document.title = value;
        return this;
    }

    setFooter(content) {
        if(this.window.hasClass('borderless'))
            this.window.find('#modalFooter').html(content);
        else
            this.window.find('.footer-content').html(content);
        if (content != "") {
            this.window.find('.window-footer').addClass('big');
        }
        return this;
    }

    addOkButtonToFooter(text, callback=null) {
        const button = $(`<button id=footerButtonOk>${text}</button>`);

        if(this.window.hasClass('borderless'))
            this.window.find('#modalFooter').prepend(button);
        else
            this.window.find('.footer-content').prepend();

        if (callback != null) {
            button.click((evt) => { callback(); });
        }
        return this;
    }

    addButtonToFooter(text, callback=null, button_class=null) {
        const button = $(`<button>${text}</button>`)
        if(this.window.hasClass('borderless'))
            this.window.find('#modalFooter').append(button);
        else {
            this.window.find('.footer-content').append(button);
            this.window.find('.window-footer').addClass('big');
        }

        if (callback != null) 
            button.click((evt) => { callback(); });
        if(button_class != null)
            button.addClass(button_class);
        return this;
    }

    addCloseButtonToFooter(text) {
        this.addButtonToFooter('Close', () => { this.close(); });
        return this;
    }

    center(rootWindow=null) {
        if (rootWindow == null)
            this.setPosition( ($(window).width() - this.size[0])/2,  ($(window).height() - this.size[1])/2);
        else
            this.setPosition(
                (rootWindow.size[0] - this.size[0])/2 + rootWindow.position[0],
                (rootWindow.size[1] - this.size[1])/2 + rootWindow.position[1],
            );
        return this;
    }

    close(immediate=false) {
        $(document).trigger("windowClosed",this);
        this.oncloseimmediate();
        this.hideInTaskBar();

        if (this.embedded) {
            this.window.parent().trigger('undock', [this]);
        }
        // Recalcule des zIndex
        this.subWindows.forEach((w) => { w.close(); });

        Window.windowList.forEach((element) => {
            if (element.zIndex % 10000 > this.zIndex % 10000) {
                element.zIndex -= 1;
            }
        });

        const index = Window.windowList.indexOf(this);
        if (index > -1) {
            Window.windowList.splice(index, 1);
        }
        this.window.addClass('fade-out');
        if (immediate) {
            this.window.remove();
            this.onclose();
        } else {
            setTimeout(() => {this.window.remove(); this.onclose()}, 300);
        }
    }

    setMinSize(min_width, min_height) {
        this.minSize = [min_width, min_height];
        this.window.css('min-width',min_width + "px").css('min-height',min_height + "px");
        return this;
    }

    updateZIndex() {
        this.window.css('z-index', this.zIndex);
    }

    setOnTop() {
        $(document).trigger("windowClicked", [this]);
        var oldIndex = Math.min(this.zIndex % 10000, Window.windowList.length-1);
        Window.windowList.forEach((element) => {
            // Ajouter: Cas d'un zindex égal mais qui n'appartient pas à la fenetre courante
            if (element.zIndex % 10000 == oldIndex) {
                if (element == this) {
                    element.zIndex = Window.windowList.length + element.layer * 10000;
                    element.updateZIndex();
                } else {
                    element.zIndex = Math.min(element.zIndex%10000 -1, Window.windowList.length - 1) + element.layer * 10000;
                    element.updateZIndex();
                }
                //console.log("EQ");
                element.updateZIndex();
            } else if ((element.zIndex % 10000) > oldIndex) {
                element.zIndex = Math.min(element.zIndex%10000 -1, Window.windowList.length - 1) + element.layer * 10000;
                element.updateZIndex();
            }
        });
        //console.log(this.zIndex);
        return this;
    }

    setPosition(x, y) {
        this.position = [x,y];
        this.window.css('top', y + "px");
        this.window.css('left', x + "px");
        return this;
    }

    trySetSize(width,height) {
        this.size = [Math.max(width, this.minSize[0]), Math.max(height, this.minSize[1])];
        this.window.css('width', this.size[0] + "px");
        this.window.css('height', this.size[1] + "px");

        if (this.embedded) {
            this.window.css('min-width', this.size[0] + "px");
            this.window.css('min-height', this.size[1] + "px");
        }

        return [width>this.minSize[0],height>this.minSize[1]];
    }

    setSize(width, height) {
        this.trySetSize(width,height);

        return this;
    }

    setResizable(value=true) {
        if (value) {
            this.window.addClass('resizable');
        } else {
            this.window.removeClass('resizable');
        }
        return this;
    }

    setPinnable(value=true) {
        if (value) {
            this.window.addClass('pinnable');
        } else {
            this.window.removeClass('pinnable');
            this.pinned = false;
        }
        return this;
    }

    setExpandable(value=true) {
        if (value) {
            this.window.addClass('expandable');
        } else {
            this.window.removeClass('expandable');
        }
        return this;
    }

    setClosable(value=true) {
        if (value) {
            this.window.addClass('closable');
        } else {
            this.window.removeClass('closable');
        }
        return this;
    }

    resizeWindowStart(evt, horizontal=false, mask=[0,0,1,1]) {
        if(this.mono)
            return;
        evt.preventDefault();

        this.origClientPos = [evt.clientX, evt.clientY];
        this.origSize = this.size;
        //this.origCursorSize = [evt.clientX - this.size[0], evt.clientY - this.size[1]];
        //this.origCursorPos = [evt.clientX - this.position[0], evt.clientY - this.position[1]];
        this.resizeMask = mask;

        document.onmousemove = (evt) => {this.resizeWindow(evt, horizontal)};
        document.onmouseup = (evt) => {this.resizeWindowEnd(evt)};
    }

    resizeWindow(evt, horizontal=false) {
        if(this.mono)
            return;
        evt.preventDefault();

        if (horizontal)
            this.resizeMask=[0,0,0,1];

        var delta = [evt.clientX - this.origClientPos[0], evt.clientY - this.origClientPos[1]];

        var res = this.trySetSize(
            this.origSize[0]+(this.resizeMask[2]==0?0:delta[0]) - (this.resizeMask[0]==0?0:delta[0]), 
            this.origSize[1]+(this.resizeMask[3]==0?0:delta[1]) - (this.resizeMask[1]==0?0:delta[1])
        );
        this.setPosition(
            (this.resizeMask[0]==0||!res[0])?this.position[0]:evt.clientX, 
            (this.resizeMask[1]==0||!res[1])?this.position[1]:evt.clientY
        );

        this.onresize();
    }

    resizeWindowEnd(evt) {
        if(this.mono)
            return;
        evt.preventDefault();
        document.onmousemove = null;
        document.onmouseup = null;
        this.onresized();
    }

    dragWindowStart(evt) {
        if(this.mono)
            return;
        this.dragging = true;
        evt.preventDefault();
        //this.setOnTop();
        this.origCursorPos = [evt.clientX - this.position[0], evt.clientY - this.position[1]];
        this.origScreenPos = [evt.clientX, evt.clientY];

        document.onmousemove = (evt) => {this.dragWindow(evt)};
        document.onmouseup = (evt) => {this.dragWindowEnd(evt)};
    }

    dragWindow(evt) {
        if(this.mono)
            return;
        evt.preventDefault();
        const newPos = [evt.clientX - this.origCursorPos[0], evt.clientY - this.origCursorPos[1]];
        const distance = Math.sqrt((this.origScreenPos[0] - evt.clientX)**2 + (this.origScreenPos[1] - evt.clientY)**2);

        if (distance > 60 && this.embedded && !this.window.parent('.wcontainer').hasClass('locked')) {
            this._popOut();
            this.setPosition(evt.clientX - this.titleBar.outerWidth()/2, evt.clientY - this.titleBar.outerHeight()/2)
            this.origCursorPos = [evt.clientX - this.position[0], evt.clientY - this.position[1]];
        } else {
            this.setPosition(newPos[0], newPos[1]);
        }

        if (!this.embedded) {
            $('.wcontainer:empty').each((index, element) => {
                if($(element).attr("restrict") != undefined) {
                    if (!$(element).attr("restrict").split(" ").includes(this.class))
                        return;
                }
                element = $(element);
                if (evt.clientX >= element.offset().left && evt.clientX <= element.offset().left + element.outerWidth() &&
                    evt.clientY >= element.offset().top  && evt.clientY <= element.offset().top  + element.outerHeight()) {
                        element.addClass('hovered');
                    } else {
                        element.removeClass('hovered');
                    }

            });
        }
    }

    dockWindow(wcont) {
        if(this.mono)
            return;
        wcont.removeClass('hovered').append(this.window);
        wcont.trigger('dock', [this]);
        this.window.css('min-width', this.size[0]).css('min-height', this.size[1])
        var win = Window.FindWindowFromElement(wcont);
        win.subWindows.push(this);
        this.embedded = true;
        this.hideInTaskBar();
    }

    dragWindowEnd(evt) {
        if(this.mono)
            return;
        evt.preventDefault();
        this.dragging = false;
        document.onmousemove = null;
        document.onmouseup = null;
        var wcont = $('.wcontainer.hovered:empty');
        if (wcont.length > 0) {
            this.dockWindow($(wcont[0]));
        }
        this.onmoved();
    }


    // Events

    onresize() {

    }

    onresized() {

    }

    onmoved() {

    }

    onreduce() {

    }

    onrestore() {

    }

    onclose(){

    }

    oncloseimmediate() {

    }


}