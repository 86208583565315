import Applet from './applet.js';
import AutoForm from './autoForm.js';
import Window from './window.js';
import Menus from './appletMenus';
import Widget from './widget.js';
import Foroyar from './foroyar.js';
import AppletVCard from './appletVCard.js';
import Utils from './utils.js';

export default class AppletMailDatabase extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "mail-box";
    static appname="mail_database";

    static content = `
        <div>
            <div class="flex row nowrap m-2 h-100">
                <div class="box-list listbox" style="width:25%;display:inline-block; vertical-align:top;">
                </div>
                <div class="" style="display:inline-block; vertical-align:top; overflow:auto;">
                    <widget type="grid" options="af:mail_archive" field="mail_grid"></widget>
                </div>
            </div>
        </div>
    `;

    static iconBox = `<div class="text-muted hidden-icon-box">
    <i class="inline-icon icon-modify icofont-pencil-alt-2" tooltip="Modifier"></i>
    <i class="inline-icon icon-to-outbox icofont-arrow-right" tooltip="Placer dans la boîte d'envoi"></i>
    <i class="inline-icon icon-delete icofont-ui-delete" tooltip="Supprimer"></i>
    </div>
    `

    static folder_list = [
        ["inbox", "<i class='text-muted icofont-inbox'></i> Boîte de réception"],
        ["outbox", "<i class='text-muted icofont-mail'></i> Boîte d'envoi"],
        ["sent", "<i class='text-muted icofont-paper-plane'></i> Messages envoyés"],
        ["error", "<i class='text-muted icofont-error'></i> Erreurs d'envoi"],
        ["deleted", "<i class='text-muted icofont-delete-alt'></i> Supprimés"]
    ]

    loadPath(path) {

    }

    static FromPath(value) {
        var applet = super.New("Mail BDD");
		applet.loadPath(value);
		return applet;
    }

    update_mail_list(account_uuid, folder) {
        this.window.w('mail_grid').setFilter({'mailbox':folder,'account_uuid':account_uuid});
        this.current_account = account_uuid;
        this.current_folder = folder;
    }

    move_to_outbox(mail_uuid, callback) {
        var data = {
            uuid:mail_uuid,
            mailbox:"outbox"
        }
        $.post('/autoform/mail_archive/update', {data:JSON.stringify(data)}, (res) => {
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else {
                callback()
            }
        })
    }

    delete_mail(mail_uuid, callback) {
        var data = {
            uuid:mail_uuid,
            mailbox:"deleted"
        }
        $.post('/autoform/mail_archive/update', {data:JSON.stringify(data)}, (res) => {
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert(response.message);
            } else {
                callback();
            }
        });
    }

    open_mail(mail_uuid, modify=false) {
        const win = new Window(
            Utils.generate_uid(), 
            "Mail", 50,50,800,700, 
            $(`<div><div>
                <h3>Journaux SMTP</h3>
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Statut</th>
                            <th style="width:180px;">Date</th>
                            <th>Détails</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
                <h3>Contenu du mail</h3>
                <div class='autoform-content'></div>
                <button class="btn btn-primary btn-save" style="display:${modify?"block":"none"};">Enregistrer</button>
            </div></div>`), 
            this.taskbarIcon, this);
        win.setClosable(true).setExpandable(true).setPinnable(true).setResizable(true).center().setOverflow("auto").application = this;
        this.autoform.generateForm(win.find('.autoform-content'), this); 
        this.autoform.loadItemByIndexValue(mail_uuid, (title, uuid, data) => {
            win.setTitle(title);

            this.autoform.enableModifications(modify);
            this.current_uuid = uuid;
            this.current_item_title = title;

            win.find(".btn-save").click(() => {
                this.autoform.postForm((uuid) => {
                    win.close();
                });
            });

            $.get('/autoform/mail_status/list', {mail_uuid:mail_uuid}, (res) => {
                const response = res;
                if (response.status != 'OK') {
                    this.window.showAlert(response.message);
                } else {
                    const data = response.data.items;
                    data.forEach((row) => {
                        var bg = "none";
                        if(row.title.includes("ERROR"))
                            bg = "danger";
                        const date = new Date(row.date * 1000);
                        win.find("tbody").prepend(
                            `<tr class="bg-${bg}"><td>${row.title}</td><td>${date.toLocaleString('fr-FR', {dateStyle:"short",timeStyle:"short"})}</td><td>${row.content.replace("\n","<br>")}</td></tr>`
                        );
                    });
                }
            });
        });
    }

    fetch_usernames() {
        $.get('/operator/list', (res) => {
            var operators = {}
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				this.user_dict = response.data;
                console.log(this.user_dict);
            }
        });
        
    }

    update_usernames() {
        this.window.find('.user:empty').each((idx,elt) => {
            const uuid = $(elt).attr("uuid");
            if (Object.keys(this.user_dict).includes(uuid))
                $(elt).html(this.user_dict[uuid].username);
        });
    }

    static New() {
        var applet = super.New('Mail BDD');

        applet.user_dict={};

        applet.fetch_usernames();

        applet.autoform = new AutoForm("mail_archive");
        applet.autoform.errorCallback = (err) => {applet.window.showAlert(err)};
        applet.autoform.loadForm(() => { 
            //applet.window.find('#autoTarget').empty();
            //applet.autoform.generateForm(applet.window.find('#autoTarget'), applet); 
        });

        $.get('/mail', (res) => {
            const data = res;
            data.data.forEach((element) => {
                var node = $(`<div><h5>${element.name} (${element.address})</h5><ul></ul></div>`);
                AppletMailDatabase.folder_list.forEach((folder) => {
                    node.find('ul').append($(`<li uuid="${element.uuid}" folder="${folder[0]}">${folder[1]}</li>`));
                });
                applet.window.find('.box-list').append(node);
            });
            applet.window.find('.box-list').find('li').click((evt) => {
                applet.window.find('.box-list').find('li').removeClass("selected");
                $(evt.currentTarget).addClass("selected");
                applet.update_mail_list($(evt.currentTarget).attr("uuid"), $(evt.currentTarget).attr("folder"));
            });
        });

        setTimeout(() => {
            const grid = applet.window.w('mail_grid');
            applet.grid = grid;
            grid.load_autoform('mail_archive');console.log(applet.window.widgets);
            grid.setColumns(["toolbar","subject","reception_date","hdr_to","user_uuid","ticket"]);
            grid.setColumnOptions({
                "subject":{
                    "width":"40%"
                },
                "hdr_to":{
                    "title":"Destinataire"
                }
            });
            grid.setFormatter({
                "toolbar" : () => {
                    return `<div style="width:100%; text-align:center;">${AppletMailDatabase.iconBox}</div>`;
                },
                "uuid" : (item) => {
                    return `<a href="" class="mail-open" uuid="${item.uuid}">${item.uuid}</a>`;
                },
                "hdr_to": (item) => {
                    var res = "";
                    item.hdr_to.forEach((contact) => {
                        if (contact.includes(":") && ["uuid","vcard"].includes(contact.split(":")[0])) {
                            res += `<span class="vcard-link" uuid="${contact.substring(6)}"></span>`;
                        } else {
                            res += contact;
                        }
                    });
                    return res;
                },
                "subject" : (item) => {
                    return `<a href="" class="mail-open" uuid="${item.uuid}">${item.subject}</a>`;
                },
                "ticket" : (item) => {
                    return `<a class="ilink" href="/ticket/${item.ticket}">Ouvrir</a>`;
                },
                "user_uuid" : (item) => {
                    return `<span class="user" uuid="${item.user_uuid}"></span>`;
                },
                "reception_date":(item) => {
                    const date = new Date(item.reception_date*1000);
                    return date.toLocaleString('fr-FR', {dateStyle:"medium",timeStyle:"short"});
                }
            });
            grid.element.on('update', () => {
                applet.update_usernames();
                Widget.SetupiLinks(grid.element);
                Widget.SetupTooltips(grid.element);

                // Récupération des adresses mails associées à des vcard
                var uuid_list = [];
                applet.window.find(".vcard-link").each((idx, elt) => {
                    const uuid = $(elt).attr("uuid")
                    if (!uuid_list.includes(uuid))
                        uuid_list.push(uuid);
                });

                Foroyar.get("/autoform/vcard/list", {uuid: uuid_list.join(",")}, (res) => {
                    res.items.forEach((vcard) => {
                        $(`.vcard-link[uuid='${vcard.uuid}']`).html(vcard.title);
                    });
                });

                // Affichage de la carte de visite de l'utilisateur (peut-être à déplacer dans Widgets?)
                applet.window.find(".vcard-link").click((evt) => {
                    const uuid = $(evt.currentTarget).attr("uuid");
                    AppletVCard.New(uuid);
                });

                grid.element.find('.mail-open').click((evt) => {
                    evt.preventDefault();
                    const uuid = $(evt.currentTarget).attr('uuid');
                    applet.open_mail(uuid,false);
                });

                grid.element.find('.icon-modify').click((evt) => {
                    const uuid  = $(evt.currentTarget).closest("tr").attr("uuid");
                    applet.open_mail(uuid, true);
                });
                grid.element.find('.icon-to-outbox').click((evt) => {
                    const uuid  = $(evt.currentTarget).closest("tr").attr("uuid");
                    applet.window.showYesNoModal(
                        "Voulez-vous déplacer ce mail dans la boîte d'envoi?", 
                        "Suppression", 
                        "", 
                        () => {
                            applet.move_to_outbox(uuid, () => {
                                $(evt.currentTarget).closest("tr").remove();
                            });
                            
                        });
                });
                grid.element.find('.icon-delete').click((evt) => {
                    const uuid  = $(evt.currentTarget).closest("tr").attr("uuid");
                    applet.window.showYesNoModal(
                        "Voulez-vous supprimer ce mail de la base de donnée?<br>(cette action est irréversible)", 
                        "Suppression", 
                        "", 
                        () => {
                            applet.delete_mail(uuid, () => {
                                $(evt.currentTarget).closest("tr").remove();
                            });
                            
                        });
                });

            });
        },50);

        return applet;
    }

    constructor() {
        super("Base de données mails")

        this.window.setMinSize(950, 580).setSize(1400,750)
            .setClosable()
            .setPinnable()
            .setResizable()
            .setExpandable()
            .center()
            .setOverflow('clip');
    }

}

AppletMailDatabase.Initialize();