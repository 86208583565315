import Applet from './applet.js';
import AppletViewer from './appletViewer.js';
import WSConnection from "./websocket.js";
import SettingsManager from './settingsManager.js';

export default class AppletChat extends Applet {
    static unique = true;
    static instance = [];
    static shownInTaskBar = true;
    static taskbarIcon = "people";
    static appname="conversation";

    static content = `
    <div id="WindowConversation">
		<div class="conversation-content flew-grow h-100" style="overflow-y:scroll;">
        
        </div>
        <div class="conversation-status"></div>
        <div class="conversation-text flex">
            <textarea style="flex-grow:2;" class="conversation-textarea" placholder="Tapez votre texte ici..."></textarea>
            <button id="btnSend">Envoyer</button>
        </div>
		
	</div>
    `;

    last_message = null;
    last_status_update_sent = 0;
    last_status_update_received = 0;
    participants = {};

    loadPath(value) {
		this.load_conversation(value);
	}

	static FromPath(value) {
		var applet = super.New("Conversation");
		applet.loadPath(value);
		return applet;
	}

    load_conversation(conversation) {
        this.setPath(conversation)
        this.conversationUuid = conversation;
        this.last_message = null;
        this.window.find('.conversation-content').empty();
        $.get('/chat', {conversation:conversation}, (res) => {
            const response = res;
            if (response.status != 'OK') {
                this.window.showAlert('Error occured : ' + response.error_message);
            } else {
                this.window.find('.conversation-content').empty();
                this.participants = response.data.participants;
                response.data.messages.reverse().forEach((msg) => this.putMessage(msg));
            }
        });

        WSConnection.subscribe(`conv:${conversation}`, (data) => {
            if (data.content != undefined) {
                this.putMessage(data); 
            } else if (data.status != undefined && data.status == "typing") {
                if (data.user_uuid == Applet.user_uuid)
                    return;
                    this.window.find('.conversation-status').html(`${this.participants[data.user_uuid].nickname} est en train d'écrire...`)
                var timestamp = data.timestamp;
                this.last_status_update_received = data.timestamp;
                setTimeout(() => {
                    if (this.last_status_update_received == timestamp)
                        this.window.find('.conversation-status').html("");
                }, 7000)
            }
        });

        this.window.find('.conversation-textarea').keyup(() => {
            var time = new Date();
            if (time.getTime() - this.last_status_update_sent > 5000) {
                this.last_status_update_sent = time.getTime();
                $.post('/conversation', {action:'typing', conversation:conversation});
            }
        });

        this.window.find('.conversation-textarea').keydown((evt) => {
            //console.log(evt);
            if (evt.keyCode == 13 && !evt.shiftKey) {
                evt.preventDefault();
                this.sendMessage(conversation);
            }
        });

        this.window.find('#btnSend').off('click');
        this.window.find('#btnSend').click(() => {
            this.sendMessage(conversation);
        })
    }

    static New(conversation=null, application=null) {
        var applet = super.New('Conversation', false, application);
        applet.load_conversation(conversation)
        

        return applet;
    }

    formatTimestamp(unix_timestamp, hours=true) {
        var timestamp = unix_timestamp * 1000
        var date = new Date(timestamp);
        var today = new Date();
        today.setHours(0,0,0,0);
        var yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0,0,0,0);

        var str_time = ("0" + date.getHours()).substr(-2) + ":" + ("0" + date.getMinutes()).substr(-2);

        if (date >= today) {
            if (!hours)
                return "Aujourd'hui";
            return str_time;
        }
        else if (date >= yesterday) {
            if (!hours)
                return "Hier";
            return `Hier à ${str_time}`;
        }

        var str_day = ("0" + date.getDay()).substr(-2) + "/" + ("0" + date.getMonth()).substr(-2) + "/" + date.getFullYear();

        if (!hours)
            return str_day;
        return str_day + " " + str_time;
    }

    scrollDown() {
        this.window.find('.conversation-content').scrollTop(this.window.find('.conversation-content').prop('scrollHeight'));
    }

    sendMessage(conversation) {
        var content = this.window.find('.conversation-textarea').val().trim();
        if (content.length == 0) {
            return;
        }
        $.post('/chat', {content:content, conversation:conversation, timestamp:0}, (res) => {
            const response = res;
            if (response.status != 'OK') {
                applet.window.showAlert('Error occured : ' + response.error_message);
            } else {
                this.window.find('.conversation-textarea').val("");
            }
        })
    }

    putMessage(message) {
        var extra_class = "";

        if (this.last_message != null) {
            var date = new Date(message.timestamp * 1000);
            date.setHours(0,0,0,0);
            var last_date = new Date(this.last_message.timestamp * 1000);
            last_date.setHours(0,0,0,0);
            if (last_date.getTime() != date.getTime()) {
                console.log(last_date.getTime(), date.getTime());
                var separator = `
                    <div class="separator">
                        <div class="separator-bar"></div>
                        <span class="separator-label">${this.formatTimestamp(date.getTime()/1000, false)}</span>
                        <div class="separator-bar"></div>
                    </div>
                `;
                this.window.find('.conversation-content').append(separator);
            }

            if (this.last_message.user_uuid == message.user_uuid 
            && message.timestamp - this.last_message.timestamp < 300) {
                extra_class = "condensed";
            }
        }

        const participant = this.participants[message.user_uuid];
        console.log(participant, message.user_uuid, this.participants);
        //const color = `rgb(${participant.color})`;
        const username = participant.nickname;
        var img = "/assets/img/contact.png";
        if (participant.picture != "") {
            img = `${SettingsManager.cdn}i/small/${participant.picture}`;
        }

        var html = `
            <div class="message ${extra_class}">
                
                <div>
                    <span class="sender">${username} </span>
                    <span class="date">${this.formatTimestamp(message.timestamp)}</span>
                </div>
                <div class="message-profile"><img src="${img}" width=64 height=64></div>
                <div>${message.content}</div>
            </div>`;
        this.window.find('.conversation-content').append(html);
        this.last_message = message;

        this.scrollDown();
    }

    constructor(application) {

        if (!super("Conversation",application))
            return;
            
        this.window
            .setSize(750, 600)
            .setMinSize(180, 250)
            .setPosition(135,75)
            .setClosable(true)
            .setPinnable()
            .setExpandable()
            .setResizable();

    }
}

AppletChat.Initialize();