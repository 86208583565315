import Applet from './applet.js';
import Window from './window.js';
import AppletTicketList from './appletTicketList.js';
import AppletSendMail from './appletSendMail.js';
import Menus from './appletMenus.js';
import SettingsManager from './settingsManager.js';

import createEditorInline from './ckeditor/ckutils.js';
import Utils from './utils.js';
import Foroyar from './foroyar.js';

export default class AppletTickets extends Applet {
    static unique = false;
	static instance = [];
	static shownInTaskBar = true;
    static taskbarIcon = "ticket";
	static appname="ticket";

	static ticket_closed_content = `
		<div class="container">
			<p>Temps de traitement du ticket</p>
			<input id="inputTime">
			<p>Ticket résolu par</p>
			<select id="inputResolution">
				<option default>N/A</option>
			</select>
			<button class="btn btn-primary">Valider</button>
		</div>
	`;

	static content_left = `
		<div id="priorityIcon" class="priority-icon">
				<i class="" style="vertical-align: super;"></i>
		</div>
		<div style="padding:15px">
			<div class="flex">
				<button class="inline large menu" id="btnAction">Action <i class="icofont-caret-down"></i></button>
				<input id="inputTitle" class="seamless" style="display:none;font-size:x-large;flex-grow:100;margin-left:12px;margin-bottom:auto;"></input>
				<div id="tagBox" class="tag-box flex-grow"></div>
			</div>
			<div class="d-flex flex-wrap" style="">
				<div class="ticket-form"><label style="width:110px;">Statut: </label> <select id="inputStatus" class="seamless" disabled></select></div>
				<div class="ticket-form"><label style="width:110px;">Priorité:</label> <select id="inputPriority" class="seamless" disabled></select></div>
				<div class="ticket-form"><widget field="contact" label="Demande de:" options="noicon" type="mixed:autoform/vcard"></widget></div>
				<div class="ticket-form"><label style="width:110px;">Ouvert le:</label> <input id="inputCreated" class="seamless"></input></div>
				<div class="ticket-form"><label style="width:110px;">Temps estimé</label> <input id="inputEstimatedTime" class="seamless" disabled /></div>
				<div class="ticket-form"><label style="width:110px;">complétion</label> <span class="progress-bar"><span id="inputCompletion" class="progress-bar-percentage" style="width:32%">32%</span></span></div>
				<div class="ticket-form"><label  style="width:110px;">Client:</label> <input id="inputInstance" class="seamless" disabled /></div>
				<div class="ticket-form"><label  style="width:110px;">Logiciel:</label> <input id="inputSoftware" class="seamless" disabled /></div>
				<div class="ticket-form">
					<select id="inputOrigin" disabled>
						<option value="mail" default>Mail</option>
						<option value="phone">Téléphone</option>
						<option value="client">Suivi client</option>
					</select>
				</div>
				<div class="ticket-form">
					<select id="inputType" disabled>
						<option value="maintenance" default>Maintenance</option>
						<option value="evolution">Demande d'évolution</option>
						<option value="specifics">Développement spécifique</option>
					</select>
				</div>
				<div class="ticket-form"><label  style="width:110px;">Liens externes:</label> <span id="inputLinks" class="seamless"></span></div>
			</div>
		</div>
	`;

    static content = `
    <div id='TicketWindow'>
		<div class="h-100 w-100 d-flex flex-row main-container maintenance">
			<div class="panel-left">
				${AppletTickets.content_left};
			</div>
			<div class="hr-container h-100 w-100">
				<div class="tab">
					<div class="tab-button-bar">
						<div class="tab-button selected" tab="description">Description</div>
						<div class="tab-button" tab="history">Historique</div>
						<div class="tab-button" tab="attachments">Pièces jointes</div>
						<div class="filler" style="text-align:right;">
							<button id="modifyDescription" class=" modify-description">Modifier</button> 
							<button id="saveDescription" class="warning" style="display:none;">Enregistrer</button>
							<button id="refreshHistory" class="btn btn-link link"><i class="icofont-refresh"></i></button>
						</div>
					</div>
					<div style="border-radius: 6px; border: 1px solid #ccc; background-color: #eee;" class="history comment d-none parent-ticket position-relative">
						<b> Ticket parent</b> : <span class="parent-content"></span>
					</div>
					<div style="border-radius: 6px; border: 1px solid #ccc; background-color: #eee;" class="history comment d-none children-ticket position-relative">
						<b> Tickets enfant</b> : <ul class="children-content"></ul>
					</div>
					<div class="history comment d-none last-comment position-relative">
						<div class="btn-close-comment grey position-absolute" style="right:4px;top:4px;cursor:pointer;"><i class="icofont-close"></i></div>
						<div class="tag" uuid=""></div>
						<b class="date"></b> - <span class="last-comment-content"></span>
					</div>
					<div class="tab-panel selected" tab="description">
						<div class="ticket-content ck-content h-100" style="overflow:auto;"></div>
					</div>
					<div class="tab-panel" tab="history">
						<div id="History" class="history-box" style=""></div>
					</div>
					<div class="tab-panel text-center" tab="attachments">
						<div id="Attachments" class="justify-content-center p-3 small ticket-attachments d-flex flex-wrap" style="">
						</div>
						<button class="btn inline btn-add-attachment">Ajouter une pièce-jointe</button>
					</div>
				</div>
			</div>
		</div>
    `;

	update_tags(uuids=[], update_tagbox=true) {
		if(this.operators == undefined)
			return;

		if (update_tagbox)
			this.window.find('#tagBox').empty();
			uuids.forEach((uuid) => {
				this.window.find('#tagBox').append(`<div class="tag" uuid="${uuid}"></div>`);
			});

		this.window.find('.tag').each((index,tag) => {
			const uuid = $(tag).attr('uuid');
			if (uuid == "" || uuid == undefined)
				return

			const operator = this.operators[uuid];
			if (operator  == undefined) {
				$(tag).html(`undefined:${uuid}`);
				return;
			}
			if (operator.nickname == "")
				$(tag).html(operator.username);
			else
			$(tag).html(operator.nickname);

			var hue = operator.hue;

			$(tag).css('filter',`hue-rotate(${hue}deg)`);
		});
	}

	update_priority(priority) {
		var color = "";
		var icon = "";
		switch(priority) {
			case "low":
				color = "cyan";
				icon = "shrimp";
				break;
			case "medium":
				color = "green";
				icon = "fish-4";
				break;
			case "high":
				color = "orange";
				icon = "squid";
				break;
			case "critical":
				color = "red";
				icon = "octopus";
				break;
		}
		return [color, icon];
	}

	show_ticket_closed_window() {
		const ticket_closed_window = new Window('TicketClosed', 'Fermeture du ticket', 0, 0, 650, 450, $(AppletTickets.ticket_closed_content), "comment").setMinSize(450, 420)
            .setClosable()
            .setPinnable()
            .setResizable()
            .center();
		
	}

	load_ticket_history(uuid) {
		this.window.find('#History').empty();
		this.current_history = uuid;

		$.get('/ticket/history', {uuid:uuid}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				//if (uuid != this.current_ticket)
				//	return;

				response.data.forEach((evt) => {
					var date = new Date(parseFloat(evt.date) * 1000);
					const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour:'2-digit', minute:'2-digit' };
					var date = date.toLocaleString('fr-FR', options);

					var content;
					switch(evt.type) {
						case "assignments":
							var ass_plus = [];
							var ass_minus = [];
							evt.content.split('|').forEach((ass) => {
								if (ass.length <= 1)
									return;
								if (ass.substr(0,1) == "+") {
									ass_plus.push(ass.substr(1));
								} else {
									ass_minus.push(ass.substr(1));
								}
							});
							content = "";
							if (ass_plus.length > 0) {
								content += `Ticket assigné à `
								ass_plus.forEach((op) => content += `<div class="tag" uuid="${op}"></div>`);
								content += `. `;
							}
							if (ass_minus.length > 0) {
								content += `Ticket désassigné à `
								ass_minus.forEach((op) => content += `<div class="tag" uuid="${op}"></div>`);
								content += `. `;
							}
							break;
						case "mail":
							const mail_uuid = evt.content;

							content = `Mail <span id="direction_${mail_uuid}"></span> <span id="recipients_${mail_uuid}"></span> <span id="mail_from_${mail_uuid}"></span> 
							<button class="btn-mail-reply btn btn-primary btn-sm" mail='${mail_uuid}'><i class='inline-icon icofont-reply'></i>Répondre</button>
							<div class="mail-box box-expandable" id="mail_${mail_uuid}"></div>`;

							$.get('/autoform/mail_archive/load', {uuid:mail_uuid}, (res) => {
								const response = res;

								if (response.status != "OK") {
									this.window.showAlert(`Error while retrieving form data ${this.name} : ${response.message}.`);
									return;
								} else {
									const mail = response.data.data;
									var mail_from_content = "";
									if (mail.mailbox == "sent" || mail.mailbox == "outbox") {
										this.window.find(`#direction_${mail_uuid}`).html("envoyé à");
										//mail_from_content = mail.hdr_to.join(',');
										mail.hdr_to.forEach((recipient) => {
											this.window.find(`#recipients_${mail_uuid}`).append(Utils.generateMailTag(recipient));
										});
									} else {
										this.window.find(`#direction_${mail_uuid}`).html("reçu de");
										//mail_from_content = mail.hdr_from.join(',');
										mail.hdr_from.forEach((recipient) => {
											this.window.find(`#recipients_${mail_uuid}`).append(Utils.generateMailTag(recipient));
										});
									}
									
									var mail_content = mail.content.replaceAll("vid:", `${SettingsManager.cdn}orig/`);
									const comment_regex = /<!--.*-->/gs;
									mail_content = mail_content.replace(comment_regex, "").replaceAll('width="1920"','');

									this.window.find(`#mail_${mail_uuid}`).html(mail_content);
									if (mail.mailbox == "outbox")
										mail_from_content += " <b>(En cours d'envoi)</b>";
									else if(mail.mailbox == "error")
										mail_from_content += " <b><span class='text-danger'>(Erreur d'envoi)</span></b>"
									else if(mail.mailbox == "deleted")
										mail_from_content += " <b><span class='text-danger'>(Supprimé)</span></b>"
									this.window.find(`#mail_from_${mail_uuid}`).html(mail_from_content);
									
									Utils.lookUpMailTags(this.window);
								}
							});
							break;
						case "from_mail":
							const mail_from = evt.content;
							content = `Ticket créé à partir d'un mail 
							<button class="btn btn-primary btn-mail-reply btn-sm" mail='${mail_from}'><i class='inline-icon icofont-reply'></i>Répondre</button>
							<div class="mail-box box-expandable" id="mail_${mail_from}"></div>`;

							$.get('/autoform/mail_archive/load', {uuid:mail_from}, (response) => {
								if (response.status != "OK") {
									this.window.showAlert(`Error while retrieving form data ${this.name} : ${response.message}.`);
									return;
								} else {
									const mail = response.data.data;
									var content = mail.content.replaceAll("vid:", `${SettingsManager.cdn}orig/`);
									const comment_regex = /<!--.*-->/gs;
									content = content.replace(comment_regex, "").replaceAll('width="1920"','');

									this.window.find(`#mail_${mail_from}`).html(content);

									const date_mail = new Date(mail.reception_date*1000);
									
								}
							});
							break;
						case "comment":
							content = `a commenté <div class="comment-box box-expandable">${evt.content}</div>`;
							break;

						case "priority":
							const priority = evt.content.split('|');
							const priority_from = this.update_priority(priority[0]);
							const priority_to = this.update_priority(priority[1]);
							content = `Priorité changée de <i style="color:${priority_from[0]};" class="icofont-${priority_from[1]}"></i> à <i style="color:${priority_to[0]};" class="icofont-${priority_to[1]}"></i>.`;
							break;
						case "attachment":
							if (evt.content[0] == "-") {
								content = `Pièce-jointe supprimée <bold>${evt.content}</bold>.`
							} else {
								content = `Pièce-jointe ajoutée <bold>${evt.content}</bold>.`
							}
							break;
						case "status":
							const status = evt.content.split('|');
							content = `Statut changé de <b>${this.statuses[status[0]]}</b> à <b>${this.statuses[status[1]]}</b>.`;
							break;
						case "estimated_time":
							const et = evt.content.split('|');
							content = `Temps estimé changé de <b>${et[0]}</b> à <b>${et[1]}</b>.`;
							break;
						case "title":
							const title = evt.content.split('|');
							content = `Titre changé de <b>${title[0]}</b> à <b>${title[1]}</b>.`;
							break;
						case "content":
							content = `Modification du descriptif <div class="diff-box box-expandable">${evt.content}</div>`;
							evt.type = "description";
							break;
						case "new_ticket":
							var acq = { "mail":"Mail","phone":"Téléphone","client":"Suivi client"};
							content = `Création du ticket (<b>${evt.content}</b>)`;
							break;
						case "origin":
							var data = evt.content.split("|");
							var acq = { "mail":"Mail","phone":"Téléphone","client":"Suivi client"};
							content = `Changement du canal d'acquisition de <b>${acq[data[0]]}</b> à <b>${acq[data[1]]}</b>`;
							break;
						case "ticket_type":
							var data = evt.content.split("|");
							var acq = { "specifics":"Développement spécifique","evolution":"Demande d'évolution","maintenance":"Maintenance"};
							content = `Changement du type de ticket de <b>${acq[data[0]]}</b> à <b>${acq[data[1]]}</b>`;
							break;
						case "software":
							var data = evt.content.split("|");
							content = `Changement du logiciel de <b>${data[0]}</b> à <b>${data[1]}</b>`;
							break;
						case "instance":
							var data = evt.content.split("|");
							content = `Changement du client de <b>${data[0]}</b> à <b>${data[1]}</b>`;
							break;
						case "to_evolution":
							content = `Conversion en ticket d'évolution`;
							break;
						case "from_evolution":
							content = `Converti depuis ticket de maintenance`;
							break;
						default:
							content = `Evènement de type <b>${evt.type}</b> <div class="mail-box box-expandable">${evt.content}</div>`;
							break;
					}
					if(evt.parent)
						content = "<b>[Parent]</b> " + content;
					const html = `<div class="history ${evt.type}"><div class="tag" uuid="${evt.operator}"></div><b>${date}</b> - ${content}</div>`
					this.window.find('#History').prepend(html);
					
				});
				this.window.find(".btn-close-comment").click(() => { this.window.find(".last-comment").addClass("d-none"); })
				this.window.find('#History').find('.box-expandable').click((evt) => { $(evt.currentTarget).removeClass('box-expandable');} );
				this.window.find('#History').find('.btn-mail-reply').click((evt) => { 
					console.log(this.window.w('contact').val());
					AppletSendMail.New(null, 
						//this.window.find('#inputContact').val().split(','), 
						this.window.w('contact').val(),
						[],
						``,
						`<p><b>Votre référence de ticket :</b> ${this.ticket_data.number}</p><br>`,
						$(evt.currentTarget).attr('mail'),
						(mail_uuid) => {
							$.post('/ticket/add_mail', {uuid:this.current_ticket, mail_uuid:mail_uuid}, (res) => {
								this.load_ticket_history(this.current_ticket);
							});
						}
					);
				});
				this.update_tags([], false);
			}
		});
	}

	load_ticket(uuid) {
		$.get('/ticket/get', {uuid:uuid}, (response) => {
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				//"number","title","content","status","categories","created","created_by","priority","estimated_time",
				//"completion","instance","modified","assignments","contacts"
				this.ticket_type = response.data.ticket_type;
				if(this.ticket_type == "maintenance")
					this.window.find(".main-container").addClass("maintenance");
				else
					this.window.find(".main-container").removeClass("maintenance");

				this.orig_mail_uuid = null;
				var ticket_number = parseInt(response.data.number).toString();
                if (isNaN(ticket_number))
                    ticket_number = response.data.number
				this.setPath(ticket_number);

				['status','priority','instance'].forEach((elt) => {
					this.window.find(`#input${elt[0].toUpperCase()}${elt.slice(1)}` ).val(response.data[elt]);
				});

				this.window.find(`#inputInstance`).attr('value',response.data['instance']).val('');
				this.fetchFieldTitle(this.window.find("#inputInstance"), 'project');

				this.window.find(`#inputSoftware`).attr('value',response.data['software']).val('');
				this.fetchFieldTitle(this.window.find("#inputSoftware"), 'project');

				this.window.find(`#inputLinks`).empty();
				if (response.data['external_links'] != "") {
					response.data['external_links'].forEach((link) => {
						if(link[0] == "tuleap") {
							var node = $(`<span class="tuleap-link"><img src="/assets/img/tuleap.png" class="mx-1" height=16>Tuleap #${link[1]}</span>`)
							this.window.find(`#inputLinks`).append(node);
							node.click(() => {
								window.open(link[2], "_blank");
							});
						}
					});
				}

				const priority = this.update_priority(response.data.priority);
				this.window.find("#priorityIcon").css('color',priority[0]).find('i').attr('class', 'icofont-' + priority[1]).removeClass("d-none");

				this.window.setTitle(`[Ticket #${response.data.number}] ${response.data.title}`);
				//TaskBar.SetTitle(this, `[Ticket #${response.data.number}] ${response.data.title}`);

				this.window.find(`#inputEstimatedTime`).val(response.data.estimated_time);

				this.window.find(`#inputOrigin`).val(response.data.origin);
				this.window.find(`#inputType`).val(response.data.ticket_type);

				this.window.find(`.ticket-content`).html(response.data.content);
				var date = new Date(parseFloat(response.data.created) * 1000);
				const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

				this.project = response.data.instance;
				this.software = response.data.software;
				this.origin = response.data.origin;
				this.assignments = response.data.assignments;
				if (this.assignments[0] == "")
					this.assignments = [];

				this.window.find(".ticket-attachments").empty();
				response.data.files.forEach((file) => {
					this.add_attachment(file.uuid, file.total_size, file.title);
				})

				this.window.w('contact').val(response.data.contacts);
				this.window.w('contact').disable(true);
				//this.window.find('#inputContact').val(response.data.contacts.join(', '));

				//this.window.find('#inputCreated').val(date.toLocaleDateString(undefined, options))
				this.window.find('#inputCreated').getKendoDatePicker().value(date);
				this.current_ticket = response.data.uuid;
				this.ticket_data = response.data;

				this.window.find('.children-content').empty();
				if(response.data.children.length > 0) {
					response.data.children.forEach((child) => {
						this.window.find('.children-content').append(`<li><a number="${child[0]}" href="/p/ticket/${child[0]}">[${child[0]}] ${child[1]}</a></li>`);
						this.window.find('.children-ticket').removeClass("d-none");
					});
					this.window.find('.children-content a').click((evt) => {
						evt.preventDefault();
						var number = $(evt.currentTarget).attr("number");
						AppletTickets.New(number, true);
					});
				} else {
					this.window.find('.children-ticket').addClass("d-none");
				}

				this.window.find('.parent-content').empty();
				if(response.data.parent != undefined && response.data.parent != "") {
					this.window.find('.parent-content').html(`<a number="${response.data.parent[0]}" href="/p/ticket/${response.data.parent[0]}">[${response.data.parent[0]}] ${response.data.parent[1]}</a>`);
					this.window.find('.parent-ticket').removeClass("d-none");
					this.window.find('.parent-content a').click((evt) => {
						evt.preventDefault();
						var number = $(evt.currentTarget).attr("number");
						AppletTickets.New(number, true);
					});
				} else {
					this.window.find('.parent-ticket').addClass("d-none");
				}

				if (response.data.last_comment == null) {
					this.window.find(".last-comment").addClass("d-none");
				} else {
					var date = new Date(response.data.last_comment.date * 1000);
					const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour:'2-digit', minute:'2-digit' };
					this.window.find(".last-comment").removeClass("d-none");
					this.window.find(".last-comment .tag").attr("uuid", response.data.last_comment.operator);
					this.window.find(".last-comment .date").html(date.toLocaleString('fr-FR', options));
					this.window.find(".last-comment .last-comment-content").html(response.data.last_comment.content)
				}
				this.update_tags(this.assignments);
				this.load_ticket_history(this.current_ticket);
			}
		})
	}

	new_from_mail(mail_uuid) {
		this.new_ticket();

		$.get(`/autoform/mail_archive/load`, {uuid:mail_uuid}, (res) => {
			const response = res;

			if (response.status != "OK") {
				this.window.showAlert(`Error while retrieving form data for ${mail_uuid} : ${response.message}.`);
				return;
			} else {
				const mail = response.data.data;
				this.orig_mail_uuid = mail_uuid;
				this.window.find("#inputTitle").val(mail.subject);
				//this.window.find("#inputContact").val(mail.hdr_from.join(','));
				this.window.w('contact').val(mail.hdr_from);
				const date_creation = new Date(Date.now());
				const date_mail = new Date(mail.reception_date*1000);
				this.window.find("#inputCreated").val(date_creation.toLocaleDateString()).attr('value',Date.now()/1000);
				this.window.find('#inputCreated').getKendoDatePicker().value(date_creation);
				const comment_regex = /<!--.*-->/gs;
				var mail_content = mail.content.replaceAll('vid:', `${SettingsManager.cdn}orig/`);
				mail_content = mail_content.replace(comment_regex, "");
				this.contenteditor.setData(`<p>&nbsp;</p><hr>Mail original reçu le ${date_mail.toLocaleDateString()} : <blockquote>${mail_content}</bloquote>`);

				this.window.find(".ticket-attachments").empty();
				if(response.data.data.attachments.length > 0) {
					this.window.showYesNoModal(
						"Des pièces-jointes sont présentes dans le mail, voulez-vous les ajouter au ticket?", 
						"Ajouter les pièces jointes", 
						"info-circle", () => {
							response.data.data.attachments.forEach((elt) => {
								Foroyar.get("/media/get", {uuid:elt}, (response) => {
									if (response.categories.includes("attachment"))
										this.add_attachment(response.uuid, response.total_size, response.title);
								});
							});
						}
					);
				}
			}

		});
	}

	show_comment_window() {
		const content = `
		<div class="flex-column">
			<div class="flex-grow comment-content"> </div>
			<div class="flex-row">
				<button class="warning" id="btnCancel">Annuler</button>
				<button id="btnSend">Envoyer</button>
			</div>
		</div>
		`
		if (this.comment_window != null)
			this.comment_window.close();

		this.comment_window = new Window('TicketComment', 'Commentaire', 0, 0, 650, 450, $(content), "comment").setMinSize(450, 420)
                .setClosable()
                .setPinnable()
                .setResizable()
                //.setOverflow('auto')
                .center();

		this.comment_window.oncloseimmediate = () => {this.comment_window = null;}

		var comment_editor = null;
		createEditorInline(this.comment_window.uid, '.comment-content', 
			(editor) => { comment_editor = editor; },
			this.comment_window.showAlert
		);

		this.comment_window.find('#btnCancel').click(() => { this.comment_window.close(); });
		this.comment_window.find('#btnSend').click(() => { 
			const modal = this.comment_window.showModal('Enregistrement...', 'Envoi', 'sand-clock', false, true);
			this.add_comment(comment_editor.getData(), () => {
				modal.close();
				this.comment_window.close();
			});
		});
	}

	congrats() {
		console.log('Congrats!!');
		var sound = new Audio('/assets/sounds/yay.ogg');
		sound.play();

		const defaults = {
			spread: 360,
			ticks: 50,
			gravity: 0,
			decay: 0.94,
			startVelocity: 30,
			shapes: ["star"],
			colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
		  };
		  
		  function shoot() {
			confetti({
			  ...defaults,
			  particleCount: 40,
			  scalar: 1.2,
			  shapes: ["star"],
			});
		  
			confetti({
			  ...defaults,
			  particleCount: 10,
			  scalar: 0.75,
			  shapes: ["circle"],
			});
		  }
		  
		  setTimeout(shoot, 0);
		  setTimeout(shoot, 100);
		  setTimeout(shoot, 200);

	}

	new_ticket() {
		this.window.find("#btnAction").html('Créer');
		this.window.find("#inputTitle").css('display','inline-block');
		this.orig_mail_uuid = null;

		['Status','Priority','Contact','Instance','EstimatedTime','Origin','Software','Type'].forEach((element) => {
			this.window.find('#input' + element).removeAttr('disabled');
		})

		this.window.find('#saveDescription').css('display','none');
		this.window.find('#modifyDescription').css('display','none');

		this.update_priority("low");

		this.mode = 'create';
		this.current_ticket = "";
		this.project = "";
		this.software = "";
		this.origin = "";
		this.assignments = [];
		this.update_tags(this.assignments);
		this.window.setTitle('Nouveau ticket');
		this.window.find('#inputTitle').val('Nouveau ticket');
		this.window.w('contact').empty();
		this.window.w('contact').disable(false);
		this.window.find('#inputInstance').val('').attr('value','');
		this.window.find('#inputSoftware').val('').attr('value','');
		this.window.find(`#inputLinks`).empty();
		this.window.find('#inputOrigin').val('mail');
		this.window.find('#inputType').val('maintenance');
		this.window.find('#inputEstimatedTime').val('');
		this.window.find("#priorityIcon").addClass("d-none");
		this.window.find('#inputCreated').val('');
		this.window.find('#inputPriority').val('low');
		this.window.find('#inputCreated').prop('disabled',false);
		this.window.find('#inputCreated').getKendoDatePicker().enable(true);
		this.window.find('#inputStatus').val('open');
		this.window.find(".last-comment").addClass("d-none");
		this.create_editor('<h2>Nouveau ticket</h2>');

	}

	disable_modifications() {
		this.window.find("#btnAction").html('Action');
		this.window.find("#inputTitle").css('display','none');
		this.window.find('#inputCreated').prop('disabled',true);
		this.window.find('#inputCreated').getKendoDatePicker().enable(false);

		['Status','Priority','Contact','Instance','EstimatedTime','Software','Type','Origin'].forEach((element) => {
			this.window.find('#input' + element).attr('disabled','1');
		})
		this.contenteditor.destroy();
		this.window.find('.ticket-content').removeAttr("contenteditable");
		this.mode = 'edit';
	}

	save_ticket() {
		if (this.mode == "create") {
			// title, i.estimated_time, content, priority categories.split('|'),i.contacts.split('|'),i.instance
			if (this.window.find('#inputCreated').getKendoDatePicker().value() == null) {
				this.window.showAlert("Veuillez renseigner une date d'ouverture.")
				return;
			}
			var time = this.window.find('#inputCreated').getKendoDatePicker().value().getTime() / 1000;
			var attachments = [];
			this.window.find(".ticket-attachments .thumbnail-box").each((idx,elt) => {
				attachments.push($(elt).attr("uuid"));
			});
			var data = {
				'title':this.window.find('#inputTitle').val(),
				'estimated_time':this.window.find('#inputEstimatedTime').val(),
				'created':time,
				'content':this.contenteditor.getData(),
				'priority':this.window.find('#inputPriority').val(),
				'categories':'',
				//'contacts':this.window.find('#inputContact').val(),
				'attachments':attachments.join(","),
				'contacts':this.window.w('contact').val().join(","),
				'instance':this.window.find('#inputInstance').attr('value'),
				'software':this.window.find('#inputSoftware').attr('value'),
				'origin':this.window.find('#inputOrigin').val(),
				'ticket_type':this.window.find('#inputType').val()
			};

			if (this.orig_mail_uuid != null)
				data['orig_mail_uuid'] = this.orig_mail_uuid;

			$.post('/ticket/create',data, (res) => {
				const response = res;
				if (response.status != 'OK') {
					this.window.showAlert(response.message);
				} else {
					//this.disable_modifications();
					//this.window.setTitle(`[Ticket #${response.data.number}] ${data.title}`);
					//TaskBar.SetTitle(this, `[Ticket #${response.data.number}] ${data.title}`);
					//this.current_ticket = response.data.uuid;
					AppletTickets.New(response.data.uuid, true);
					this.window.close();
					
					AppletTicketList.refresh();
					//this.ticket_data = response.data;
					//this.load_ticket_history(this.current_ticket);
				}
			})
		}
	}

	create_editor(content=null) {
		createEditorInline(this.window.uid, '.ticket-content', 
			(editor) => {
				this.contenteditor = editor;
				if (content != null)
					editor.setData(content);
			},
			this.window.showAlert
		);			
	} 

	add_comment(comment, callback) {
		$.post('/ticket/comment', {uuid:this.current_ticket, value:comment}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				this.load_ticket_history(this.current_ticket);
			}
			callback();
		}) 
	}


	update_assignments() {

		$.post('/ticket/update', {uuid:this.current_ticket, field:"assignments", value:this.assignments.join(',')}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				this.update_tags(this.assignments);
				AppletTicketList.refresh();
			}
		}); 
	}

	update_project(project) {
		$.post('/ticket/update', {uuid:this.current_ticket, field:'instance', value:project}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				this.load_ticket(this.current_ticket);
				AppletTicketList.refresh();
			}
		});
	}

	update_software(software) {
		$.post('/ticket/update', {uuid:this.current_ticket, field:'software', value:software}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				this.load_ticket(this.current_ticket);
				AppletTicketList.refresh();
			}
		});
	}

	update_origin(origin) {
		$.post('/ticket/update', {uuid:this.current_ticket, field:'origin', value:origin}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				this.load_ticket(this.current_ticket);
				AppletTicketList.refresh();
			}
		});
	}

	update_type(type) {
		$.post('/ticket/update', {uuid:this.current_ticket, field:'type', value:type}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				this.load_ticket(this.current_ticket);
				AppletTicketList.refresh();
			}
		});
	}

	update_estimated_time(new_time) {
		$.post('/ticket/update', {uuid:this.current_ticket, field:"estimated_time", value:new_time}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				this.load_ticket(this.current_ticket);
				AppletTicketList.refresh();
			}
		}) 
	}

	update_title(new_title) {
		$.post('/ticket/update', {uuid:this.current_ticket, field:"title", value:new_title}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				this.load_ticket(this.current_ticket);
				AppletTicketList.refresh();
			}
		}) 
	}

	update_tuleap_link(link) {
		Foroyar.post('/ticket/update_tuleap', {uuid:this.current_ticket, value:link}, (res) => {
			this.load_ticket(this.current_ticket);
		},
		(error, msg) => {
			this.window.showAlert(error + ":" + msg,false);
		});
	}

	create_tuleap_link() {
		Foroyar.post('/ticket/create_tuleap', {uuid:this.current_ticket}, (res) => {
			this.load_ticket(this.current_ticket);
		},
		(error, msg) => {
			this.window.showAlert(error + ":" + msg,false);
		});
	}


	update_content() {
		$.post('/ticket/update', {uuid:this.current_ticket, field:"content", value:this.contenteditor.getData()}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				AppletTicketList.refresh();
			}
		}) 
	}

	menu_callback(value) {
		const val = value.split(':');
		var field = "";
		if (val.length > 1)
			var update_value = val[1];
		var ticket_field = null;
		var callback = null;

		switch(val[0]) {
			case "new_mail":
				AppletSendMail.New(null, 
					//this.window.find('#inputContact').val().split(','), 
					this.window.w('contact').val(),
					[],
					`Re: ${this.ticket_data.title}`,
					`<p><b>Votre référence de ticket :</b> ${this.ticket_data.number}</p><br>`,
					null,
					(mail_uuid) => {
						$.post('/ticket/add_mail', {uuid:this.current_ticket, mail_uuid:mail_uuid}, (res) => {
							this.load_ticket_history(this.current_ticket);
						});
					}
				);
				return;
			case "title":
				this.window.showInputBox('Veuillez entrer le nouveau titre du ticket', 'Titre', (val) => {
					this.update_title(val);
				}, false, this.ticket_data.title);
				return;
			case "comment":
				this.show_comment_window();
				return;
			case "create_tuleap":
				this.create_tuleap_link();
				return;
			case "change_estimated_time":
				this.window.showInputBox('Veuillez entrer le nouveau temps de traitement', 'Temps de traitement', (val) => {
					this.update_estimated_time(val);
				}, false, this.ticket_data.estimated_time);
				return;
				break;
			case "tuleap":
				this.window.showInputBox('Veuillez coller le lien vers Tuleap', 'Lien Tuleap', (val) => {
					this.update_tuleap_link(val);
				});
				return;
			case "priority":
				field = "priority";
				ticket_field = this.window.find('#inputPriority');
				callback = (val) => { this.update_priority(val);};
				break;
			case "status":
				field = "status";
				ticket_field = this.window.find('#inputStatus');
				//callback = this.update_status
				break;
			case "convert":
				this.convert_ticket();
				return;
			default:
				return;
		}
		$.post('/ticket/update', {uuid:this.current_ticket, field:field, value:update_value}, (res) => {
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				if (ticket_field != null)
					ticket_field.val(val[1]);
				if (callback != null)
					callback(val[1]);
				AppletTicketList.refresh();
				if (field == "status" && update_value == "closed")
					this.congrats();
			}
		});
	}

	convert_ticket() {
		this.window.showYesNoModal(
			"Voulez-vous convertir le ticket en ticket d'évolution ?", 
			"Convertir le ticket", 
			"info-circle", () => {
				Foroyar.post('/ticket/convert', {uuid:this.current_ticket}, (response) => {
					this.load_ticket(response);
				},
				(error,message) => {
					this.window.showAlert(message, true);
				});
			}
		);
	}

    fetch_operators() {
        
		const req = $.get('/operator/list', (res) => {
            var operators = {}
			const response = res;
			if (response.status != 'OK') {
				this.window.showAlert(response.message);
			} else {
				operators = response.data;
                Object.keys(operators).forEach((op) => {
                    var hue = 0;
                    if (operators[op].color != "") {
                        var rgb = operators[op].color.split(',');
                        operators[op].rgb = rgb;
                        for (var i=0; i<3;i++)
                            rgb[i] = parseInt(rgb[i]);
                        const min = Math.min(rgb[0], rgb[1], rgb[2]);
                        const max = Math.max(rgb[0], rgb[1], rgb[2]);
        
                        if (min == max) {
                            hue = 0;
                        } else {
                            if (max == parseInt(rgb[0])) {
                                hue = (rgb[1] - rgb[2]) / (max - min);
                            } else if (max == rgb[1]) {
                                hue = 2 + (rgb[2] - rgb[0]) / (max - min);
                            } else {
                                hue = 4 + (rgb[0] - rgb[1]) / (max - min);
                            }
        
                            hue = Math.round(hue*60);
                        }
                    }
                    operators[op].hue = hue;
                });
                this.operators = operators;
				this.update_tags(this.assignments);
			}
		});
        return req;
    }

	loadPath(value) {
		this.load_ticket(value);
		//this.load_ticket_history(value);
	}

	static FromPath(value, new_window=true) {
		var applet = super.New("Ticket", new_window);
		applet.loadPath(value);
		return applet;
	}

	static NewFromMail(mail_uuid, application=null) {
		var applet = super.New("Ticket", false,application);
		console.log(['New from mail',mail_uuid]);
		applet.new_from_mail(mail_uuid);
		return applet;
	}

	static New(uuid=null, new_window=false, application=null) {
		var applet = super.New("Ticket", new_window, application);
		if (uuid != null) {
			applet.load_ticket(uuid);
			//applet.load_ticket_history(uuid);
		} else {
			applet.new_ticket();
		}
		return applet;
	}

	add_attachment(uuid, size, title) {
		var node = $(`<div class="thumbnail-box " uuid="${uuid}">
					<div class="img-box" loading="lazy" style="background-image: url(${SettingsManager.cdn}i/small/${uuid});"></div>
					<p>${title}</p><p class="grey">${Utils.formatBytes(size)}</p><button class="small btn inline btn-delete"><i class="icofont-close"></i></button>
					</div>`);
		this.window.find(".ticket-attachments").append(node);
		node.click(() => { Applet.LoadFromPath(`/viewer/${uuid}`); });
		node.find(".btn-delete").click((evt) => {
			evt.stopPropagation();
			if (this.mode != "create") {
				Foroyar.delete("/ticket/attachment", {uuid:this.current_ticket, attachment:uuid}, (response) => {
					node.remove();
				}, (error, message) => {
					this.window.showAlert(message);
				})
			} else {
				node.remove();
			}
		})
	}

    constructor(title, application=null) {
        super(title, application);

		this.window_list = new Window('')

		this.current_history = null;
            
        this.window
            .setSize(1200, 850)
            .setMinSize(450, 420)
            .setClosable()
            .setPinnable()
            .setResizable()
            .setPadding(0)
            .setExpandable()
            .center();

		this.window.find('#inputCreated').kendoDatePicker({culture:"fr-FR"});

		this.window.find('#inputCreated').getKendoDatePicker().enable(false);

        this.window.find('.icon-expand-history').click((evt) => {
            var target = this.window.find('.icon-expand-history').attr('target');
            if ($(target).css('display') == 'none') {
                $(target).css('display','block');
    
            } else {
                $(target).css('display','none');
            }
        });

		this.window.find('#inputInstance').focus((evt) => {
            Menus.showSearchPopup(this.window, this.window.find('#inputInstance'), 'project');
        });

		this.window.find('#inputSoftware').focus((evt) => {
            Menus.showSearchPopup(this.window, this.window.find('#inputSoftware'), 'software');
        });

		this.fetchSelectOptions(this.window.find('#inputPriority'), 'priorities');
		this.fetchSelectOptions(this.window.find('#inputStatus'), 'statuses');

		this.fetch_operators();

		this.window.find('#saveDescription').click((evt) => {
			this.update_content(),
			this.contenteditor.destroy();
			this.window.find('.ticket-content').removeAttr("contenteditable");
			this.window.find('#saveDescription').css('display','none');
			this.window.find('#modifyDescription').css('display','inline-block');
		});

		this.window.find(".btn-add-attachment").click(() => {
			Utils.OpenMediaLibrary(this.window, (uuid) => {
				Foroyar.post("/ticket/attachment", {"uuid":this.current_ticket, "attachment":uuid}, (response) => {
					this.add_attachment(response.uuid, response.size, response.title)
				}, (error, message) => {
					this.window.showAlert(message);
				});
				
			});
		})

		this.window.find("#inputType").change((evt) => {
			var value = $(evt.currentTarget).val();
			if (value == "maintenance")
				this.window.find(".main-container").addClass("maintenance");
			else
				this.window.find(".main-container").removeClass("maintenance");
		})
		
		this.contenteditor = null;
		this.mode = "edit";

		this.statuses = {
			"open":"Ouvert",
			"pending":"En cours",
			"waiting":"Attente de retour client",
			"closed":"Fermé",
			"postponed":"Ajourné",
			"abandoned":"Abandonné",
			"evolution" : "Évolution",
			"evol_open":"A statuer",
			"evol_pending":"Accepté",
			"evol_rejected":"Rejeté",
			"evol_closed":"Terminé",
			'to_be_closed':"A clôturer",
			'evol_pending_validation':"A valider par Xavier"
		}

		this.window.find('#btnAction').click((evt) => {
			if (this.mode == "create") {
				this.save_ticket()
			} else {
				var operatorMenu = []
				Object.keys(this.operators).forEach((op) => {
					const rgb = this.operators[op].rgb;
					var checked = "";
					if (this.assignments.includes(op))
						checked = "checked";
					operatorMenu.push([`
						<input type="checkbox" id="checkbox_${op}" ${checked}>
						<i class="icofont-user" style="color:rgb(${rgb[0]} ${rgb[1]} ${rgb[2]});"></i> 
						${this.operators[op].nickname}
						`, op, (value, dom) => {
							if (this.assignments.includes(value)) {
								const index = this.assignments.indexOf(value);
								this.assignments.splice(index, 1);
							} else {
								this.assignments.push(op);
							}
							this.update_assignments();

							dom.find('input').prop('checked', !dom.find('input').prop('checked'));

						}])
				});

				var projectMenu = [];
				Applet.FetchProjects((projects) => {
					projects[""] = "Aucun";
					Object.keys(projects).forEach((uuid) => {
						var css_class="";
						if (uuid == "")
							css_class = "grey";
						if (uuid == this.project)
							css_class="selected"
						projectMenu.push([
							`<span class="${css_class}">${projects[uuid]}</span>`,
							uuid,
							(value, dom) => {
								this.update_project(uuid);
								return true;
							}
						]);
					})
				}, false, true);

				var softwareMenu = [];
				Applet.FetchProjects((projects) => {
					projects[""] = "Aucun";
					Object.keys(projects).forEach((uuid) => {
						var css_class="";
						if (uuid == "")
							css_class = "grey";
						if (uuid == this.software)
							css_class="selected"
						softwareMenu.push([
							`<span class="${css_class}">${projects[uuid]}</span>`,
							uuid,
							(value, dom) => {
								this.update_software(uuid);
								return true;
							}
						]);
					})
				}, true, false);

				var originMenu = [];
				[['mail','Mail'],['phone','Téléphone'],['client','Suivi client']].forEach((elt) => {
					originMenu.push([
						`<span class="${this.origin == elt[0]?"selected":""}">${elt[1]}</span>`,
						"",
						(value, dom) => {
							this.update_origin(elt[0]);
							return true;
						}
					]);
				})

				var typeMenu = [];
				if(this.ticket_type != "maintenance") {
					[['evolution','Evolution'],['specifics','Spécifique']].forEach((elt) => {
						typeMenu.push([
							`<span class="${this.ticket_type==elt[0]?"selected":""}">${elt[1]}</span>`,
							"",
							(value, dom) => {
								this.update_type(elt[0]);
								return true;
							}
						]);
					})
				}

				var statusMenu = [
					[`<i class="icofont-unlock"></i> Ouvert`,"status:open"],
					[`<i class="icofont-gear"></i> En cours`,"status:pending"],
					[`<i class="icofont-question-circle"></i> Attente de retour client`,"status:waiting"],
					[`<i class="icofont-lock"></i> Fermé`,"status:closed"],
					[`<i class="icofont-ui-clock"></i> Ajourné`,"status:postponed"],
					[`<i class="icofont-ui-block"></i> Abandonné`,"status:abandoned"],
					[`<i class="icofont-space-shuttle"></i> Évolution`,"status:evolution"],
					[`<i class="icofont-eye"></i> À clôturer`,"status:to_be_closed"],
				];

				var statusMenuEvol = [
					[`<i class="icofont-question-circle" style="color:blue;"></i> A statuer`,"status:evol_open"],
					[`<i class="icofont-tick-mark" style="color:green;"></i> Accepté`,"status:evol_pending"],
					[`<i class="icofont-close" style="color:red;"></i> Rejeté`,"status:evol_rejected"],
					[`<i class="icofont-check-circled" style="color:blue;"></i> Terminé`,"status:evol_closed"],
					[`<i class="icofont-ui-clock"></i> À valider par Xavier`,"status:evol_pending_validation"],
				];

				var priorityMenu = [
					[`<i class="icofont-shrimp-alt" style="color:cyan;"></i> Basse`,"priority:low"],
					[`<i class="icofont-fish-4" style="color:green;"></i> Moyenne`,"priority:medium"],
					[`<i class="icofont-squid" style="color:orange;"></i> Haute`,"priority:high"],
					[`<i class="icofont-octopus" style="color:red;"></i> Critique`,"priority:critical"],
				]

				Menus.showMenu(this.window, $(evt.currentTarget), [
					['Commentaire', 'comment'],
					['Assigner à...',operatorMenu],
					['Statut...', (this.ticket_type=="maintenance")?statusMenu:statusMenuEvol],
					['Priorité...', priorityMenu],
					['Changer le titre', 'title'],
					'separator',
					['Créer un ticket Tuleap', 'create_tuleap'],
					['Lien Tuleap', 'tuleap'],
					(this.ticket_type=="maintenance"?['Convertir...','convert']:["<span class='grey'>Convertir...</span>",""]),
					'separator',
					['Client', projectMenu],
					['Logiciel', softwareMenu],
					['Origine',originMenu],
					['Temps de traitement', 'change_estimated_time'],
					(this.ticket_type=="maintenance"?["<span class='grey'>Type</span>",""]:['Type',typeMenu]),
					'separator',
					["Répondre par mail", "new_mail"],

				],
					(res) => {
						this.menu_callback(res);
					}
				);
			}
		})

		this.window.find('#refreshHistory').click(() => {
			this.load_ticket_history(this.current_ticket);
		});
        

        this.window.find('.modify-description').click((evt) => {
			this.create_editor();
			this.window.find('.ticket-content').attr("contenteditable","true");
			this.window.find('#saveDescription').css('display','inline-block');
			this.window.find('#modifyDescription').css('display','none');
		});

        
        this.window.find('.icon-external-window').click((evt) => {
            var target = this.window.find('.icon-expand-history').attr('target');
            var newNode = $(target).clone();
            var winHistory = new Window('TicketWindowHistory', 'Historique', 180, 120, 450, 420, newNode).setMinSize(450, 420)
                .setClosable()
                .setPinnable()
                .setResizable()
                .setOverflow('auto')
                .center();
			winHistory.window.find('.box-expandable').click((evt) => { console.log('test'); $(evt.currentTarget).removeClass('box-expandable');} );
        });
    }
}

AppletTickets.Initialize();